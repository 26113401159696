import { forwardRef, Inject, Injectable, Optional }                   from '@angular/core';
import { ErrorStore }                                                 from './error.store';
import { HttpErrorResponse }                                          from '@angular/common/http';
import { CsToastManagerService }                                      from '@cs/components/toast-manager';
import { AuthenticationService }                                      from '../../authentication/state/authentication.service';
import { CsGenericErrorLogger, CsErrorResponse, CsCfErrorResponse }   from '@cs/common';
import { CsHttpRequestOptions }                                       from '@cs/core/http';
import { CsHttp400ErrorResponse, CsHttpErrorResponse, ErrorResponse } from '@cs/core/generate';
import { isNullOrUndefined }                                          from '@cs/core/utils';
import { TranslateService }                                           from '@ngx-translate/core';
import { HealthConfigService }                                        from '../../health/health-config.service';


@Injectable({providedIn: 'root'})
export class ErrorService {

	constructor(private errorStore: ErrorStore,
							private toastService: CsToastManagerService,
							@Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
							private loggerService: CsGenericErrorLogger,
							@Optional() @Inject(forwardRef(() => HealthConfigService)) private healthService: HealthConfigService) {
	}


	handleInternalServerError(response: HttpErrorResponse) {
		// TODO: translate
		try {
			const errorObj = response.error as CsErrorResponse & CsCfErrorResponse;

			// if not logged by the server let's report it from the client
			if (!errorObj.logged && !errorObj.reportId) {
				this.loggerService.logError(response).subscribe(value => {
					if (value.logged) {
						this.toastService.show({
							type:         'error',
							title:        errorObj.message,
							clickToClose: true,
							content:      `The error was logged with report-id: ${value.referenceId}`
						});
					} else {
						if (value.logged) {
							this.toastService.show({
								type:         'error',
								title:        errorObj.message,
								clickToClose: true,
								content:      `The error was not logged`
							});
						}
					}

				});
			} else {
				this.toastService.show({
					type:         'error',
					title:        errorObj.message,
					clickToClose: true,
					content:      `The error was logged with report-id: ${errorObj.reference ? errorObj.reference : errorObj.reportId}`
				});
			}
		} catch (ex) {
			this.toastService.show({
				type:         'error',
				title:        response.message,
				clickToClose: true,
				content:      `An error occurred`
			});

		}
	}

	handleMaintenanceMode(response: HttpErrorResponse) {
		this.toastService.show({
			type:    'info',
			title:   `Maintenance mode`,
			content: `We are updating the server. Please try again in a couple of minutes`
		});
	}

	handleUnhandledServerError(response: HttpErrorResponse) {
		if (this.loggerService.isLogging()) {
			this.loggerService.logError(response).subscribe(value => {
				this.toastService.show({
					type:         'error',
					title:        `Unknown Error`,
					content:      `The server returned an unhandled response: ${response.status} : ${response.statusText}. Developers are notified.
          Reference number: ${value.referenceId}`,
					clickToClose: true
				});
			});
		} else {
			this.toastService.show({
				type:         'error',
				title:        `Unknown Error`,
				content:      `The server returned an unhandled response: ${response.status} : ${response.statusText}`,
				clickToClose: true
			});
		}
	}

	/**
	 * Handles the connection issues in the following order:
	 * 1) Check for internet connection
	 * 2) has internet connection, then check if application has health service, check if application is alive.
	 * 3) if application is live, assume it's a temporary connection issue.
	 * 4) show message with the detected result
	 * @param response The error
	 */
	handle0ResponseServerError(response: HttpErrorResponse) {
		// Internal function for calling the default toast message
		function showTempConnectionIssueMessage() {
			const self = this as ErrorService;

			let message = 'There was a temporary connection problem, please try again. If the situation persists, please contact support.';
			if (self.healthService) {
				message = `There was a temporary connection problem, please try again.
        If the situation persists, please contact support at ${self.healthService.supportEmail}`;
			}

			if (self.loggerService.isLogging()) {
				self.loggerService.logError(response).subscribe(result => {
					console.log('connection error is logged');
				});
			}
			self.toastService.show({
				type:         'warning',
				title:        `Connection`,
				content:      message,
				clickToClose: false
			});
		}

		// Check if the there is an internet connection
		if (!this.loggerService.hasInternetConnection()) {
			this.toastService.show({
				type:            'warning',
				title:           `No internet detected`,
				content:         'It appears you are offline, please check your internet connection and try again.',
				showProgressBar: true
			});

			return;
		}

		// Check if the application has an healthModule provided
		if (this.healthService) {

			// Handle issues with the health check, notify that the server is not responding
			const options = new CsHttpRequestOptions({
				errorResponseHandler: error => {
					this.toastService.show({
						type:         'warning',
						title:        `Server is not responding`,
						content:      `The application appears have run into trouble, and we have been informed.
            Please wait a minute and try again. If the situation persists, please contact support at ${this.healthService.supportEmail}.`,
						clickToClose: true
					});

					return true;
				}
			});
			// check if the api is still responsive
			this.healthService.checkApiHealth(options).subscribe(value => {
				showTempConnectionIssueMessage.call(this);
			});
		} else {
			// show message without the health check and email support adress
			showTempConnectionIssueMessage.call(this);
		}
	}

	handleNotAuthenticated() {
		this.authService.goToLoginPage();
	}

	notAuthorized(errorResponse?: ErrorResponse) {
		this.toastService.show({
			type:    'alert',
			title:   `Unauthorized`,
			content: (!isNullOrUndefined(errorResponse) && errorResponse.message) || `You are not authorized to perform this operation.`
		});
	}

	handleBadRequest(response: CsHttpErrorResponse, nameObject: { [key: string]: string } = {}) {
		const errorResponse = response.error as CsHttp400ErrorResponse;
		let message         = response.message;

		// ducktyping the CsHttp400ErrorResponse, if it is a CsHttp400ErrorResponse then get the errors and show them
		if (errorResponse.errors !== undefined)
			message = Object.keys(errorResponse.errors)
											.map(value => `${nameObject.hasOwnProperty(value.toLowerCase())
												? nameObject[value.toLowerCase()] : value}: ${errorResponse.errors[value]}`).join('\n');

		this.toastService.show({
			type:         'info',
			title:        `Invalid selection`,
			content:      message,
			clickToClose: true
		});

	}

	handleExpiredAutoLoginMessage(l8n: TranslateService) {
		this.authService.showExpiredAutoLoginMessage(l8n);
	}

	handleResetPassword(l8n: TranslateService) {
		this.authService.goToResetPasswordPage(l8n);
	}

	handleExpiredPassword(l8n: TranslateService) {
		this.authService.goToExpiredPasswordPage(l8n);
	}

	handlesetPassword(l8n: TranslateService) {
		this.authService.goToSetPasswordPage(l8n);
	}
}
