export enum AuthorizationResources {
  Application_CanAccessHR                   = 'application.CanAccessHR',
  Application_CanViewWeights                = 'application.CanViewWeights',
  Application_CanAccessQuality              = 'application.CanAccessQuality',
  Application_CanAccessOperational          = 'application.CanAccessOperational',
  Application_CanAccessWorkforce            = 'application.CanAccessWorkforce',
  Application_CanAccessTargets              = 'application.CanAccessTargets',
  Application_CanCreateAllEventTypes        = 'application.CanCreateAllEventTypes',
  Application_CanEnterNegativeTonAWBvolumes = 'application.CanEnterNegativeTonAWBvolumes',
  Client_CanAccessAdmin                     = 'client.CanAccessAdmin',
  Framework_Debug                           = 'framework.Debug',
  Demo_CanAccessDCM                    = 'Demo.CanAccessDCM',
  Demo_CanAccessCDM                    = 'Demo.CanAccessCDM',
}
