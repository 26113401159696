<div class="dashboard_main">
    <!-- Show alerts -->
    <div *ngFor="let alert of dashboardGrid?.alerts"
         class="alert alert-{{alert.type}}" role="alert">
        <h4 *ngIf="alert.title" class="alert-heading">{{alert.title}}</h4>
        <p class="mb-0" style="color: inherit">{{alert.message}}</p>
    </div>
    <!-- Generate the dashboard grid -->
    <ng-container *ngFor="let row of dashboardGrid?.grid">
        <!-- Create a row foreach array in data.grid -->
        <div class="cs-row flex-grow-1" [class.expanded]="row.isExpanded">
            <!-- Create a bootstrap column foreach array entry in row  -->
            <ng-container *ngFor="let col of row.columns">
                <div *ngIf="col.colspan"
                     class="cs-col-{{col.colspan}}">
                    <div *ngFor="let panel of col.panels"
                         class="block-panel">
                        <div class="dashboard_block {{panel.reason}}"
                             [ngClass]="{'has-shadow': panel.hasShadow}"
                             [attr.id]="panel.name">
                            <div class="header" *ngIf="panel.label">
                                <div class="truncate"
                                     [matTooltipDisabled]="!panel.isTruncated"
                                     matTooltip="{{panel.label}}"
                                     (mouseover)="detectTruncatedField($event, panel)">{{panel.label}}</div>

                                <div class="flex flex-row header-button--row">
                                    <ng-container *ngFor="let iconBtn of panel?.meta?.infoIcons">
                                        <ng-container *ngIf="iconBtn.asButton">
                                            <button [matTooltip]="replaceSpaces(iconBtn.description)"
                                                    matTooltipPosition="below"
                                                    class="btn btn--icon-and-text btn--{{getButtonType(iconBtn.type)}} "
                                                    (click)="metaButtonClicked(iconBtn, panel)">
                                                <i class="{{ getButtonIcon(iconBtn) }} panel-info-icon"
                                                   [@loadingIconState]="iconBtn.loading"
                                                   [ngClass]="{ 'mdi-loading mdi-spin': iconBtn.loading === 'loader' }">
                                                </i>{{iconBtn.label}}</button>
                                        </ng-container>
                                        <ng-container *ngIf="!iconBtn.asButton">
                                            <i [matTooltip]="replaceSpaces(iconBtn.description)"
                                               matTooltipPosition="below"
                                               class="{{ getIcon(iconBtn) }} ml-2 panel-info-icon "
                                               [@loadingIconState]="iconBtn.loading"
                                               [ngClass]="{ 'mdi-loading mdi-spin': iconBtn.loading === 'loader',
											   'hover-pointer': iconBtn.action,
											   'hover-help': (!iconBtn.action)
											   }"
                                               (click)="metaButtonClicked(iconBtn, panel)"></i>
                                        </ng-container>

                                    </ng-container>
                                    <ng-content select="[headerRight]"></ng-content>

                                </div>


                            </div>
                            <div class="content {{panel.class}} {{panel.reason}}"
                                 [class.with-footer]="panel.hasFooter">
                                <ng-content select="[contentTop]"></ng-content>
                                <!-- dynamic component resolving of the paneltype -->
                                <div [csDashboardComponentLoader]="panel.component"
                                     (componentInstantiated)="trackComponent($event, panel)"
                                     [componentName]="panel.name"
                                     [componentPanel]="panel"
                                     [componentData]="panel.data"
                                     [componentHeight]="panel.height">
                                </div>
                            </div>
                            <div class="panel-footer" *ngIf="panel.hasFooter">
                                <div class="panel-footer-info-container flex flex-row">
                                    <div class="footer-filter flex" *ngIf="panel?.options">
                                        <!--<cs-form-generator-nxt [data]="panel?.options"></cs-form-generator-nxt>-->
                                        <ng-container *ngFor="let optionList of panel?.options?.dataAnnotation?.fields">
                                            <div class="panel-option__container"
                                                 [class.panel-option-readonly]="optionList?.readOnly"
                                                 *ngIf="optionList.visible === true">
                                                <small class="panel-option__label f-body-s"
                                                       *ngIf="optionList?.label"><b>{{optionList?.label}}</b></small>
                                                <ng-container *ngIf="!optionList?.readOnly">
                                                    <div class="flex flex-row align-items-center drop-filter">
                                                        <i *ngIf="!optionList?.label"
                                                           class="mdi mdi-filter-variant panel-filter-icon"></i>
                                                        <div class="panel-filter-select">
                                                            <button mat-button
                                                                    class="btn btn-default btn-sm dropdown-toggle"
                                                                    [matMenuTriggerFor]="menu"
                                                            >{{getCurrentValue(panel, optionList)}}
                                                            </button>
                                                            <mat-menu #menu="matMenu">
                                                                <ng-container
                                                                        *ngFor="let lookup of getLookup(panel, optionList?.lookup)">
                                                                    <button mat-menu-item
                                                                            class="mat-menu-item-filter"
                                                                            (click)="panelOptionClicked(lookup.key, panel, optionList)">{{lookup.value}}</button>
                                                                </ng-container>
                                                            </mat-menu>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="optionList?.readOnly">
                                                    <small>{{getCurrentValue(panel, optionList)}}</small>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <ng-container *ngIf="panel.note">
                                        <div class="flex flex-row align-items-center panel-note-container">
                                            <i class="mdi mdi-information panel-note-icon"></i>
                                            <small class="f-body-s panel-note" [innerHTML]="panel.note">
                                            </small>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
