import { AppService, AuthorizationResources }                   from '@cs/performance-manager/shared';
import { Observable }                                           from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable }                                           from '@angular/core';
import { ShellConfigService }                                   from '../shell-config.service';
import { Result }                                               from '@cs/core';


@Injectable()
export class ResourcesResolver implements Resolve<Result<{ [key: string]: boolean }>> {
	constructor(private shellConfig: ShellConfigService, private appStore: AppService) {

	}

	static hasResource(resources: { [key: string]: boolean }, resource: AuthorizationResources) {
		for (const key of Object.keys(resources)) {

			if (key.toLowerCase() === resource.toLowerCase())
				return true;
		}

		return false;
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
		: Observable<Result<{ [p: string]: boolean }>> | Promise<Result<{ [p: string]: boolean }>> | Result<{ [p: string]: boolean }> {
		const obs = new Observable<Result<{ [p: string]: boolean }>>(subscriber => {
			this.shellConfig.getUserResources().subscribe(value => {
				const resources = value.value;

				this.appStore.update({
					canAccessAdmin:         ResourcesResolver.hasResource(resources, AuthorizationResources.Client_CanAccessAdmin),
					isDebugUser:            ResourcesResolver.hasResource(resources, AuthorizationResources.Framework_Debug),
					allowNegativeDataEntry: ResourcesResolver.hasResource(resources, AuthorizationResources.Application_CanEnterNegativeTonAWBvolumes)
				});

				subscriber.next(value);
				subscriber.complete();
			});
		});


		return obs;
	}


}
