import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';


import { CsStatisticsPanelComponent }                 from './statistics-panel.component';
import { CsStatisticsPanelTableComponent }            from './statistics-panel-table.component';
import { CsStatisticsPanelPerformanceStatsComponent } from './statistics-panel-performance-stats.component';
import { CsStatisticsPanelComparisonBarComponent }    from './statistics-panel-comparison-bar.component';
import { CsStatisticsPanelNoneComponent }             from './statistics-panel-none.component/statistics-panel-none.component';
import { CsStatisticsPanelHtmlComponent }             from './statistics-panel-html.component/statistics-panel-html.component';
import { CsStatisticsPanelAuditsComponent }           from './statistics-panel-audits.component/statistics-panel-audits.component';
import { MatTooltipModule }                           from '@angular/material/tooltip';
import { PMAuditTrailModule }                         from '@cs/performance-manager/audit-trail';


const COMPONENTS = [
	CsStatisticsPanelComponent,
	CsStatisticsPanelTableComponent,
	CsStatisticsPanelPerformanceStatsComponent,
	CsStatisticsPanelComparisonBarComponent,
	CsStatisticsPanelNoneComponent,
	CsStatisticsPanelHtmlComponent,
	CsStatisticsPanelAuditsComponent
];

@NgModule({
	imports:      [
		CommonModule,
		MatTooltipModule,
		PMAuditTrailModule
	],
	declarations: [...COMPONENTS],
	exports:      [
		...COMPONENTS
	],
	providers:    []
})
export class CsStatisticsPanelModule {
	static forRoot(): ModuleWithProviders<CsStatisticsPanelModule> {
		return {ngModule: CsStatisticsPanelModule};
	}
}
