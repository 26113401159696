import { getPropertyOf } from '@cs/core/utils';

export class DataGroup<T> {

	groupBy: string;
	values?: [T];
	isCollapsible?: boolean;

	constructor(init: Partial<DataGroup<T>>) {
		this.groupBy       = getPropertyOf(init, 'groupBy');
		this.values        = getPropertyOf(init, 'values');
		this.isCollapsible = getPropertyOf(init, 'isCollapsible', false);
	}

}
