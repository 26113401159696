import { FormAnnotation }                   from './form-annotation.model';
import { hasPropertyOf, isNullOrUndefined } from '@cs/core/utils';
import { LayoutAnnotation }                 from '../data-described/layout-annotation.model';
import { LabelPosition }                    from './label-position.model';
import { LayoutOrientation }                from './layout-orientation.model';
import { FormLayoutAnnotation }             from './form-layout-annotation.model';


export class LayoutAnnotationForm<T> extends LayoutAnnotation<T> {
	constructor(layoutObject: Partial<LayoutAnnotationForm<T>>) {
		super(layoutObject);

		this.form = !isNullOrUndefined(layoutObject) && hasPropertyOf(layoutObject, 'form')
			? new FormAnnotation<T>(layoutObject.form)
			: new FormAnnotation<T>({
				id:     'default',
				label:  '',
				layout: new FormLayoutAnnotation({
					labelPosition:  LabelPosition.Left,
					orientation:    LayoutOrientation.Vertical,
					showValidState: false,
					ignoreIdColumn: true
				})
			});
	}

	form?: FormAnnotation<T>;
}
