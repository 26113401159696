import { InjectionToken, TemplateRef } from '@angular/core';

export class ToastData {
	type: ToastType;
	title?: string;
	content?: string;
	template?: TemplateRef<any>;
	templateContext?: {};
	showProgressBar?: boolean;
	clickToClose ? = false;
	showTitle ?    = true;
	timeOut?: number;
}

export type ToastType = 'warning' | 'info' | 'success' | 'bare' | 'alert' | 'error';

export interface ToastConfig {
	position?: {
		bottom: number;
		top: number;
		right: number;
	};
	animation?: {
		fadeOut: number;
		fadeIn: number;
	};
	timeOut: number;
	pauseOnHover: boolean;
}

export const defaultToastConfig: ToastConfig = {
	position:     {
		bottom: 20,
		top:    0,
		right:  20
	},
	animation:    {
		fadeOut: 200,
		fadeIn:  200
	},
	timeOut:      4000,
	pauseOnHover: true
};

export function DefaultToastConfigFactory() {
	return defaultToastConfig;
}

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config', {
	providedIn: 'root',
	factory:    DefaultToastConfigFactory
});
