<cs-table-nxt #grid
			  [data]="data"
			  [sortable]="isSortable"
			  [isLoading]="isLoading"
			  [height]="height"
			  class="{{clickTypeClass}}"
			  [class.is-clickable]="isClickable"
			  [selectable]="isEntityClickable"
			  [selectedPageSize]="selectedPageSize"
			  (sortingRequestServerSidePaging)="sortingServerSideDateRequest($event)"
			  (filterRequestServerSidePaging)="filterServerSideDateRequest($event)"
			  (newPageRequestServerSidePaging)="newPageServerSideDateRequest($event)"
			  (renderingIsDone)="updateView()"
			  (cellClick)="cellClicked($event)"
			  (rowMenuItemClicked)="entityMenuItemClicked($event)"
			  (rowClick)="rowClicked($event)">
</cs-table-nxt>
