import { Resolve, Routes }   from '@angular/router';
import { ShellComponent }    from './shell.component';
import { ResourcesResolver } from './resolvers/resource.resolver';

import { AuthenticationGuard } from '@cs/performance-manager/shared';
import { Type }                from '@angular/core';


export class ShellRoutingLoader {
	static getRoutes(children: Routes, resolvers: { [key: string]: Type<Resolve<any>> } = {
		resources: ResourcesResolver
		//  allMembers: AllMembersResolver
	}) {
		return [{
			path:        '', component: ShellComponent,
			canActivate: [AuthenticationGuard],
			resolve:     resolvers,
			children:    [
				...children
			]
		}
		];
	}
}
