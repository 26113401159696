import { IButton }           from '@cs/components/shared';
import { isNullOrUndefined } from '@cs/core';

/**
 * Sheet settings can be modified by adding rule(s).
 * Modifying the settings through the grid options (c.q. config/layout) is currently not implemented.
 */
export class DataGridSheetSettings {
	showSaveAction: boolean;
	showCancelAction: boolean;
	showAddAction: boolean;

	disableSaveAction: boolean;
	disableCancelAction: boolean;
	disableAddAction: boolean;
	fixedSize: boolean;

	showLabelAction: boolean;
	showHeaderAction: boolean;
	alignBtnWithTableHeader: boolean;

	doNotCalculateSize: boolean;
	/**
	 * Show zero (0) in Total Rows even when column contains no numeric values
	 */
	showZeroTotalsWhenColumnIsEmpty: boolean;


	/**
	 * Additional custom Sheet Action buttons
	 */
	dynamicSheetActionButtons: Array<IButton>;

	constructor(initial: Partial<DataGridSheetSettings> = null) {
		if (initial === null) {
			this.setDefaults();
		} else if (!isNullOrUndefined(initial)) {
			Object.assign(this, initial);
		}
	}

	setDefaults() {
		this.showSaveAction   = false;
		this.showCancelAction = false;
		this.showAddAction    = true;

		this.disableSaveAction   = false;
		this.disableCancelAction = false;
		this.disableAddAction    = false;
		this.fixedSize           = true;
		this.doNotCalculateSize  = false;

		this.showLabelAction           = true;
		this.showHeaderAction          = true;
		this.alignBtnWithTableHeader   = false;
		this.dynamicSheetActionButtons = [];

		this.showZeroTotalsWhenColumnIsEmpty = true;

	}
}
