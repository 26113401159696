import { NgModule }                                             from '@angular/core';
import { CommonModule }                                         from '@angular/common';
import { MatButtonModule }                                      from '@angular/material/button';
import { CsDashboardChartLoaderSetup }                          from './models/cs-dashboard-chart-loader-setup';
import { DashboardComponent }                                   from './dashboard.component';
import {
	DashboardChartComponent,
	DashboardChartNxtComponent,
	DashboardHtmlComponent,
	DashboardStatisticsComponent,
	DashboardTableComponent,
	HyperlinkListComponent,
	DashboardGenericTableComponent,
	DashboardEmptyComponent,
	DashboardInformationComponent,
	DashboardSingleIndicatorsComponent,
	DashboardChartNxtSliderComponent,
	DashboardListGroupedItemsComponent,
	DashboardCombiEntryStateComponent,
	DashboardLegendComponent,
	DashboardListComponent,
	DashboardIndicatorsComponent,
	DashboardTasksComponent,
	DashboardUpdatesComponent,
	DashboardFormGeneratorComponent,
	DashboardGaugeComponent,
	DashboardViewerComponent
}                                                               from './components';
import { CsChartLoaderModule, CsDataDescribedChartLoaderSetup } from '@cs/components/cs-chart-loader';

import {
	CsCultureModule
}                                            from '@cs/common';
import { CsPopoverModule }                   from '@cs/components/popover';
import { TranslateModule }                   from '@ngx-translate/core';
import { CsGaugeModule }                     from '@cs/components/gauge';
import { CsIndicatorModule }                 from '@cs/components/indicator';
import { CsTableNxtModule }                  from '@cs/components/table-nxt';
import { CsTemplateRegistry }                from '@cs/components/shared';
import { MatDialogModule }                   from '@angular/material/dialog';
import { MatIconModule }                     from '@angular/material/icon';
import { MatMenuModule }                     from '@angular/material/menu';
import { MatTooltipModule }                  from '@angular/material/tooltip';
import { CsSingleIndicatorModule }           from '@cs/components/single-indicator';
import { FormGeneratorNxtModule }            from '@cs/components/form-generator-nxt';
import { FormsModule }                       from '@angular/forms';
import { Ng5SliderModule }                   from '@cs/components/slider-nxt';
import { CsSharedModule }                    from '@cs/components/shared';
import { ChartLegendModule }                 from '@cs/components/chart-legend';
import { CsDataPickerCultureModule }         from '@cs/components/datepicker';
import { DashboardComponentLoaderDirective } from './dashboard-component-loader.directive';
import { CsViewerModule }                    from '@cs/components/viewer';


const COMPONENTS         = [DashboardComponent];
const DYNAMIC_COMPONENTS = [
	HyperlinkListComponent,
	DashboardTableComponent,
	DashboardChartComponent,
	DashboardHtmlComponent,
	DashboardStatisticsComponent,
	DashboardCombiEntryStateComponent,
	DashboardLegendComponent,
	DashboardListComponent,
	DashboardGaugeComponent,
	DashboardIndicatorsComponent,
	DashboardSingleIndicatorsComponent,
	DashboardGenericTableComponent,
	DashboardChartNxtComponent,
	DashboardEmptyComponent,
	DashboardInformationComponent,
	DashboardListGroupedItemsComponent,
	DashboardTasksComponent,
	DashboardUpdatesComponent,
	DashboardChartNxtSliderComponent,
	DashboardFormGeneratorComponent,
	DashboardViewerComponent
];
const DIRECTIVES         = [DashboardComponentLoaderDirective];

@NgModule({
	imports:         [
		CommonModule,
		CsCultureModule,
		TranslateModule,
		CsPopoverModule,
		CsGaugeModule,
		MatDialogModule,
		MatButtonModule,
		MatTooltipModule,
		MatMenuModule,
		MatIconModule,
		FormGeneratorNxtModule,
		CsChartLoaderModule.forChild(CsDashboardChartLoaderSetup),
		CsDataPickerCultureModule,
		CsIndicatorModule,
		CsSingleIndicatorModule,
		CsTableNxtModule,
		CsChartLoaderModule.forChild(CsDataDescribedChartLoaderSetup),
		FormsModule,
		Ng5SliderModule,
		CsSharedModule,
		ChartLegendModule,
		CsViewerModule
	],
	exports:         [...COMPONENTS, ...DIRECTIVES],
	declarations:    [...COMPONENTS, ...DIRECTIVES, ...DYNAMIC_COMPONENTS],
	providers:       [CsTemplateRegistry],
	entryComponents: [...DYNAMIC_COMPONENTS]
})
export class DashboardModule {
	// constructor(loader: ComponentTranslationLoaderService) {
	// 	// loader.registerComponentModule('dashboard');
	// }
}
