import { NgModule, ModuleWithProviders, forwardRef, Type } from '@angular/core';
import { CommonModule }                                    from '@angular/common';

import { CsCultureDateParserFormatter }        from './date-culture-parser-formatter';
import { CsCultureDateParserFormatterDefault } from './date-culture-parser-formatter-default';

import { FormatProviderService }             from './format-provider.service';
import { DefaultFormatRegistry }             from './default-format-registry';
import { FormatRegistry }                    from './format-registry';
import { CsCultureProvider }                 from './culture-provider';
import { CsCultureProviderDefault }          from './culture-provider.default';
import { FormatPipe }                        from './pipes/format.pipe';


const pipes = [FormatPipe];

@NgModule({
  declarations:    [...pipes],
  exports:         [...pipes],
  imports:         [CommonModule],
  providers:       [],
  entryComponents: []
})
export class CsCultureModule {
  static forRoot(formatRegistry: Type<FormatRegistry> = DefaultFormatRegistry): ModuleWithProviders<CsCultureModule> {
    return {
      ngModule:  CsCultureModule,
      providers: [
        {provide: CsCultureProvider, useClass: CsCultureProviderDefault},
        {provide: CsCultureDateParserFormatter, useClass: forwardRef(() => CsCultureDateParserFormatterDefault)},
        {provide: FormatProviderService, useClass: FormatProviderService},
        {provide: FormatRegistry, useClass: formatRegistry}
      ]
    };
  }
}
