import { fromEvent as observableFromEvent, merge as observableMerge, Observable } from 'rxjs';
import {
	ChangeDetectionStrategy,
	Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output,
	ViewChild
}                                                                                 from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, NG_VALIDATORS }         from '@angular/forms';
import { ElementBase }                                                            from '@cs/components/shared';
import { SpinnerChangedEventArgs }                                                from './spinner-changed.event-args';
import { isNullOrUndefined, isString }                                            from '@cs/core';

@Component({
	selector:        'cs-spinner',
	templateUrl:     './spinner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers:       [
		{
			provide:     NG_VALUE_ACCESSOR,
			useExisting: CsSpinnerComponent,
			multi:       true
		}
	]
})

export class CsSpinnerComponent extends ElementBase<number> implements OnInit {
	model: NgModel;

	@Input() displayValue = 0;

	@Input()
	notNegative = true;

	@ViewChild('up', {static: true})
	upButton: ElementRef;

	@ViewChild('down', {static: true})
	downButton: ElementRef;

	@Output() spinnerChanged      = new EventEmitter<SpinnerChangedEventArgs>();
	@Output() spinnerInputChanged = new EventEmitter<Event>();

	private mousedown: Observable<any>;
	private mouseup: Observable<any>;

	constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
							@Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
		super(validators, asyncValidators);
	}

	ngOnInit() {
		this.setupButton(this.upButton, (counter) => this.increment(counter));
		this.setupButton(this.downButton, (counter) => this.decrement(counter));
		this.value = 0;
	}

	inputChanged(changeEvent: Event) {
		this.spinnerInputChanged.emit(changeEvent);
	}

	increment(counter: number = 1) {
		const num = Math.ceil(counter / 8);
		let val   = (isNullOrUndefined(this.value) || this.value.toString() === '') ? 0 : this.value;
		val       = isString(val) ? parseFloat(val) : val;
		if (val === NaN || isNullOrUndefined(val)) {
			val = 0;
		}
		const calc = val + num;

		if (calc < 0 && this.notNegative)
			return;

		// this.value = calc;
		this.spinnerChanged.emit(new SpinnerChangedEventArgs(num));
	}

	decrement(counter: number = 1) {
		const num = -Math.ceil(counter / 8);

		let val = (isNullOrUndefined(this.value) || this.value.toString() === '') ? 0 : this.value;
		val     = isString(val) ? parseFloat(val) : val;
		if (val === NaN || isNullOrUndefined(val)) {
			val = 0;
		}
		const calc = val + num;

		if (calc < 0 && this.notNegative)
			return;

		// this.value = calc;
		this.spinnerChanged.emit(new SpinnerChangedEventArgs(num));
	}

	private setupButton(btn: ElementRef, callback: (arg: number) => any) {

		this.mousedown = observableMerge(
			observableFromEvent(btn.nativeElement, 'mousedown'),
			observableFromEvent(btn.nativeElement, 'touchstart')
		);

		this.mouseup = observableMerge(
			observableFromEvent(btn.nativeElement, 'mouseup'),
			observableFromEvent(btn.nativeElement, 'mouseleave'),
			observableFromEvent(btn.nativeElement, 'touchend'));

		let timer;
		this.mousedown.subscribe((x: Event) => {
			let counter = 1;
			// set initial click
			callback(counter);

			let interval = 200;
			timer        = setInterval((x) => {
				callback(counter);
				counter++;
			}, interval);
		});

		this.mouseup.subscribe((x) => {
			clearInterval(timer);
		});
	}
}
