<div class="home-page-container">
	<!-- Header  -->
	<div class="home-header-container" [ngStyle]="backgroundStyling">

		<div class="home-header-overlay"></div>
		<div class="home-header-content">
			<div class="home-welcome-container">
				<h1>{{greeting$ | async}}</h1>
				<p class="last-login-text">{{'LAST_LOGIN_TEXT' | translate}} {{lastLogin$ | async}}</p>
			</div>
			<div class="home-header-data-values-container" [@listAnimation]="homeData?.stats?.indicators?.length">
				<ng-container *ngFor="let item of homeData?.stats?.indicators">
					<div class="value-panel effect-glass">
						<div class="value-panel-container ">
							<label class="value-panel--title">{{item.title}}</label>
							<div class="value-panel--value">{{item.value}}</div>
							<div class="value-panel--value-description">{{item.subTitle}}</div>
							<div class="value-panel--value-fact-description">{{item.description}}</div>
						</div>
					</div>
				</ng-container>
			</div>
			<!-- The glass effect is hidden instead of removing the complete filterbar. This is because the
			filterbar is used to set the pages in the tab -->
			<div class="home-header-data-list-container"
				 [class.effect-glass]="mainbarDataSource?.filterElements?.length > 0"
				 [@fadeInOnEnter]>
				<div class="home-dashboard-navigation col-3">
					<cs-filter-and-compare-bar [mDataSource]="mainbarDataSource"
											   [hasCompare]="false"
											   [renderStubs]="false"
											   [SyncUrlParameters]="true"
											   (selectionChanged)="filterBarSelectionChanged($event)"
											   #filterBar
					></cs-filter-and-compare-bar>
				</div>

			</div>
		</div>
	</div>


	<!-- Dashboard part -->
	<div class="home-dashboard-container">
		<router-outlet></router-outlet>
	</div>
</div>
