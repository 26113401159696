import { NgModule, ModuleWithProviders, Type }                from '@angular/core';
import { CommonModule }                                       from '@angular/common';
import { HttpClient }                                         from '@angular/common/http';
import { CsApplicationSettings }                              from '@cs/performance-manager/shared';
import { IConfigService }                                     from '@cs/performance-manager/shared';
import { CsTranslationLoader }                                from '@cs/performance-manager/shared';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { AuditTrailConfigService }                            from './audit-trail-config.service';
import { LoggerUtil }                                         from '@cs/core';
import { FormsModule }                                        from '@angular/forms';
import { CsComboboxModule, CsTableNxtModule }                 from '@cs/components';
import { AuditTrailAuditsComponent }                          from './components/audit-trail-audits/audit-trail-audits.component';
import { AuditTrailChangesComponent }                         from './components/audit-trail-changes/audit-trail-changes.component';
import { AuditTrailStateService }                             from './state/audit-trail-state.service';
import { AuditTrailStateStore }                               from './state/audit-trail-state.store';
import { AuditTrailStateQuery }                               from './state/audit-trail-state.query';
import { CsCultureModule }                                    from '@cs/common';
import { MatTooltipModule }                                   from '@angular/material/tooltip';

export function auditTrailTranslationLoader(client: HttpClient,
																						pmAppSettings: CsApplicationSettings,
																						service: IConfigService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`, client);
}

@NgModule({
	declarations: [AuditTrailAuditsComponent, AuditTrailChangesComponent],
	exports:      [AuditTrailAuditsComponent, AuditTrailChangesComponent],
	providers:    [AuditTrailStateService, AuditTrailStateStore, AuditTrailStateQuery],
	imports:      [
		CommonModule, FormsModule,
		CsCultureModule,
		CsComboboxModule,

		CsTableNxtModule,
		// add the translate module
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: auditTrailTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, AuditTrailConfigService]
			},
			isolate: true
		}), MatTooltipModule
	]
})
export class PMAuditTrailModule {

	static forChild(moduleConfig: Type<AuditTrailConfigService>,
									appSetting: CsApplicationSettings): ModuleWithProviders<PMAuditTrailModule> {
		return {
			ngModule:  PMAuditTrailModule,
			providers: [
				{
					provide:  AuditTrailConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
		translate.setDefaultLang('en');

		// the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
		translate.use('en');
	}
}
