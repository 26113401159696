<div class="cs-table-nxt-container"
	 [@loadingPanelState]="isLoadingDebounced$ | async">
	<div class="cs-table-nxt-header" *ngIf="showPagination">
		<nav class="cs-table-nxt-header-pagination-container" [class.has-scrollbar]="isScrollingVertical">
			<div class="pagination__count_container">
        <span class="pagination__count">
          <span matTooltip="{{'ROW_START_END' | translate}}">{{ pageStartRow }} - {{ pageEndRow }}</span>
          <span class="pagination__count_splitter">|</span>
          <span matTooltip="{{'ROW_COUNT' | translate}}">{{ rowCount }}</span>
        </span>
			</div>

			<select matTooltip="{{'SHOW_NUMBER_OF_ROWS' | translate}}"
					class="page-size-selection form-control form-control-sm ml-2"
					*ngIf="showPagination && pagingSizesLookup?.values.length>1"
					[ngModel]="selectedPageSize"
					(ngModelChange)="requestNewPagePageSize($event)">
				<ng-container *ngFor="let val of pagingSizesLookup?.values">
					<option [ngValue]="val.key">{{val.value}}</option>
				</ng-container>
			</select>

			<ul class="pagination" *ngIf="showPagination">
				<li class="page-item first">
					<button type="button" class="btn btn--paging btn--dark mr-2"
							matTooltip="{{'FIRST_PAGE' | translate}}"
							[matTooltipDisabled]="displayPage === 1"
							[disabled]="displayPage === 1"
							(click)="requestNewPage(0)"><i
							class="mdi mdi-page-first"></i></button>
				</li>
				<li class="page-item back">
					<button type="button"
							class="btn btn--paging btn--dark"
							matTooltip="{{'PREVIOUS_PAGE' | translate}}"
							[matTooltipDisabled]="displayPage === 1"
							[disabled]="displayPage === 1"
							(click)="requestNewPage(page - 1)"><i
							class="mdi mdi-chevron-left"></i></button>
				</li>
				<li class="page-input-field">
					<label class="d-inline-flex align-items-center input-xs">
						<input [value]="displayPage" type="number"
							   [ngStyle]="{'width.px': displayPagelength}"
							   min="0"
							   (change)="requestNewPageInput($event)"
						>
						<label class="f-dimmed-text-color" matTooltip="{{'TOTAL_AMOUNT_OF_PAGES' | translate}}">
							/ {{pagingPageCount}}</label>
					</label>
				</li>
				<li class="page-item next">
					<button type="button"
							class="btn btn--paging btn--dark"
							matTooltip="{{'NEXT_PAGE' | translate}}"
							[disabled]="pagingPageCount == displayPage"
							[matTooltipDisabled]="pagingPageCount == displayPage"
							(click)="requestNewPage(page + 1)">
						<i class="mdi mdi-chevron-right"></i></button>
				</li>
				<li class="page-item last">
					<button type="button" class="btn btn--paging btn--dark ml-2"
							matTooltip="{{'LAST_PAGE' | translate}}"
							[disabled]="pagingPageCount == displayPage"
							[matTooltipDisabled]="pagingPageCount == displayPage"
							(click)="requestNewPage(pagingPageCount - 1)"><i
							class="mdi mdi-page-last"></i></button>
				</li>
			</ul>
		</nav>
		<nav class="cs-table-nxt-header-actions-container">
			<ng-content select="cs-table-nxt-actions"></ng-content>
		</nav>
	</div>


	<div class="table-responsive"
		 [class.has-scrollbar]="isScrollingVertical"
		 [class.scrollable]="getScrollable() && renderSchema?.layout.hasHorizontalScrollEnabled"
		 #tableScrollContainer
	>
		<ng-container *ngIf="renderSchema?.layout.hasHorizontalScrollEnabled">
			<div class="scrolling-cloak"></div>
			<div class="scrolling-cloak-vertical"></div>
		</ng-container>
		<table class="table"
			   [class.table-fixed]="!renderSchema?.layout.hasHorizontalScrollEnabled"
			   [class.fixed-header-scrollable]="hasCollapseAble"
			   [class.table-hover]="renderSchema?.layout.isSelectable || selectable"
			   [style.max-height]="height">
			<colgroup *ngFor="let colgroup of renderSchema?.headerRows">
				<ng-container *ngFor="let col of colgroup.headers">
					<col [style.width]="col.width">
				</ng-container>
			</colgroup>
			<thead class="table-headers">
			<tr *ngFor="let tr of renderSchema?.headerRows; let rowIndex = index; let isLast = last; trackBy: trackId"
				class="th-header-{{rowIndex}}"
				[class.isLast]="isLast"
			>
				<th *ngIf="actionButtons?.length > 0" class="checkbox-container-table-header-container">
					<label class="checkbox-container checkbox-container-table-header"
						   *ngIf="rowIndex === renderSchema?.headerRows.length-1"
						   (click)="onSelectAllClicked($event)">
						<input class="form-control"
							   type="checkbox"
							   [checked]="selectAllActive"
						> <span class="checkmark"></span>
					</label>
				</th>
				<th *ngFor="let th of tr.headers; trackBy: trackHeaders"
					class="table-th table-cell table-headers__cell "
					[ngClass]="th.classList"
					(click)="onHeaderClick(th, $event)"
					[attr.colspan]="th.colSpan"
					[title]="th.description">
					<div class="table-th-container"
						 [class.no-content]="th.displayValue === ''"
						 [class.sortable]="th.isSortable">
						<div class="table-th-icons-container"
							 [ngStyle]="{'display':th.hasFilter?'block':'none'}"
							 id="f_{{th.id}}_filter"
							 [matTooltip]="th.displayValue"
							 [matTooltipDisabled]="!th.truncate"
							 (mouseover)="detectTruncatedFieldHeader($event, th)">
							<mat-form-field>
								<mat-select [placeholder]="th.displayValue"
											[(ngModel)]="currentFilter[th.id]"
											[multiple]="true"
											[id]="th.id"
											[panelClass]="'table-header-mat-select'"
											(selectionChange)="filterOptionsSelectionChanged(th.id)"
											(openedChange)="setPanelHeight(th,searchComboBar.displayOptions.values, $event)"
											disableRipple>
									<cs-mat-select-search #searchComboBar
														  (deselectAllEvent)="resetTableFilter(th.id)"
														  [ngStyle]="{
                                        display:searchComboBar?.displayOptions?.values?.length > 8
                                        || searchComboBar?.value?.length > 0 ? 'block': 'none'
                                        }"
														  class="mat-select-search"
														  [options]="staticFilterOptions[th.id]"
									></cs-mat-select-search>
									<cdk-virtual-scroll-viewport [itemSize]="30"
																 minBufferPx="400" maxBufferPx="500"
																 class="full-height"
																 [ngStyle]="{
                                               'max-height.px': searchComboBar.displayOptions.values.length >8? 209: 256
                                               }">
										<ng-container
												*cdkVirtualFor="let value of searchComboBar.displayOptions.values">
											<mat-option [value]="value.key"
														(onSelectionChange)="onSelectionChange(th.id, $event)">
												{{value.value}}
											</mat-option>
										</ng-container>

									</cdk-virtual-scroll-viewport>
								</mat-select>
							</mat-form-field>
						</div>
						<div (mouseover)="detectTruncatedField($event, th)"
							 [matTooltip]="th.displayValue"
							 [matTooltipDisabled]="!th.truncate"
							 [ngStyle]="{'display':!th.hasFilter?'block':'none', cursor: th.isSortable? 'pointer': 'default'}"
							 class="table-th-content-container header-truncate">
							{{th.displayValue}}
						</div>
						<div *ngIf="th.isSortable" class="sorting-icon"
							 [matTooltip]=" !this.currentSorting[th.id]? 'Sortable column' : this.currentSorting[th.id]?.sortOrder">
							<i class="mdi mdi-sort-variant"
							   *ngIf="this.currentSorting[th.id]?.sortOrder.toLowerCase() === 'desc'"></i>
							<i class="mdi mdi-sort-reverse-variant"
							   *ngIf="this.currentSorting[th.id]?.sortOrder.toLowerCase() === 'asc'"></i>
							<i class="mdi mdi-sort" *ngIf="th.isSortable && !this.currentSorting[th.id]"></i>
						</div>
					</div>
				</th>
			</tr>
			</thead>
			<tbody class="table-body">


			<ng-template #recursive let-groupItems>
				<ng-container *ngFor="let group of groupItems">
					<tr *ngFor="let tr of group.rows; trackBy:trackId"
						(click)="onRowClick(tr, group, $event)"
						[attr.aria-expanded]="!group.collapsed"
						class="table-body__row"
						[ngClass]="tr.classList"
						aria-controls="collapse">
						<ng-container *ngIf="checkRow(tr)">
							<td *ngIf="actionButtons?.length > 0">
								<label class="checkbox-container checkbox-container-table-row-selection"
									   (click)="onRowSelected(tr,$event)">
									<input class="form-control"
										   type="checkbox"
										   [checked]="tr.selected"
									> <span class="checkmark"></span>
								</label>
							</td>
							<td id="collapse"
								*ngFor="let td of tr.cells"
								[ngSwitch]="td.template == null"
								[ngClass]="td.classList"
								[class.invalid]="td.state.invalid"
								[ngbCollapse]="tr.isCollapsible ? group.collapsed : false"
								(click)="cellClicked(td, tr, group, $event)"
							>
								<i *ngIf="td.collapseIcon"
								   class="mdi mr-1"
								   [ngStyle]="{'cursor':td.collapseIcon ? 'n-resize' : 'auto' }"
								   (click)="stopPropagation(tr, group, $event)"
								   [ngClass]="{
												'mdi-plus-box-outline':group.collapsed,
												'mdi-minus-box-outline':!group.collapsed
												}"></i>
								<ng-container *ngSwitchCase="true">
									<div class="text-no-whitespace text-truncate cell-container"
										 [matTooltip]="td.displayValue"
										 [matTooltipDisabled]="!td.truncate"
										 [class.collapsable]="td.collapseIcon"
										 (mouseover)="detectTruncatedField($event, td)">
										{{td.displayValue}}</div>
								</ng-container>
								<ng-container *ngSwitchCase="false">
									<ng-container csTemplateLoader="{{td.template}}" [csTemplateLoaderPayload]="td"
												  [csTemplateLoaderRow]="tr"></ng-container>
								</ng-container>
							</td>
						</ng-container>
					</tr>
					<ng-container *ngIf="group?.children.length > 0">
						<ng-container
								*ngTemplateOutlet="recursive; context:{ $implicit: group.children }"></ng-container>
					</ng-container>
				</ng-container>
			</ng-template>
			<!-- recursive -->
			<ng-container *ngTemplateOutlet="recursive; context:{ $implicit: groups }"></ng-container>

			<tr *ngIf="currentRows.length === 0">
				<td class="no-data" [attr.colspan]="noDataColSpan + (actionButtons?.length > 0 ? 1 : 0)">
					{{noDataText}}
				</td>
			</tr>
			</tbody>
			<tfoot class="table-footer"
				   *ngIf="!isServerSidePaging && (!showPagination || !showPageCounter) && (showRowCounter && rowCount > 4)">
			<tr>
				<td colspan="99">
					<div class="f-dimmed-text-color row-number">
						<i><small>Number of rows:{{ rowCount }}</small></i>
					</div>
				</td>
			</tr>
			</tfoot>
		</table>
	</div>
</div>

<div [@changingData]="isLoadingDebounced$ | async" class="loader-container table-loader-container"
	 style="pointer-events: none">
	<div class="loader-md"></div>
</div>

<ng-template csTemplateHandle="defaultArrayTpl" let-data let-showContent>
	<ng-container *ngIf="data.displayValue.length <= 2">
    <span class="badge badge-info mr-2"
		  *ngFor="let entry of data.displayValue">
          {{entry}}
    </span>
	</ng-container>
	<ng-container *ngIf="data.displayValue.length > 2">
		<ng-container *ngFor="let entry of data.displayValue; let i = index">
        <span class="badge badge-info mr-2"
			  *ngIf="i < 2">
              {{entry}}
        </span>
			<span class="badge badge-info mr-2"
				  *ngIf="i >= 2 && data.showContent">
              {{entry}}
        </span>
			<ng-container *ngIf="i == 2 && !data.showContent">
				<button class="btn btn-sm badge" (click)="showAllItems($event, data)"> +</button>
			</ng-container>
			<ng-container *ngIf="i == data.displayValue.length - 1 && data.showContent">
				<button class="btn btn-sm badge" (click)="showAllItems($event, data)"> -</button>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template csTemplateHandle="defaultBooleanTpl" let-data>
	<label class="checkbox-container checkbox-container-table-cell">
		<input class="form-control"
			   type="checkbox"
			   [checked]="data.displayValue"
		> <span class="checkmark"></span>
	</label>
</ng-template>

<ng-template csTemplateHandle="defaultNumberTpl" let-data>
	<input type="text"
		   pattern="^[0-9]+([\\.,][0-9]+)?"
		   (change)="valueChanged($event, data)"
		   [ngModel]="data.displayValue"
	/>
</ng-template>

<ng-template csTemplateHandle="defaultIconTpl" let-data>
	<i class="{{data.displayValue?.toString()}}"></i>
</ng-template>

<ng-template csTemplateHandle="defaultMenu" let-data let-rowData=rowData>
	<div class="handle-row-click table-row-menu-container">
		<ng-container *ngFor="let menuItem of data.value">
			<button class="btn btn--single-icon">
				<i class="{{menuItem | tableMenuIcon}}"
				   (click)="menuItemSelected(menuItem, data, rowData, $event)"
				   [matTooltip]="menuItem.label"></i>
			</button>
		</ng-container>
	</div>
</ng-template>
