import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Type
}                                   from '@angular/core';
import { DashboardCombiData }       from '../../models/dashboard-combi-data';
import { IDashboardPanelComponent } from '../../models/i-dashboard-panel-component';
import { ArrayUtils }               from '@cs/core';
import { PopoverService }           from '@cs/components/popover';
import { DashboardLegendComponent } from '../dashboard-legend/dashboard-legend.component';
import { DashboardListComponent }   from '../dashboard-list/dashboard-list.component';

@Component({
	selector:        'cs-dashboard-combi-entry-state',
	templateUrl:     './dashboard-combi-entry-state.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles:          [
		`:host {
		width: 100%;
	}`
	]
})
export class DashboardCombiEntryStateComponent implements IDashboardPanelComponent<DashboardCombiData> {

	name: string;

	data: DashboardCombiData;
	entryStatePosition                              = {transform: 'rotate(0deg)'};
	legendComponent: Type<DashboardLegendComponent> = DashboardLegendComponent;
	listComponent: Type<DashboardListComponent>     = DashboardListComponent;

	constructor(private changeRef: ChangeDetectorRef, private popper: PopoverService) {
	}

	update(data: DashboardCombiData): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

}

