import { IMembersTree, IMember }                                            from '@cs/core';
import { Logger }                                                           from '@cs/components/util';
import zipObject                                                            from 'lodash/zipObject';
import last                                                                 from 'lodash/last';
import { IDataSet }                                                         from '../interfaces';
import { createToObjectWithLowerCaseKeys, filter, isEmptyObject, uniqueBy } from '@cs/components/util';
import { isNullOrUndefined }                                                from '@cs/core';
import { DataGridCellType, GridItemType, RowState, UiTypes }                from '../enums/data-grid.enum';
import {
	DataGridLookupAction
}                                                                           from '../classes/data-grid-action';

import { DataGridHelpers }        from './data-grid-helpers';
import { DataGridElementFactory } from './data-grid-element.factory';
import { DataGridRuleEnforcer }   from './data-grid-rule-enforcer';
import {
	GridRule, GridHeaderCell, GridSheet,
	GridDataCell, GridGroup, GridHeaderRow, GridDataRow
}                                 from '../models';
import { GridOptions }            from '../classes/grid-options';
import { DataGridUIState }        from '../classes/data-grid-u-i-state';
import { GridDataCellMetaValues } from '../classes/grid-data-cell-meta-values';
import { CellBehavior }           from '../classes/cell-behavior';
import { Injector }               from '@angular/core';
import { FormatProviderService }  from '@cs/common';


export class DataGridInterpreter {
	/**
	 * Added injector in this way to avoid a lot of method changes. Needs to be different in newer version
	 */
	static injector: Injector = null;

	static fillDataGridWithDataRowsAsync(sheets: Array<GridSheet>, data: IDataSet, options: GridOptions): Promise<Array<GridSheet>> {

		function handler(resolve, reject) {
			resolve(DataGridInterpreter.fillDataGridWithDataRows(sheets, data, options));
		}

		return new Promise<Array<GridSheet>>(handler);
	}

	static fillDataGridWithDataRows(sheets: Array<GridSheet>, data: IDataSet, options: GridOptions): Array<GridSheet> {

		const filledSheets = [];
		for (const sheet of sheets) {
			let filledSheet;
			if (options.structureData.dimensionTrees.rowTree.treeDepth === 0) {
				filledSheet = DataGridInterpreter.createBodyWithData(sheet, data, options);
			}
			filledSheet = DataGridInterpreter.fillBodyWithData(sheet, data, options);

			// find all items that have rules implemented
			DataGridRuleEnforcer.executeRules(options.rules, sheet, true);

			filledSheets.push(filledSheet);
		}
		return filledSheets;

	}

	static async createEmptySheetsAsync(sheets: IMembersTree,
																			columnTree: IMembersTree,
																			rowTree: IMembersTree,
																			options: GridOptions): Promise<Array<GridSheet>> {
		function handler(resolve, reject) {
			resolve(DataGridInterpreter.createEmptySheets(sheets, columnTree, rowTree, options));
		}

		return new Promise<Array<GridSheet>>(handler);
	}

	static createEmptySheets(sheets: IMembersTree,
													 columnTree: IMembersTree,
													 rowTree: IMembersTree,
													 options: GridOptions): Array<GridSheet> {

		const sheetsToRender = [];
		if (isNullOrUndefined(sheets) || isNullOrUndefined(sheets.members) || sheets.members.length === 0) {
			return sheetsToRender;
		}
		for (const sheet of sheets.members) {

			// create an empty sheet with keys and properties
			const sheetKeys     = Object.assign({}, options.baseKeys, sheet.keys);
			const sheetToRender = DataGridElementFactory.createSheet(sheets.key, sheetKeys, sheet.properties, options);

			const columsRows = DataGridInterpreter.ConvertToGridHeaderRows(sheetToRender, options, columnTree.members);

			if (isNullOrUndefined(columsRows) || columsRows.length === 0)
				continue;

			DataGridInterpreter.injectColumns(columsRows, options);

			// Add Total and offset columns
			const lastHeaderRow = columsRows[columsRows.length - 1];
			DataGridInterpreter.addCalculationColumns(lastHeaderRow, options, sheetKeys);
			DataGridInterpreter.addCheckboxColumn(lastHeaderRow, options);
			DataGridInterpreter.addRowButtonColumn(lastHeaderRow, options);


			// if there are no row groups than render the table flat
			const renderRowsFlat = options.renderFlat || options.config.dimensionTrees.rowTree.length < 2;
			// extra ignore for members items that are id overal
			const members        = options.config.dimensionTrees.rowTree.length === 0 ?
				(rowTree.members.length > 0 && rowTree.key.toLowerCase() !== 'idoverall' ? rowTree.members : []) : rowTree.members;

			sheetToRender.groups = DataGridInterpreter.ConvertToGridGroups(sheetToRender, columsRows, members, [], renderRowsFlat, options);

			let addTotalRow = true;
			if (!isNullOrUndefined(options.config.calculations) &&
				!isNullOrUndefined(options.config.calculations.hideTotalRow)) {
				addTotalRow = !options.config.calculations.hideTotalRow;
			}

			// always add total row to the sheet, even if there no data (yet)
			if (addTotalRow)
				DataGridInterpreter.addTotalRow(sheetToRender);

			// DataGridInterpreter.parseColGroupSettings(options.colgroupSettings, sheetToRender);


			sheetsToRender.push(sheetToRender);
		}

		return sheetsToRender;
	}


	private static ConvertToGridDataRows(options: GridOptions, sheet: GridSheet, dataGridHead: Array<GridHeaderRow>,
																			 members: Array<IMember>, dataRows: GridDataRow[] = [],
																			 group: number                                    = 0) {

		const formatService = DataGridInterpreter.injector.get(FormatProviderService);

		for (const rowConf of members) {

			const values = [];
			let rowKeys  = {};

			// create Row identifier
			if (!isEmptyObject(rowConf.keys)) {
				// Check if the row has keys defined
				rowKeys = Object.assign({}, rowConf.keys, rowConf.virtualKeys);
				// if (rowConf.key)
				//   rowKeys[rowConf.key] = rowConf.id;
			} else {
				// when both are absent then create own key
				rowKeys[rowConf.key] = rowConf.id;
			}

			if (this.ignoreRowByKey(options, rowKeys)) continue;

			// Add sheet keys to the row
			Object.assign(rowKeys, sheet.keys);

			// Fill the other column cells with values TODO
			const lastColumnRow = dataGridHead[dataGridHead.length - 1];
			const hasCheckbox   = lastColumnRow.columns.find(x => x.cellType === DataGridCellType.Checkbox);

			if (hasCheckbox) {
				// Fill first column cell based on row label
				const checkbox = new GridDataCell({
					value:      rowConf.properties.label,
					index:      0,
					cellType:   DataGridCellType.Checkbox,
					keys:       rowKeys,
					properties: rowConf.properties
				}, formatService);

				this.setupCellBasedOnHeaderType(checkbox, DataGridCellType.Checkbox);

				values.push(checkbox);
			}

			// Look for the label column, but it does not always have this property set for some reason.
			const labelColumn = lastColumnRow.columns.find(x => x.isLabel === true);
			const displayKey  = !isNullOrUndefined(labelColumn) ? labelColumn.displayKey : 'label';

			// Fill first column cell based on row label
			const label = new GridDataCell({
				value:          !isNullOrUndefined(rowConf.properties[displayKey]) ? rowConf.properties[displayKey] : rowConf.properties.label,
				index:          0,
				cellType:       DataGridCellType.Injected,
				keys:           rowKeys,
				properties:     rowConf.properties,
				staticCssClass: 'group-' + group
			}, formatService);

			values.push(label);

			for (let i = hasCheckbox ? 2 : 1; i < lastColumnRow.columns.length; i++) {
				const col        = lastColumnRow.columns[i];
				const isEditable = false;

				// Add cell to row
				const cell              = new GridDataCell({}, formatService);
				cell.cellState.editable = isEditable;
				// give the cell a celltypr
				cell.cellType           = col.cellType;
				// Add calculation cell to the calculator
				if (col.cellType === DataGridCellType.Total ||
					col.cellType === DataGridCellType.Offset)
					sheet.calculator.addCalculationCell(cell);

				this.setupCellBasedOnHeaderType(cell, col.cellType);

				// Set the keys of the row and column to the cell so we can find the cell by keys
				cell.keys = Object.assign({}, cell.keys, col.keys);
				cell.keys = Object.assign({}, cell.keys, rowKeys);

				values.push(cell);
			}

			const row = new GridDataRow(values, rowKeys);
			if (!rowConf.isEndNode) {
				row.rowState   = RowState.Total;
				row.isSubGroup = true;
			}

			row.properties = rowConf.properties;
			if (rowConf.isGroup) {
				row.id += '_group'; // Fix the issue where the find row returns a group row
			}
			if (!rowConf.isGroup && options.rowButtons) {
				row.buttons = options.rowButtons.map(btn => Object.assign({}, btn));
			}
			dataRows.push(row);

			// Check if each row has members, if so add these to the data-grid
			if (rowConf.members && rowConf.members.length > 0) {
				dataRows.concat(DataGridInterpreter.ConvertToGridDataRows(options, sheet, dataGridHead,
					rowConf.members, dataRows, group + 1));
			}
		}
		return dataRows;
	}

	private static ConvertToGridGroups(sheet: GridSheet, dataGridHead: Array<GridHeaderRow>, members: Array<IMember>,
																		 groups: Array<GridGroup> = [], renderFlat = false, options: GridOptions) {
		const isNested = options.isNested;

		let dataGroup = new GridGroup();
		if (members.length === 0) {
			dataGroup.columsRows = dataGridHead.concat([]);
		}

		for (const rowConf of members) {

			const values = [];
			let rowKeys  = {};

			// create Row identifier
			if (!isEmptyObject(rowConf.keys)) {
				// Check if the row has keys defined
				rowKeys = Object.assign({}, rowConf.keys, rowConf.virtualKeys);
				// if (rowConf.key)
				//   rowKeys[rowConf.key] = rowConf.id;
			} else {
				// when both are absent then create own key
				rowKeys[rowConf.key] = rowConf.id;
			}


			// Add sheet keys to the cell
			Object.assign(rowKeys, sheet.keys);

			// get last inserted header row
			const lastColumnRow = dataGridHead[dataGridHead.length - 1];

			// Fill first column cell based on row label
			const label = DataGridElementFactory.createGridHeaderCell({
				value:      rowConf.properties[lastColumnRow.columns[0].displayKey],
				index:      0,
				cellType:   lastColumnRow.columns[0].cellType,
				isLabel:    true,
				keys:       rowKeys,
				properties: rowConf.properties
			});

			// Add the injectedKey again.
			label.keys = Object.assign({}, label.keys, {injectedkey: Object.getOwnPropertyNames(rowConf.virtualKeys).join('_').toLowerCase()});
			values.push(label);

			// Fill the other column cells with values  TODO
			for (let i = 1; i < lastColumnRow.columns.length; i++) {
				const col     = lastColumnRow.columns[i];
				const cell    = DataGridElementFactory.createGridHeaderCell(col);
				cell.value    = ''; // some value to give the tr some height
				cell.cssClass = 'hidden';
				values.push(cell);
			}

			// copy headers to a new reference
			const headersColumns = dataGridHead.concat([]);

			// when not rendering flat create for each member a new group
			if (!renderFlat)
				dataGroup = new GridGroup();

			// check if it's the first group and create a new group
			if (rowConf.members && rowConf.members.length > 0
				&& rowConf.depth === 0) {

				if (groups.length === 0 && !isNested) {
					const lastHeaderRow = headersColumns[headersColumns.length - 1];

					// Replace label for the first table head element
					lastHeaderRow.columns[0].value    = rowConf.properties.label;
					lastHeaderRow.columns[0].isLabel  = true;
					lastHeaderRow.columns[0].cssClass = (lastHeaderRow.columns[0].cssClass).replace('table-row-col-hide', '');

					// workaround for checkbox column
					if (options.showCheckboxes) {
						lastHeaderRow.columns[0].cellType = label.cellType; // 'Checkbox'
						lastHeaderRow.columns[1].isLabel  = true;
					}

					// set headers to group
					dataGroup.columsRows = headersColumns;
				} else {
					// Just show the label as een stand alone row
					dataGroup.columsRows = [new GridHeaderRow(values)];
				}

			} else {
				if (!isNested) {
					// If no members add the original headers to the group because it will be only one group
					dataGroup.columsRows = headersColumns;
				} else {
					// Just show the label as een stand alone row
					const lastHeaderRow = headersColumns[headersColumns.length - 1];
					for (const col of lastHeaderRow.columns) {

						if (!col.isLabel)
							col.cssClass = 'hidden';

					}
					dataGroup.columsRows = [lastHeaderRow];
				}

			}

			// Check if the group has data rows to render otherwise create an empty dataRow array
			if (!isNullOrUndefined(rowConf.members) && rowConf.members.length > 0) {
				dataGroup.dataRows = DataGridInterpreter.ConvertToGridDataRows(options, sheet, dataGridHead,
					rowConf.members, []);
			} else {
				if (renderFlat)
					dataGroup.dataRows = dataGroup.dataRows.concat(
						DataGridInterpreter.ConvertToGridDataRows(options, sheet, dataGridHead, [rowConf]));
				else
					dataGroup.dataRows = DataGridInterpreter.ConvertToGridDataRows(options, sheet, dataGridHead, [rowConf]);
			}

			// Add keys to the group if no show group totals is defined than just add the sheet keys,
			// TODO: This is because of the "CSPM-386 not adding the correct index when expanding a row" issue. If we take the values[0].keys
			// than a
			// issue occurs where Sheet Total row has the same id
			// as the first row of the index
			(!isNullOrUndefined(options.config.calculations) && options.config.calculations.showSheetTotals)
				? (dataGroup.keys = values[0].keys) : (dataGroup.keys = sheet.keys);


			// Don't add each datagroup to the grid when rendering flat
			if (!renderFlat) {
				groups.push(dataGroup);
				if (!isNullOrUndefined(options.config.calculations) && options.config.calculations.showSheetTotals)
					this.addTotalRowToGroup(sheet, dataGroup);
			}
		}

		// only add one datagroup when rendering flat
		if (renderFlat) {
			if (isNullOrUndefined(dataGroup.keys))
				dataGroup.keys = sheet.keys;
			groups.push(dataGroup);
		}
		return groups;
	}

	private static ConvertToGridHeaderRows(sheetToRender: GridSheet, options: GridOptions, members: Array<IMember>,
																				 tableHeadRows: Array<GridHeaderRow> = []) {

		Logger.hasValue(members, 'members');

		for (let i = 0; i < members.length; i++) {
			const colConf                      = members[i];
			const colMembers: GridHeaderCell[] = [];
			// Disable tooltip headers
			if (!isNullOrUndefined(options.config.dimensionTrees.columnTree) && options.config.dimensionTrees.columnTree.length > 0) {
				const colSet = options.config.dimensionTrees.columnTree.find(value =>
					(value.name === colConf.key || !isNullOrUndefined(colConf.keys[value.name])) && value.disableDescription);
				if (!isNullOrUndefined(colSet)) {
					colConf.properties.disableDescription = true;
				}
			}
			// get the display property for the column
			const display           = options.config.dimensionTrees.columnTree[colConf.depth].display;
			const columnPropertyKey = !isNullOrUndefined(display) ? display : 'labelMin';
			const colCell           = DataGridElementFactory.createGridHeaderCell({
				properties: colConf.properties,
				keys:       Object.assign({}, colConf.keys, sheetToRender.keys), // combine the sheet key to the header cell keys
				value:      colConf.properties[columnPropertyKey],
				depth:      colConf.depth
			});

			colMembers.push(colCell);

			let row: GridHeaderRow;
			// Create row If al exist use that one
			if (!tableHeadRows[colConf.depth]) {
				row = new GridHeaderRow(colMembers);
				tableHeadRows.push(row);
			} else {
				row = tableHeadRows[colConf.depth];
				row.columns.push(...colMembers);
			}

			if (colConf.members) {
				// Set colspan based by the amount of children
				colCell.colSpan = colConf.members.length;
				colCell.isGroup = true;
				DataGridInterpreter.ConvertToGridHeaderRows(sheetToRender, options, colConf.members, tableHeadRows);
			}
		}
		return tableHeadRows;
	}

	private static fillBodyWithData(sheet: GridSheet, dataSet: IDataSet, options: GridOptions): GridSheet {

		const mergedData = [];

		const formatService = DataGridInterpreter.injector.get(FormatProviderService);

		const columns = dataSet.columns.map(x => x.toLowerCase());
		// Merge data rows to have keys
		for (const row of dataSet.data) {
			mergedData.push(zipObject(columns, row));
		}

		const sheetKeys = DataGridHelpers.cleanBaseKeys(options, sheet.keys);

		const sheetData = filter(<any>mergedData, <any>sheetKeys);
		// if (!isNullOrUndefined(sheetData) && sheetData.length === 0) {
		//   return sheet;
		// }


		for (const group of sheet.groups) {

			if (isNullOrUndefined(group.dataRows)) {
				Logger.Warning(`No dataRows on the group: ${group.keys}`);
				return sheet;
			}

			// Fill all rows for this col
			for (let dataRowIndex = 0; dataRowIndex < group.dataRows.length; dataRowIndex++) {

				const dataRow = group.dataRows[dataRowIndex];

				// remove Total row if it's the only row
				if (group.dataRows.length === 1 && dataRow.rowState === RowState.Total) {
					group.dataRows.splice(dataRowIndex, 1);
					continue;
				}

				if (dataRow.isGroup) {
					continue;
				}

				// Cargo-forecastool TODO: Enable by rule
				if (options.client.isCargoDataEntryTool) {
					dataRow.spinnerOnSelected = true;
				}

				const relevantDataHeaderKeyNames = DataGridHelpers.compareDataWithStructure(Object.keys(dataRow.keys), options.dataKeyParts);
				const dataRowKeys                = DataGridHelpers.cleanBaseKeys(options, dataRow.keys, false, relevantDataHeaderKeyNames);


				// Find array index for the values by Column key
				const rowData = filter(sheetData, dataRowKeys, <never>null, 'all', true);

				if (isNullOrUndefined(group.columsRows)) {
					Logger.Warning(`No columnsRow on the group: ${group.keys}`);
				}
				// Use last headerRow for column reference
				const lastHeaderRow = group.columsRows[group.columsRows.length - 1];

				if (isNullOrUndefined(lastHeaderRow.columns)) {
					Logger.Warning(`No columns found on the lastHeaderRow`);
				}

				for (let colIndex = 0; colIndex < lastHeaderRow.columns.length; colIndex++) {

					const headerCol                     = lastHeaderRow.columns[colIndex];
					const headerColKeys                 = headerCol.keys || {};
					const colRelevantDataHeaderKeyNames = DataGridHelpers.compareDataWithStructure(Object.keys(headerColKeys), options.dataKeyParts);
					// Ignore the first table column because it's a label
					if (headerCol.isLabel) {
						continue;
					}
					let cellData = [];

					// Check if its not the total row
					if (dataRow.rowState !== RowState.Total && headerCol.cellType !== DataGridCellType.Checkbox
						&& headerCol.cellType !== DataGridCellType.RowMenu) {
						// Check if the header has no key but it has keys. If so this is NOT an INJECTED column
						if (!isNullOrUndefined(headerCol.keys) && isNullOrUndefined(headerCol.key)) {
							const headerColKeys = DataGridHelpers.cleanBaseKeys(options, headerCol.keys, false, colRelevantDataHeaderKeyNames);
							cellData            = filter(<any>rowData, <any>headerColKeys);
						} else if (rowData.length > 0) {
							// this is usually a injected column that has no keys
							cellData = [{value: rowData[0][headerCol.key]}];
						}
					}

					// dataRow.isEditable = this.checkEditStatus(cellData, headerCol, sheet);
					if (isNullOrUndefined(cellData)) {
						Logger.Warning(`No cellData found`);
					}
					const cell = new GridDataCell(Object.assign({},
						dataRow.values[colIndex],
						<Partial<GridDataCell>>{
							cellData: cellData,
							lookup:   headerCol.lookup,
							cellType: headerCol.cellType
						}), formatService);

					// add DatasourceValue as Key for injected columns
					if (cell.cellType === DataGridCellType.Injected) {
						const dataSourceKeys = options.useDataSourceValueAsKey.map(d => {
							return d.toLowerCase();
						});
						const rowKeys        = DataGridHelpers.createKeysObject(dataRowKeys, dataSourceKeys);
						cell.keys            = Object.assign(cell.keys, rowKeys);
						cell.metaValues      = new GridDataCellMetaValues(Object.assign(cell.metaValues, {columnkey: headerCol.key}));
					}

					// Set the behaviour on the cell. This is also for visual purposes
					if (!isNullOrUndefined(headerCol.behavior)) {
						cell.behavior = headerCol.behavior;
					}
					cell.setInitialValue();

					// implement lookup for value
					cell.updateValue();

					this.setupCellBasedOnHeaderType(cell, headerCol.cellType);

					const totalLabelIndex = dataRow.values[0].cellUIState.uiType === UiTypes.Checkbox ? 1 : 0;

					// Set the label and cell calculatations for Total row
					if (dataRow.rowState === RowState.Total && !dataRow.isSubGroup) {
						cell.behavior = new CellBehavior();
						// colIndex 0 does never reach here, just always add the total label (yes, i'm lazy)
						dataRow.values[totalLabelIndex].updateValue('Total');
					}
					if (colIndex !== totalLabelIndex) {
						if (headerCol.cellType === DataGridCellType.Total ||
							headerCol.cellType === DataGridCellType.Offset ||
							headerCol.cellType === DataGridCellType.Data) {
							sheet.calculator.addCalculationCell(cell, !dataRow.isGroupTotal ? GridItemType.Sheet : GridItemType.Group);
						}
					}
					dataRow.values[colIndex] = cell;
				}
			}
		}

		return sheet;
	}

	/**
	 * Create dataRows based on the dataset. By getting the unique id's based on Primary Key of the injectedColumns.
	 * Each unique id is a new row to be filled with all the data from the dataset.

	 */
	private static createBodyWithData(sheet: GridSheet, dataSet: IDataSet, options: GridOptions): GridSheet {

		const mergedData = [];

		if (dataSet.data.length === 0) {
			return sheet;
		}
		const formatService = DataGridInterpreter.injector.get(FormatProviderService);

		const lowerCaseCols = dataSet.columns.map(x => x.toLowerCase());
		// Merge data rows to have keys
		for (const row of dataSet.data) {
			mergedData.push(zipObject(lowerCaseCols, row));
		}
		const sheetKeys = DataGridHelpers.cleanBaseKeys(options, sheet.keys);
		const grouped   = filter(<any>mergedData, sheetKeys);

		Logger.hasValue(sheet.groups, 'sheet.groups');

		let primaryKeys = options.injectColumns.map(c => {
			return c.key.toLowerCase();
		});

		if (options.hasOwnProperty('useDataSourceValueAsKey') && !isNullOrUndefined(options.useDataSourceValueAsKey)) {
			const dataSourceKeys = options.useDataSourceValueAsKey.map(d => {
				return d.toLowerCase();
			});
			primaryKeys          = [...primaryKeys, ...dataSourceKeys];
		}

		const rowsToCreate = uniqueBy(grouped, primaryKeys);

		for (const group of sheet.groups) {
			const lastHeaderRow = <GridHeaderRow>last(group.columsRows);
			for (const rowData of rowsToCreate) {
				const values  = [];
				// create keys for the row id
				const rowKeys = DataGridHelpers.createKeysObject(rowData, primaryKeys);

				if (this.ignoreRowByKey(options, rowKeys)) continue;

				for (const header of lastHeaderRow.columns) {
					let cellKeys = Object.assign({}, rowKeys);
					if (header.key) {
						cellKeys = DataGridHelpers.createKeysObject(rowData, [header.key]);
					}
					const cell = new GridDataCell({
						keys:     Object.assign(cellKeys, header.keys),
						cellType: header.cellType
					}, formatService);

					if (header.cellType === DataGridCellType.RowMenu) {
						cell.cellUIState.uiType             = UiTypes.RowMenu;
						cell.cellState.readonly             = false;
						cell.cellState.editable             = true;
						cell.cellUIState.hasReadOnlyVersion = false;
					}
					if (header.cellType === DataGridCellType.Injected) {
						cell.metaValues = new GridDataCellMetaValues(Object.assign(cell.metaValues, {columnkey: header.key}));
					}

					values.push(cell);
				}

				const row = new GridDataRow(values, rowKeys);
				if (options.rowButtons) {
					row.buttons = options.rowButtons.map(btn => Object.assign({}, btn));
				}
				group.dataRows.push(row);
			}
		}

		return sheet;
	}

	private static injectColumns(tableHeadRows: Array<GridHeaderRow>, options: GridOptions) {
		const rowSets = options.choiceSets.rowSets;
		let isLabel   = false;

		const setup = {
			label:   '',
			name:    '',
			display: 'label' as 'label' | 'labelMin'
		};
		if (options.config.dimensionTrees.rowTree.length > 0) {
			setup.label   = options.config.dimensionTrees.rowTree[0].hasOwnProperty('label') ? options.config.dimensionTrees.rowTree[0].label : '';
			setup.name    = options.config.dimensionTrees.rowTree[0].name;
			setup.display = options.config.dimensionTrees.rowTree[0].hasOwnProperty('display') ?
				options.config.dimensionTrees.rowTree[0].display : 'label';
		} else if (options.structureData.dimensionTrees.rowTree.levels.length > 0) {
			setup.label   = options.structureData.dimensionTrees.rowTree.levels[0];
			setup.name    = options.structureData.dimensionTrees.rowTree.memberTree.key;
			setup.display = 'label';
		}


		// If no row-sets available, use first level of the rowtree as label
		if (isNullOrUndefined(rowSets) || rowSets.length === 0) {
			rowSets.push({
				label:      setup.label,
				name:       setup.name,
				memberList: '',
				display:    setup.display || 'label',
				width:      undefined,
				type:       null,
				unique:     null
			});
			isLabel = true;
		}

		if (isNullOrUndefined(tableHeadRows) || tableHeadRows.length === 0) {
			Logger.ThrowError('No tableHeadRows found');
		}

		for (let i = 0; i < rowSets.length; i++) {
			const rowSet   = rowSets[i];
			const colLabel = DataGridElementFactory.createGridHeaderCell({
				displayKey: rowSet.display || 'label',
				index:      i,
				cellType:   DataGridCellType.Injected,
				isLabel:    isLabel,
				isInjected: true,
				value:      rowSet.label,
				key:        rowSet.name,
				width:      rowSet.width
			});

			if (!isLabel) {
				switch (rowSet.type) {
					case UiTypes.Select: {
						const ruleChoiceset = new DataGridLookupAction({
							key:              rowSet.name,
							display:          rowSet.display || 'label',
							unique:           rowSet.unique || null,
							disableNotUnique: rowSet.disableNotUnique || false,
							lookupType:       'ChoiceSet'
						});

						options.rules.push(new GridRule({actions: [ruleChoiceset]}));

						colLabel.lookup.push(new DataGridLookupAction({
							key:     rowSet.name,
							display: rowSet.display || 'label'
						}), ruleChoiceset);
						colLabel.cellUIState = new DataGridUIState({uiType: UiTypes.Select, hasReadOnlyVersion: true});
						break;
					}
					case UiTypes.DatePicker: {
						colLabel.cellUIState = new DataGridUIState({uiType: UiTypes.DatePicker, hasReadOnlyVersion: true});
						break;
					}
					default: {
						colLabel.cellUIState = new DataGridUIState(null);
					}
				}

			}

			if (options.config.dimensionTrees.rowTree.length > 0 && options.config.dimensionTrees.rowTree[0].hasOwnProperty('behavior'))
				colLabel.behavior = new CellBehavior(options.config.dimensionTrees.rowTree[0].behavior);

			colLabel.cssClass += ' table-row-label';

			// Hide the column
			if (!colLabel.value)
				colLabel.cssClass += ' table-row-col-hide';

			colLabel.properties = {
				description: '',
				groupLabel:  '',
				label:       'Label',
				labelMin:    'Label',
				sortIndex:   0,
				id:          'label',
				importKey:   ''
			};
			colLabel.keys       = {injectedKey: colLabel.key};

			options.injectColumns.push(colLabel);

			// inject into the last row
			const lastHeaderRow = tableHeadRows[tableHeadRows.length - 1];
			lastHeaderRow.columns.splice(i, 0, colLabel);

			// when there is only one header row don't create empty header cells
			if (tableHeadRows.length === 0)
				continue;

			const empty    = DataGridElementFactory.createGridHeaderCell(colLabel);
			empty.cssClass = 'table-row-col-hide';
			empty.value    = '';
			for (let i2 = 0; i2 < (tableHeadRows.length - 1); i2++) {
				const row = tableHeadRows[i2];
				row.columns.splice(0, 0, empty);
			}
		}

		return tableHeadRows;
	}

	private static addCalculationColumns(tableHeadRow: GridHeaderRow, options: GridOptions, sheetKeys: { [key: string]: number }) {
		const c       = options.config.calculations;
		const rowSets = [];

		if (isNullOrUndefined(c) || c.total)
			rowSets.push({
				label:      'Total',
				name:       'total',
				memberList: '',
				display:    'calc',
				cellType:   DataGridCellType.Total
			});
		if (!isNullOrUndefined(c) && c.offset)
			rowSets.push({
				label:      'Offset',
				name:       'offset',
				memberList: '',
				display:    'calc',
				cellType:   DataGridCellType.Offset
			});

		const extraCols = [];

		for (const rowSet of rowSets) {
			const colLabel = DataGridElementFactory.createGridHeaderCell({
				displayKey: 'value',
				index:      100,
				cellType:   rowSet.cellType,
				isLabel:    false,
				isInjected: true,
				value:      rowSet.label,
				keys:       sheetKeys
			});
			extraCols.push(colLabel);
		}

		tableHeadRow.columns.push(...extraCols);

	}

	private static addCheckboxColumn(tableHeadRow: GridHeaderRow, options: GridOptions) {
		if (!options.showCheckboxes)
			return;

		const checkboxCol = DataGridElementFactory.createGridHeaderCell({
			displayKey: 'value',
			index:      0,
			cellType:   DataGridCellType.Checkbox,
			isLabel:    false,
			isInjected: true,
			value:      '',
			cssClass:   ' table-row-col-hide'
			// key: rowSet.name
		});

		checkboxCol.cellUIState.uiType             = UiTypes.Checkbox;
		checkboxCol.cellUIState.hasReadOnlyVersion = false;


		tableHeadRow.columns.splice(0, 0, checkboxCol);

	}

	private static addRowButtonColumn(tableHeadRow: GridHeaderRow, options: GridOptions) {
		if (!options.rowButtons)
			return;

		const rowButtonCol = DataGridElementFactory.createGridHeaderCell({
			displayKey: 'value',
			index:      0,
			cellType:   DataGridCellType.RowMenu,
			isLabel:    false,
			isInjected: true,
			value:      ''
			// key: rowSet.name
		});

		rowButtonCol.cellUIState.uiType             = UiTypes.RowMenu;
		rowButtonCol.cellUIState.hasReadOnlyVersion = false;

		tableHeadRow.columns.push(rowButtonCol);
	}

	private static addTotalRow(sheetToRender: GridSheet) {
		DataGridElementFactory.addTotalRow(sheetToRender);
	}

	private static addTotalRowToGroup(sheetToRender: GridSheet, dataGroup: GridGroup) {
		DataGridElementFactory.addTotalRow(sheetToRender, dataGroup);
	}

	private static setupCellBasedOnHeaderType(cell: GridDataCell, cellType: DataGridCellType) {
		if (cellType === DataGridCellType.Checkbox) {
			cell.cellUIState.uiType             = UiTypes.Checkbox;
			cell.cellState.readonly             = false;
			cell.cellState.editable             = true;
			cell.cellUIState.hasReadOnlyVersion = false;
		}

		if (cellType === DataGridCellType.RowMenu) {
			cell.cellUIState.uiType             = UiTypes.RowMenu;
			cell.cellState.readonly             = false;
			cell.cellState.editable             = true;
			cell.cellUIState.hasReadOnlyVersion = false;
		}
	}

	private static ignoreRowByKey(options: GridOptions, rowKeys): boolean {
		// This function ignores the rows when the provided keys are found in the row keys
		if (options.client.ignoreRowsByKey) {
			const lowerRowKeys = createToObjectWithLowerCaseKeys(rowKeys);
			let abort          = false;
			for (const key of Object.keys(options.client.ignoreRowsByKey)) {
				const lowerKey = key.toLowerCase();
				if (lowerRowKeys.hasOwnProperty(lowerKey) && lowerRowKeys[lowerKey] === options.client.ignoreRowsByKey[key]) {
					abort = true;
					break;
				}
			}
			// If abort then go to next row
			if (abort)
				return true;
		}

		return false;

	}
}
