import { PmNavbarItem, PmNavbarItemType } from './models/pm-navbar-item.model';
import { NavBarItem }                     from './models/nav-bar-item';

/**
 * Parse the response to @Link(PmNavbarItem)
 */
export function getNavigationItems({
                                     items,
                                     routeToParent = null,
                                     parentAppParams = null,
                                     iconsLib = new Map<string, string>(),
                                     actions = new Map<string, (item: PmNavbarItem) => void>(),
                                     parentSortIndex = null
                                   }: {
  items: { [p: string]: NavBarItem },
  parentAppParams?: { [key: string]: any }, routeToParent?: string[],
  iconsLib?: Map<string, string>, actions?: Map<string, (item: PmNavbarItem) => void>, parentSortIndex?: number
}) {
  const output: PmNavbarItem[] = [];
  for (const key of Object.keys(items)) {

    const route = routeToParent ? routeToParent.concat() : [];

    const result = items[key] as NavBarItem;

    const item = new PmNavbarItem({
      name:                 key,
      label:                result.label,
      fullLabel:            result.fullLabel,
      disabled:             result.disabled,
      icon:                 iconsLib.get(key) || iconsLib.get('default'),
      routeToParent:        JSON.parse(JSON.stringify(route)),
      params:               result.params,
      ignoreNameForRouting: result.ignoreNameForRouting,
      type:                 result.type as PmNavbarItemType,
      appParams:            parentAppParams ? Object.assign({}, parentAppParams, result.appParams) : result.appParams,
      action:               actions.get(key) || actions.get('default'),
      sortIndex:            result.sortIndex != null ? result.sortIndex : parentSortIndex,
      preserveQueryParams:  result.preserveQueryParams
    });

    item.path = result.path ? result.path : key;

    if (result.type === 'NAV_CONTAINER') {
      if (!result.ignoreNameForRouting)
        route.push(key);

      output.push(...getNavigationItems({
        items:           result.items,
        parentAppParams: result.appParams,
        routeToParent:   route,
        iconsLib:        iconsLib,
        actions:         actions,
        parentSortIndex: result.sortIndex
      }));
      continue;
    }

    if (result.items) {
      // add parent path to route
      if (!result.ignoreNameForRouting)
        route.push(key);

      item.values = getNavigationItems({
        items:           result.items,
        parentAppParams: result.appParams,
        routeToParent:   route,
        iconsLib:        iconsLib,
        actions:         actions,
        parentSortIndex: null
      });
    }

    output.push(item);
  }
  return output;
}

/**
 * Helper method to find a NavItem based on certain condition
 * @param items the array of items that should be searched
 * @param exp The expression that validates if the NavItem matches
 */
export function findPmNavItem(items: Array<PmNavbarItem>, exp: (value: PmNavbarItem) => boolean): PmNavbarItem | null {
  for (const item of items) {
    if (exp(item)) {
      return item;
    } else if (item.values.length > 0) {
      const found = findPmNavItem(item.values, exp);
      if (found)
        return found;
    }
  }
  return null;
}

export function findNavigationItemBasedOnUrl(urlFragments: string[], allNavItems: Array<PmNavbarItem>) {
  for (let index = urlFragments.length; index > -1; index--) {
    const routeFragments = urlFragments.slice(0, index);
    const pathToFind     = routeFragments.join('/');
    const found          = findPmNavItem(allNavItems, item => item.getPath === pathToFind && !item.ignoreNameForRouting);
    if (found) {
      return found;
    }
  }
  return null;
}
