<!-- note: hover-corner has position:absolute and will block any MouseEvents below it, keep small -->
<div #hovercorner id="hover-corner" class="hover-corner"></div>

<div #container id="cs-grid-data-cell" class="cell-container">
  <div class="data-grid-cell-background">
  </div>
  <div class="data-grid-cell-edit-line">
  </div>
  <ng-container *ngIf="cell.cellState.editable  && !cell.cellUIState.uiType">

    <input #inputCell
           type="text"
           (change)="notifyCellInputChanged($event)"
           (keydown.tab)="handleCellKeyboardInput($event)"
           cs-auto-focus
           pattern="^[0-9]+([\\.,][0-9]+)?"
           min="{{minimumValue}}"
           [attr.value]="cell.inputStartValue"/>
  </ng-container>
  <ng-container *ngIf="!cell.cellState.editable">
    <div class="cell-content">
      <ng-container *ngIf="cell.behavior.action === 'None'">
        {{cell.displayValue}}
      </ng-container>
      <ng-container *ngIf="cell.behavior.action !== 'None'">
        <a>
          <i *ngIf="cell.behavior.params.iconClass"
             class="cell-icon {{cell.behavior.params.iconClass}}"></i>
          {{cell.displayValue}}
        </a>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="cell.cellState.editable && cell.cellUIState.uiType">
    <ng-container
      [csTemplateOutlet]="getTemplate(cell.cellUIState.uiType)"
      [csTemplateOutletContext]="{cell: cell,row:row,group:group,sheet:sheet}">
    </ng-container>
  </ng-container>
</div>

<ng-template #spinner let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <cs-spinner [(ngModel)]="cell.value" [displayValue]="cell.displayValue"
              (spinnerInputChanged)="notifyCellInputChanged($event)"
              [notNegative]="notNegative"
              (spinnerChanged)="notifyUpdateRowValues($event)"></cs-spinner>
</ng-template>

<ng-template #checkbox let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <div>
    <label class="checkbox-container"
           (click)="notifyRowSelected()"
           [class.disabled]="row.disabled">
      <input class="form-control"
             type="checkbox"
             [(ngModel)]="row.selected"
             [attr.disabled]="row.disabled"
      > <span class="checkmark"></span>
    </label>
  </div>
</ng-template>

<ng-template #cellcheckbox let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <div class="btn-group" data-toggle="buttons">
    <label class="btn btn-checkbox btn-sm"
           (click)="cellCheckboxClicked($event,cell,row,group,sheet)"
           [ngClass]="{'active':cell.value,'disabled':cell.cellState.readonly}">
      <i class="icon icon-check"></i>
    </label>
  </div>
</ng-template>

<ng-template #select let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <select [(ngModel)]="cell.value"
          (change)="notifySelectionChanged($event)">
    <option *ngFor="let x of getLookup(cell.lookup,cell,row, group, sheet)"
            [disabled]="x.disabled"
            [ngValue]="x.id">{{ x[cell.lookupLabel] }}
    </option>
  </select>
</ng-template>

<ng-template #selectSearch let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <cs-advanced-dropdown [dataSource]="advancedDropdown"
                        isSmall="true"
                        closeOnClick="true"
                        detectSpaceEnough="true"
                        [selected]="cell.value"
                        (selectionChanged)="onAdvancedDropdownSelectionChanged($event)">
  </cs-advanced-dropdown>
</ng-template>

<ng-template #cellcheckboxlabel let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <div class="cellcheckboxlabel-wrapper"
       data-toggle="buttons">
    <label class="btn btn-checkbox btn-sm"
           (click)="cellCheckboxClicked($event,cell,row,group,sheet)"
           [ngClass]="{'active':cell.value,'disabled':cell.cellState.readonly, 'hidden':cell.cellState.hidden}">
      <i class="icon icon-check"></i>
    </label>
    <div class="checkbox-label ml-auto">
      <ng-container *ngIf="cell.behavior.action === 'None'">
        {{cell.metaValues.customValue}}
      </ng-container>
      <ng-container *ngIf="cell.behavior.action !== 'None'">
        <a class="cs-pointer">
          <i *ngIf="cell.behavior.params.iconClass"
             class="cell-icon {{cell.behavior.params.iconClass}}"></i>
          {{cell.metaValues.customValue}}
        </a>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #datepicker let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <cs-datepicker [(ngModel)]="cell.value"
                 [outputFormat]="'iddate'"
                 (ngModelChange)="notifySelectionChanged($event)"
                 [openOnFocus]="row.rowState === 'Default'? true:false"
  ></cs-datepicker>
</ng-template>

<ng-template #rowmenu let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <ng-container *ngIf="row.buttons.length > 1">
    <mat-menu #appMenu="matMenu" class="row-menu-container" xPosition="before">
      <ng-container *ngFor="let btn of row.buttons">
        <button mat-menu-item
                (click)="notifyRowButtonClicked(btn)"
                [disabled]="btn.disabled(row)">
          <mat-icon class="{{btn.iconClass}}"></mat-icon>
          <span>{{btn.label}}</span>
        </button>
      </ng-container>
    </mat-menu>
    <div class="row-menu-trigger"
         *ngIf="row.buttons.length > 0"
         mat-icon-button
         [matMenuTriggerFor]="appMenu">
      <i class="mdi mdi-dots-vertical"
      ></i>
    </div>
  </ng-container>
  <ng-container *ngIf="row.buttons.length === 1">
    <ng-container *ngFor="let btn of row.buttons">
      <div class="row-menu-trigger"
           (click)="notifyRowButtonClicked(btn)">
        <i class="{{btn.iconClass}}"
           matTooltip="{{btn.disabled(row)?  'Not allowed':btn.label}}"
           [class.text-muted]="btn.disabled(row)">
        </i>
      </div>
    </ng-container>
  </ng-container>

</ng-template>

<ng-template #datagrid let-cell="cell" let-row="row" let-group="group" let-sheet="sheet">
  <cs-data-grid [sheets]="row.expansion.sheets"
                [options]="row.expansion.options"
                [parentRow]="row.expansion.parentRow"
                (onCellsEdited)="nestedCellEditedHandler($event)"
                (onSheetActionClicked)="nestedSheetActionClickedHandler($event)"
                (onNavigationRequested)="nestedNavigationRequestHandler($event)"
                (requestingCellActionOnClick)="nestedRequestingCellActionOnClickHandler($event)"
                (onRowAdded)="nestedRowAddedHandler($event)"
                (onRowSelected)="nestedRowSelectedHandler($event)"
                (onRowButtonClicked)="nestedRowButtonClickedHandler($event)"
  ></cs-data-grid>
</ng-template>
