import { IConfigService }   from '@cs/performance-manager/shared';
import { Observable }       from 'rxjs';
import { Result }           from '@cs/core/generate';
import { AuditTrailAudits } from './components/audit-trail-audits/audit-trail-audits.model';

export abstract class AuditTrailConfigService implements IConfigService {

	moduleName = 'audit-trail';

	abstract getAuditTrailAudits(factTableName: string,
															 selection: { [p: string]: any }
	): Observable<Result<AuditTrailAudits>>;

	abstract getAuditTrailChanges(factTableName: string,
																selection: { [p: string]: any }
	): Observable<Result<any>>;

}
