import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output
}                                                        from '@angular/core';
import { DashboardDownloadButtonEventArgs }              from './models/dashboard-download-button-event-args';
import { DashboardGridColumn }                           from './models/dashboard-grid-column';
import { DashboardGridData }                             from './models/dashboard-grid-data';
import { DashboardGridLayout }                           from './models/dashboard-grid-layout';
import { DashboardGridRow }                              from './models/dashboard-grid-row';
import { DashboardPanel }                                from './models/dashboard-panel';
import { DashboardPanelInfoIcon }                        from './models/dashboard-panel-info-icon';
import { DashboardPanelSettingEventArgs }                from './models/dashboard-panel-setting-event-args';
import { DashboardPanelType }                            from './models/dashboard-panel-type';
import { IDashboardComponent }                           from './models/i-dashboard-component';
import { IDashboardPanel }                               from './models/i-dashboard-panel';
import { IDashboardPanelComponent }                      from './models/i-dashboard-panel-component';
import { NotifyServerForChangesDashboardPanelEventArgs } from './models/notify-server-for-changes-dashboard-panel-event-args';
import { PanelSettings }                                 from './models/panel-settings';

import {
	DashboardChartComponent,
	DashboardChartNxtComponent,
	DashboardCombiEntryStateComponent,
	DashboardEmptyComponent,
	DashboardGaugeComponent,
	DashboardGenericTableComponent,
	DashboardHtmlComponent,
	DashboardIndicatorsComponent,
	DashboardInformationComponent,
	DashboardSingleIndicatorsComponent,
	DashboardStatisticsComponent,
	DashboardTableComponent,
	HyperlinkListComponent,
	DashboardListGroupedItemsComponent,
	DashboardTasksComponent,
	DashboardUpdatesComponent,
	DashboardChartNxtSliderComponent,
	DashboardFormGeneratorComponent,
	DashboardViewerComponent
}                                                        from './components';
import {
	AllDashboardPanelType, DashboardPanelMetaInfoEnum
}                                                        from './models/dashboard-models';
import {
	ApplicationSelectionTargetResult, ApplicationSelectionTargetResultMeta,
	ArrayUtils,
	ComponentChanges,
	DataDescribed, generateQuickGuid,
	hasPropertyOf,
	LoggerUtil, mergeDeep,
	PropertyAnnotation, restoreFlattenObject,
	SelectionTargetResult,
	ServerSidePaging, updateTargetSources,
	whenChanging
}                                                        from '@cs/core';
import { UntilDestroy, untilDestroyed }                  from '@ngneat/until-destroy';
import { DashboardEventHub }                             from './dashboard-event-hub.service';
import { SafeMethods, FormatProviderService }            from '@cs/common';
import { isNullOrUndefined }                             from '@cs/core';
import { animate, state, style, transition, trigger }    from '@angular/animations';
import { FilterCompareBarQuery }                         from '@cs/components/filter-and-compare-bar';
import { DomSanitizer }                                  from '@angular/platform-browser';
import { ActivatedRoute }                                from '@angular/router';
import { addClass }                                      from '@cs/components/util';
import { IEmptyPanel }                                   from './models/i-empty-panel';
import { IHtmlPanel }                                    from './models/i-html-panel';


@UntilDestroy()
@Component({
	selector:        'cs-dashboard',
	templateUrl:     './dashboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers:       [DashboardEventHub],
	animations:      [trigger('loadingIconState', [
		state('clicked', style({
			filter:        'blur(3px)',
			opacity:       0.66,
			pointerEvents: 'none'
		})),
		state('done', style({
			filter:  'blur(0)',
			opacity: 1
		})),
		transition('clicked <=> done',
			animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),

		state('loader', style({
			opacity: 1
		})),

		transition('* => loader', [
			style({opacity: 0}),
			animate('400ms 100ms cubic-bezier(0.55, 0, 0.55, 0.2)')
		]),

		transition('loader => done', [
			style({opacity: 0}),
			animate('400ms 100ms cubic-bezier(0.55, 0, 0.55, 0.2)')
		])

	])]
})
export class DashboardComponent implements IDashboardComponent, OnInit, OnChanges, OnDestroy {

	/**
	 * The object that is used to renders the dashboard layout and the panels
	 */
	@Input() data: DashboardGridData;
	/**
	 * Set the context for the dashboard
	 */
	@Input() contextObject: { [key: string]: any };
	/**
	 * When a element in one of the panels has been clicked, it could fire a event {@link DashboardEventHub.isDashboardEntryIsClicked}
	 * so the dashboard parent could execute the requested action. {@link SelectionTargetResult.selectionAction}
	 */
	@Output() dashboardEntityClicked = new EventEmitter<SelectionTargetResult>();

	/**
	 * Application trigger requested by the server
	 */
	@Output() applicationTriggerRequested = new EventEmitter<ApplicationSelectionTargetResult>();

	/**
	 * A panel could have a download option. This will fire when a button in the header of the panel is clicked.
	 */
	@Output() downloadButtonClicked = new EventEmitter<DashboardDownloadButtonEventArgs>();

	/**
	 * A panel could have multiple views that could be selected by the PanelViewOption
	 */
	@Output() panelOptionSelected = new EventEmitter<DashboardPanelSettingEventArgs>();

	/**
	 * A panel has a the option to request a Modal window with extra information {@link DashboardPopupComponent}
	 */
	@Output() showDetailsButtonClicked = new EventEmitter<SelectionTargetResult>();

	/**
	 * A panel would like to send changes made by the user to the server
	 */
	@Output() notifyChangesToServer = new EventEmitter<NotifyServerForChangesDashboardPanelEventArgs<any>>();

	@Output() newDashboardRendered = new EventEmitter<{ firstRender: boolean }>();

	dashboardGrid: DashboardGridLayout;
	private dashboardPanels: IDashboardPanelComponent<any>[];
	readonly dashboardInstanceId = generateQuickGuid();


	constructor(@Inject(forwardRef(() => DashboardEventHub)) private dashboardEventHub: DashboardEventHub,
							private filterCompareBarQuery: FilterCompareBarQuery,
							private changeRef: ChangeDetectorRef,
							private route: ActivatedRoute,
							private sanitizer: DomSanitizer,
							private formatService: FormatProviderService) {
		this.dashboardEventHub.registerAsParent(this);
	}

	ngOnInit() {

		this.dashboardEventHub.isDashboardEntryIsClicked
				.pipe(untilDestroyed(this))
				.subscribe(value => {
					value.dashboardInstanceId = this.dashboardInstanceId;
					this.dashboardEntityClicked.emit(value);
				});

		this.dashboardEventHub.onNotifyChangesToServer
				.pipe(untilDestroyed(this))
				.subscribe(value => {
					this.notifyChangesToServer.emit(value);
				});
	}


	ngOnDestroy() {
	}

	detectChanges() {
		SafeMethods.detectChanges(this.changeRef);
	}

	private async parseDashboardGridData(newData: DashboardGridData) {
		this.dashboardPanels = [];

		const gridData = new DashboardGridData(newData);

		const gridDataLayout = new DashboardGridLayout();
		let colIndex         = 0;
		for (const row of gridData.grid) {
			const rowLayout = new DashboardGridRow();

			for (const col of row) {
				const colLayout   = new DashboardGridColumn();
				colLayout.colspan = col;
				colLayout.panels  = newData.panels
																	 .filter(p => p && p.gridSlot === colIndex)
																	 .map(value => {
																		 if (value.options != null) {
																			 value.options = new DataDescribed(value.options);
																		 }
																		 return new DashboardPanel(value);
																	 });
				colLayout.panels.forEach(p => {
					p.component = this.getComponentType(p);
					this.updatePanelWithDisplayHints(p);
					this.buildNoteHTML(p);
				});

				rowLayout.columns.push(colLayout);
				colIndex++;
			}

			gridDataLayout.grid.push(rowLayout);
		}
		gridDataLayout.alerts = gridData.alerts;
		return gridDataLayout;
	}

	private async patchDashboardGridData(newData: DashboardGridData) {

		const gridData = newData;

		const gridDataLayout = this.dashboardGrid;

		for (const item of gridData.panels.filter(value => value !== null)) {

			const panel: AllDashboardPanelType = item;

			const allPanels = gridDataLayout.grid.reduce((previousValue, currentValue) => {
				previousValue.push(...currentValue.columns.reduce((previousValue1, currentValue1) => {
					previousValue1.push(...currentValue1.panels);
					return previousValue1;
				}, [] as AllDashboardPanelType[]));
				return previousValue;
			}, [] as AllDashboardPanelType[]);

			// Check if panel is null and handle it, but trigger a error for the error logger
			if (isNullOrUndefined(panel)) {
				const indexOfNull = [];
				gridData.panels.filter((value, index) => {
					if (value === null) {
						indexOfNull.push(index);
						return true;
					}
				});
				LoggerUtil.error(`Panels at indexes [${indexOfNull.join(', ')}] are NULL`, true);
				continue;
			}

			const panelComponentToPatch = this.dashboardPanels.find(value => value.name === panel.name);

			const panelToPatch = allPanels.find(value => value.name === panel.name);

			panelToPatch.meta = panel.meta;

			if (panelToPatch.type !== panel.type) {
				const foundIndex = this.dashboardPanels.findIndex(value => value.name === panel.name);

				if (foundIndex === -1) {
					LoggerUtil.error(`${panel.name} is not found`);
					continue;
				}

				this.dashboardPanels.splice(foundIndex, 1);

				panelToPatch.type      = panel.type;
				panelToPatch.class     = panel.class;
				panelToPatch.label     = panel.label;
				panelToPatch.component = this.getComponentType(panelToPatch);
				panelToPatch.data      = panel.data;
				panelToPatch.options   = panel.options != null ? new DataDescribed(panel.options) : null;
				panelToPatch.reason    = panel.reason;
				panelToPatch.infoType  = panel.infoType;
				panelToPatch.note      = panel.note;
				this.updatePanelWithDisplayHints(panelToPatch);

				continue;
			}

			if (panelComponentToPatch.update) {
				panelToPatch.label    = panel.label;
				panelToPatch.note     = panel.note;
				panelToPatch.options  = panel.options != null ? new DataDescribed(panel.options) : null;
				panelToPatch.infoType = panel.infoType;
				panelToPatch.reason   = panel.reason;
				panelToPatch.label    = panel.label;

				// Resetting the styling
				this.getComponentType(panelToPatch);
				panelToPatch.class = addClass(panel.class, panelToPatch.class);

				this.buildNoteHTML(panelToPatch);
				panelComponentToPatch.update(panel.data);
			} else {
				panelToPatch.data = panel.data;
			}

			this.updatePanelWithDisplayHints(panelToPatch);

			console.log(panelComponentToPatch);
		}

		gridDataLayout.alerts = gridData.alerts;
		return gridDataLayout;
	}

	updatePanelWithDisplayHints(panelToPatch: IDashboardPanel) {

		if (!hasPropertyOf(panelToPatch, 'meta') || !hasPropertyOf(panelToPatch.meta, 'displayHints'))
			return;

		if (panelToPatch.meta.displayHints.indexOf(DashboardPanelMetaInfoEnum.LittleLarger) > -1)
			panelToPatch.class += ' little-larger ';

	}

	private getComponentType(panel: AllDashboardPanelType) {

		switch (panel.type.toLowerCase()) {
			case DashboardPanelType.LINKS:
				panel.class = 'quick_links';
				return HyperlinkListComponent;
			case DashboardPanelType.TABLE:
				panel.class = 'data_entry';
				return DashboardTableComponent;
			case DashboardPanelType.CHART_LEGACY:
				panel.class = 'chart_panel';
				return DashboardChartComponent;
			case DashboardPanelType.CHART:
				panel.class = 'chart_panel';
				return DashboardChartNxtComponent;
			case DashboardPanelType.CHART_SLIDER:
				panel.class = 'chart_slider_panel chart_panel';
				return DashboardChartNxtSliderComponent;
			case DashboardPanelType.HTML:
				panel.class = 'html_panel';
				return DashboardHtmlComponent;
			case DashboardPanelType.STATS:
				panel.class = 'stats_panel';
				return DashboardStatisticsComponent;
			case DashboardPanelType.COMBI_ENTRY_STATE:
				panel.class = 'combi_entry_state_panel';
				return DashboardCombiEntryStateComponent;
			case DashboardPanelType.GAUGE:
				panel.class = 'gauge_panel';
				return DashboardGaugeComponent;
			case DashboardPanelType.INDICATORS:
				panel.class = 'indicators_panel';
				return DashboardIndicatorsComponent;
			case  DashboardPanelType.SINGLE_INDICATORS:
				panel.class = 'single-indicators_panel';
				return DashboardSingleIndicatorsComponent;
			case DashboardPanelType.LIST_GROUPED_ITEMS:
				panel.class = 'list_grouped_items';
				return DashboardListGroupedItemsComponent;
			case DashboardPanelType.TABLE_NXT:
				panel.class = 'table_nxt_panel';
				return DashboardGenericTableComponent;
			case DashboardPanelType.EMPTY:
				panel.class = 'empty_panel';
				panel.class = addClass(panel.class, panel.infoType);
				return DashboardEmptyComponent;
			case DashboardPanelType.INFORMATION:
				panel.class = 'information_panel';
				panel.class = addClass(panel.class, panel.infoType);
				// panel.hasShadow = false;
				return DashboardInformationComponent;
			case DashboardPanelType.TASKS:
				panel.class = 'tasks_panel';
				return DashboardTasksComponent;
			case DashboardPanelType.UPDATES:
				panel.class = 'updates_panel';
				return DashboardUpdatesComponent;
			case DashboardPanelType.FORM_GENERATOR:
				panel.class = 'form_panel';
				return DashboardFormGeneratorComponent;
			case DashboardPanelType.VIEWER:
				panel.class = 'viewer_panel';
				return DashboardViewerComponent;
			default:
				panel.class = 'html_panel';
				panel.data  = {html: 'NOT_IMPLEMENTED_PANEL'};
				console.error(`Panel type: '${panel.type}' is not found`);
				return DashboardHtmlComponent;
		}
	}

	ngOnChanges(changes: ComponentChanges<DashboardComponent>): void {

		whenChanging(changes.data, true)
			.execute(value => {

				const isDifferent = this.shouldParseData(value.currentValue, value.previousValue);

				if (isDifferent)
					this.newDashboardRendered.emit({firstRender: this.dashboardGrid == null});
				// When patching it updates the current dashboard data instance, this will allow for smoother chart changes
				// When parsing the dashboard will be teared down and build up completely. Has a blurred transition
				const executeMethod = isDifferent
					? this.parseDashboardGridData(value.currentValue)
					: this.patchDashboardGridData(value.currentValue);

				executeMethod.then(grid => {
					this.dashboardGrid = grid;
					SafeMethods.detectChanges(this.changeRef);
				});
			});
	}

	public replaceSpaces(tooltip: string): string {
		return tooltip.replace(/<br[^>]*>/g, '\n');
	}

	getIcon(icon: DashboardPanelInfoIcon) {

		let output = '';

		switch (icon.icon.toLowerCase()) {
			case 'FilterWarning'.toLowerCase():
				output = 'cs-icon-18px cs-icon-filter-warning info-icon ';
				break;
			case 'CertaintyWarning'.toLowerCase():
				output = 'mdi mdi-18px mdi-alert-outline info-icon warning ';
				break;
			case 'Info'.toLowerCase():
				output = 'mdi mdi-18px mdi-information-outline info-icon ';
				break;
			case 'DownloadAsExcel'.toLowerCase():
				output = 'mdi mdi-18px mdi-download info-icon ';
				break;
			case 'ShowDetails'.toLowerCase():
				output = 'mdi mdi-18px mdi-magnify-plus-outline info-icon ';
				break;
			case 'AddNew'.toLowerCase():
				output = 'mdi mdi-18px mdi-plus-circle-outline info-icon ';
				break;
			case 'ExpandPanel'.toLowerCase():
				output = 'mdi mdi-18px mdi-arrow-expand-horizontal info-icon ';
				break;
		}
		return output + this.getIconType(icon.type);
	}


	getButtonIcon(iconBtn: DashboardPanelInfoIcon) {
		let output = '';

		switch (iconBtn.icon.toLowerCase()) {
			case 'FilterWarning'.toLowerCase():
				output = 'cs-icon-filter-warning hover-help ';
				break;
			case 'CertaintyWarning'.toLowerCase():
				output = 'mdi  mdi-alert-outline hover-help warning';
				break;
			case 'Info'.toLowerCase():
				output = 'mdi mdi-information-outline hover-help ';
				break;
			case 'DownloadAsExcel'.toLowerCase():
				output = 'mdi mdi-download hover-pointer ';
				break;
			case 'ShowDetails'.toLowerCase():
				output = 'mdi mdi-magnify-plus-outline hover-pointer ';
				break;
			case 'AddNew'.toLowerCase():
				output = 'mdi mdi-plus hover-pointer ';
				break;
		}
		return output;
	}

	getIconType(type: string) {
		switch (type) {
			case 'warning':
				return 'text-warning';
			case 'danger':
				return 'text-danger';
			case 'info':
				return 'text-info';
			case 'download':
				return 'download';
			case 'primary':
				return 'primary';
			default:
				return 'default';
		}
	}

	/**
	 * Return the class for the button type
	 * @param type the type of the button
	 */
	getButtonType(type: string) {
		switch (type) {
			case 'primary':
				return 'primary';
			default:
				return 'default';
		}
	}


	/**
	 * Method to check if the dasboard data is the similar to previous dashboard.
	 * @param currentValue New dashboard data to render
	 * @param previousValue Current dashboard data already rendered
	 * @return result if previous data is to different than the current data for patching
	 */
	private shouldParseData(currentValue: DashboardGridData, previousValue: DashboardGridData): boolean {
		return isNullOrUndefined(previousValue)
			|| currentValue.name !== previousValue.name
			|| !(ArrayUtils.isEqual(currentValue.grid, previousValue.grid) ?
				(currentValue.panels.length === previousValue.panels.length
					&& this.panelsHaveSameName(currentValue, previousValue)) : false);
	}

	trackComponent(component: IDashboardPanelComponent<any>, panel: IDashboardPanel) {
		// is depricated in favor for the DashboardOptions
		component.name = panel.name;
		this.dashboardPanels.push(component);
	}

	metaButtonClicked(icon: DashboardPanelInfoIcon, panel: IDashboardPanel) {
		const urlParams           = this.getPanelSettingsFromUrl();
		const mainbarResultParams = this.filterCompareBarQuery.getValue().mainbarResultParams;

		const eventArgs = {
			panelName:       panel.name,
			selectionObject: Object.assign({}, mainbarResultParams,
				{panelSettings: urlParams}) as unknown as { [key: string]: string | number }
		};

		if (!isNullOrUndefined(icon.action)) {
			switch (icon.action.toLowerCase()) {
				case 'download':
					// combine selection meta info from the button with the navbar selection
					Object.assign(eventArgs.selectionObject, icon.selectionMeta);

					this.downloadButtonClicked.emit({
						...eventArgs,
						icon: icon
					} as DashboardDownloadButtonEventArgs);
					break;
				case 'details':
					this.showDetailsButtonClicked.emit({
						...eventArgs,
						selectionRoute:      null,
						selectionAction:     'ModalWindow',
						dashboardInstanceId: this.dashboardInstanceId
					});
					break;
				case 'ipa':
					this.dashboardEntityClicked.emit({
						...eventArgs,
						...updateTargetSources({
							row:    icon.selectionMeta,
							column: icon as unknown as PropertyAnnotation<any>
						}, {
							dataAnnotation: {
								displayName: '',
								fields:      [icon as unknown as PropertyAnnotation<any>],
								groups:      []
							}
						} as any, panel.name),
						dashboardInstanceId: this.dashboardInstanceId
					});
					break;
				case 'application':
					this.applicationTriggerRequested.emit({
						...eventArgs,
						selectionRoute:      null,
						selectionAction:     'Application',
						dashboardInstanceId: this.dashboardInstanceId,
						selectionMeta:       icon.selectionMeta as ApplicationSelectionTargetResultMeta
					});
					break;
				default:
					LoggerUtil.error(`The action ${icon.action} is not found`);
					break;
			}
		}
	}

	setIconLoader(icon: DashboardPanelInfoIcon) {
		return setTimeout(() => {
			icon.loading = 'loader';
			this.detectChanges();
		}, 300);
	}

	buildNoteHTML(panel: IDashboardPanel) {
		if (!isNullOrUndefined(panel.note)) {
			panel.note = this.sanitizer.bypassSecurityTrustHtml(panel.note as string);
			if (panel.type.toLowerCase() === DashboardPanelType.CHART)
				panel.class += ' chart-note';
		} else {
			panel.note = null;
		}
	}

	panelOptionClicked(value: any, panel: IDashboardPanel, field: PropertyAnnotation<unknown>) {
		const params  = this.filterCompareBarQuery.getValue().mainbarResultParams as unknown as { [key: string]: string | number };
		const options = panel.options as DataDescribed<{ [key: string]: string }>;

		options.data[field.id] = value;

		ServerSidePaging.verifyPageIndex(options as unknown as DataDescribed<ServerSidePaging>);

		const panelSettings = {
			...panel.options.data as any
		} as unknown as PanelSettings;

		const urlParams = this.getPanelSettingsFromUrl();

		const selectedSettings = {
			[panel.name]: panelSettings
		};

		this.panelOptionSelected.emit({
				panelName:           panel.name,
				selectionObject:     params,
				selectionAction:     'CurrentWindow',
				selectionRoute:      null,
				dashboardInstanceId: this.dashboardInstanceId,
				panelSettings:       Object.assign({},
					urlParams, selectedSettings)
			}
		);
	}

	getLookup(panel: IDashboardPanel, lookup: string) {
		try {
			return panel.options.getLookup(lookup).values;
		} catch (e) {
			return [];
		}
	}

	getCurrentValue(panel: IDashboardPanel, optionList: PropertyAnnotation<unknown>) {

		let result;
		if (!optionList)
			return;

		if (optionList.lookup) {
			// @ts-ignore
			result = panel.options.resolveValueWithLookup(panel.options.data[optionList.id], optionList.lookup);
			if (result === null) {
				return '';
			}
		} else {
			result = (<any>panel.options).data[optionList.id];
		}

		result = this.formatService.formatByPropertyAnnotation(optionList, result);

		return result;
	}

	/**
	 * Detects if the header is truncated and if so add an tooltip
	 */
	detectTruncatedField($event: MouseEvent, panel: IDashboardPanel & IEmptyPanel & IHtmlPanel) {
		const element = $event.currentTarget as HTMLElement;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}


		panel.isTruncated = isEllipsisActive(element);


	}

	getPanelSettings(data: DashboardGridData): { [key: string]: any } {
		return mergeDeep(this.getPanelSettingsFromUrl(true), this.getPanelSettingsFromData(data));
	}

	public getPanelSettingsFromUrl(clearValues = false): { [key: string]: any } {

		const params   = this.route.snapshot.queryParams || {};
		const restored = restoreFlattenObject(params, clearValues);

		if (restored.hasOwnProperty('panelSettings') && restored.panelSettings != null) {
			return restored.panelSettings;
		}

		return {};
	}

	public getPanelSettingsFromData(data: DashboardGridData = null, clearValues = false): { [key: string]: any } {

		return data.panels.reduce((previousValue, currentValue) => {
			if (currentValue.options && currentValue.options.data)
				previousValue[currentValue.name] = clearValues ? null : currentValue.options.data;

			return previousValue;
		}, {});
	}

	/**
	 * Check if the panels in both collections have the same name
	 * @param currentValue the new dashboard data
	 * @param previousValue the current rendered dashboard data
	 */
	private panelsHaveSameName(currentValue: DashboardGridData, previousValue: DashboardGridData) {
		const currentPanelNames  = currentValue.panels.map(value => value.name);
		const previousPanelNames = previousValue.panels.map(value => value.name);

		return ArrayUtils.isEqual(currentPanelNames, previousPanelNames);
	}

	panelIsLoading(isLoading: boolean, panelName: string) {
		this.dashboardEventHub.panelIsLoading(isLoading, panelName);
	}

	/**
	 * Returns the row that contains the panel
	 * @param name the panel name that should be in the row
	 */
	getRow(panelName: string) {
		return this.dashboardGrid.getRow(panelName);
	}

}
