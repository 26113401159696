/**
 * Supported types of data for the DashboardPanels
 */
export enum DashboardPanelType {
	UPDATES            = 'updates',
	TASKS              = 'tasks',
	SINGLE_INDICATORS  = 'onetomanyindicators',
	INFORMATION        = 'information',
	GAUGE              = 'gauge',
	COMBI_ENTRY_STATE  = 'combi-entry-state',
	STATS              = 'stats',
	LINKS              = 'links',
	CHART              = 'chart',
	CHART_LEGACY       = 'chart-legacy',
	HTML               = 'html',
	TABLE              = 'table',
	INDICATORS         = 'indicators',
	TABLE_NXT          = 'table-nxt',
	LIST_GROUPED_ITEMS = 'list-grouped-items',
	EMPTY              = 'empty',
	CHART_SLIDER       = 'sliderchart',
	FORM_GENERATOR     = 'form-generator',
	VIEWER             = 'filepreview'
}
