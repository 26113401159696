import { NgModule }                                 from '@angular/core';
import { CommonModule }                             from '@angular/common';
import { MatMenuModule }                            from '@angular/material/menu';
import { MatTooltipModule }                         from '@angular/material/tooltip';
import { PmNavbarComponent }                        from './pm-navbar.component';
import { CsAdvancedDropdownModule, CsAvatarModule } from '@cs/components';
import { TranslateModule }                          from '@ngx-translate/core';
import { RouterModule }                             from '@angular/router';
import { CsCommonPipesModule }                           from '@cs/common';

const COMPONENTS = [
	PmNavbarComponent
];

@NgModule({
	declarations: [...COMPONENTS],
	imports:      [
		CommonModule,
		RouterModule,
		MatMenuModule,
		MatTooltipModule,
		CsAvatarModule,
		TranslateModule.forChild(),
		CsCommonPipesModule,
		CsAdvancedDropdownModule
	],
	exports:      [...COMPONENTS]
})
export class PmNavbarModule {
}
