import { isUndefined } from '@cs/core';

export class DataGridItemState {
  hidden;
  locked;
  readonly;
  invalid;
  editable;
  /**
   * Flag indicating a reason should be specified when changed
   */
  requireReason;

  constructor(initial?: Partial<DataGridItemState>) {
    if (initial === null)
      this.setDefaults();
    else if (!isUndefined(initial))
      Object.assign(this, initial);
  }

  setDefaults() {
    this.hidden   = false;
    this.locked   = false;
    this.readonly = true;
    this.invalid  = false;
    this.editable = false;
    this.requireReason = false;
  }
}
