<div>
  <form enctype="application/x-www-form-urlencoded"
        (keyup.enter)="resetPassword()">
    <div class="form-group email">
      <input class="form-control"
             type="text"
             name="username"
             autocomplete="off"
             placeholder="{{'ENTER_EMAIL_OF_LOST_ACCOUNT' | translate}}"
             (keyup)="checkErrorState()"
             [(ngModel)]="username"
             required="">
    </div>
    <div class="form-group">
      <div class="mb-1 g-recaptcha-container reset-password" [innerHtml]="captchaHtml">

      </div>
      <div class="loader-container d-flex flex-justify-content-center" *ngIf="isLoadingCaptcha">
        <div class="loader"></div>
      </div>
    </div>
    <div matTooltip="{{getErrorMessage()}}"
         matTooltipPosition="above"
         [matTooltipDisabled]="!isErrorState">
      <button
        [csLoader]="isWaitingForReset"
        [loaderAdditionalClasses]="['loader--white']"
        [disabled]="isErrorState"
        (click)="resetPassword()"
        class="btn btn--primary btn--lg">
        {{'RESET_PASSWORD' | translate}}
      </button>
    </div>
  </form>
  <p class="card-text login-footer-text">
    {{'IF_YOU_HAVE_PROBLEMS_PERSIST' | translate | capitalize}}
    <a href="mailto:{{loginConfig.supportEmail}}"
       class="link">{{loginConfig.supportEmail}}</a>
  </p>
</div>
