import { CsHttpRequestOptions, DataDescribed, Result } from '@cs/core';
import { IConfigService }                              from '@cs/performance-manager/shared';
import { AccountInfo }                                 from './models/accountInfo';
import { ActivatedRoute }                              from '@angular/router';
import { Observable }                                  from 'rxjs';
import { LoginModel }                                  from './models/accountInfo';

export abstract class LoginConfigService implements IConfigService {

	moduleName = 'login';
	/**
	 * Provide the customer support email. This email address is where the user can send there issues
	 */
	abstract supportEmail: string;

	/**
	 * The title of the login page
	 */
	loginWelcomeMessage = 'IMPLEMENT WELCOME MESSAGE';

	/**
	 * Provides the route to the recover password pages
	 */
	RECOVER_URL: Array<string> = null;

	/**
	 * Login the user for accessing the Performance Manager
	 * @param username the username of the user
	 * @param password the corresponding password
	 * @param errorHandler handle the failures ahead of the global http error handler
	 */
	abstract login(username: string, password: string, errorHandler?: CsHttpRequestOptions);

	/**log
	 * Callback when login was successful
	 * @param account the current account
	 */
	abstract loginSuccessHandler(account: Result<AccountInfo>, route: ActivatedRoute);
}

export abstract class DataDescribedLoginService extends LoginConfigService {
	/**
	 * Milliseconds for timeout external login attempt
	 */
	EXTERNAL_LOGIN_TIMEOUT: number = 10 * 1000;

	/**
	 * External Login url
	 */
	EXTERNAL_LOGIN_URL: string[] = ['login', 'external'];

	/**
	 * Recover entry Url, This is the url for forgot password
	 */
	RECOVER_URL = ['recover'];

	/**
	 * gets the login form in DataDescribed format
	 */
	abstract getLoginForm(): Observable<Result<DataDescribed<LoginModel[]>>>;

	/**
	 * Login the user for accessing the Performance Manager
	 * @param username the username of the user
	 * @param password the corresponding password
	 * @param errorHandler handle the failures ahead of the global http error handler
	 */
	abstract loginWithProvider(loginModel: LoginModel, errorHandler?: CsHttpRequestOptions);
}

