import {
	Component, OnInit, OnDestroy, ChangeDetectorRef,
	ChangeDetectionStrategy,
	AfterViewInit
}                                                                      from '@angular/core';
import { PropertyAnnotation, TableDataDescribed, updateTargetSources } from '@cs/core';
import { Subscription }                                                from 'rxjs';
import { ApprovalConfigService }                   from '../approval-config.service';
import { isNullOrUndefined }                       from '@cs/core';
import {
	FilterCompareBarQuery, FilterCompareBarStore,
	FilterCompareBarService,
	FilterBarResultParamsSelection
}                                                  from '@cs/components';
import { ApprovalResultParams }                    from '../models/approval-data-described';
import { SafeMethods, opacityAndBlur }             from '@cs/common';
import { filter as filter$ }                       from 'rxjs/operators';
import { UntilDestroy, untilDestroyed }            from '@ngneat/until-destroy';
import { ApprovalOverviewDataDescribed }           from '../models/approval-overview-data-described';
import { AppNavigationService }                    from '@cs/common';
import { FilterAndNavbarShellConfigService }       from '@cs/performance-manager/filter-and-navbar-shell';


@UntilDestroy()
@Component({
	selector:        'pmc-approval-overview',
	templateUrl:     './approval-overview.component.html',
	styleUrls:       ['./approval-overview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations:      [
		opacityAndBlur('loadingPanelState')
	]
})
export class ApprovalOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

	rows = [];
	/**
	 * the columns with data
	 */
	columns: PropertyAnnotation<any>[];

	/**
	 * Used for ordering the columns
	 */
	columnsList: Array<string> = [];
	private navbarChangeSubscription: Subscription;

	isLoadingDataGrids = true;

	dataDescribe: ApprovalOverviewDataDescribed;
	private bodyClasses: Array<string> = ['bg-white'];

	constructor(private config: ApprovalConfigService,
							readonly filterCompareBarQuery: FilterCompareBarQuery,
							readonly filterCompareBarService: FilterCompareBarService,
							private readonly appNavigationService: AppNavigationService,
							private readonly filterAndNavbarShellConfigService: FilterAndNavbarShellConfigService,
							public readonly changeRef: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.filterAndNavbarShellConfigService.setBodyClasses(this.bodyClasses);

		this.navbarChangeSubscription = this.filterCompareBarService.filterbarNavigationRequested.subscribe(value => {
			const {dataEntryRoundScopeName, pageName, selection} = value as ApprovalResultParams;
			if (dataEntryRoundScopeName && pageName.toLowerCase() === 'accept'
				&& this.isStationOverAll(selection))
				this.getData(dataEntryRoundScopeName, selection);
		});

	}

	ngAfterViewInit(): void {
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
				.pipe(
					untilDestroyed(this),
					filter$(value => !isNullOrUndefined(value))
				)
				.subscribe((value: ApprovalResultParams) => {
					const {dataEntryRoundScopeName, pageName, selection} = value;
					if (dataEntryRoundScopeName && pageName.toLowerCase() === 'accept'
						&& this.isStationOverAll(selection))
						this.getData(dataEntryRoundScopeName, selection);
				});

	}

	onRowClicked($event: any) {
		console.log($event);

		if (!this.appNavigationService.canNavigate())
			return;

		// Get the SelectionValue from the Server to navigate to the Station ({LocationStation: navIdBusinessUnit})
		const targetLocation = updateTargetSources({row: $event, column: null}, this.dataDescribe, null);
		const currentNavbar  = {...this.filterCompareBarQuery.getValue().mainbarApiParams};
		const updatedNavbar  = Object.assign(currentNavbar, targetLocation.selectionObject);
		this.filterCompareBarService.triggerNavigation(updatedNavbar);

	}

	private getData(scopeName: string, selection: { [key: string]: any }) {
		this.config.getApprovalOverviewData(scopeName, selection,
			this.filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams)
				.subscribe(value => {
					this.dataDescribe       = value.value;
					this.rows               = value.value.data;
					this.columns            = value.value.dataAnnotation.fields.filter(value1 => {

						if (!value1.visible)
							return false;

						const cell = (value.value as TableDataDescribed<any>).layout.table.headers.find(value2 => value1.id === value2.id);
						return cell ? cell.include : true;
					});
					this.columnsList        = this.columns.map(value1 => value1.id) as Array<string>;
					this.isLoadingDataGrids = false;
					SafeMethods.detectChanges(this.changeRef);
				});
	}

	ngOnDestroy(): void {
		if (!isNullOrUndefined(this.navbarChangeSubscription))
			this.navbarChangeSubscription.unsubscribe();

		this.filterAndNavbarShellConfigService.cleanBodyClasses(this.bodyClasses);
	}

	isStationOverAll(selection: FilterBarResultParamsSelection): boolean {
		for (const key of Object.keys(selection)) {
			if (!isNullOrUndefined(selection[key].id) && selection[key].id.toString().toLowerCase() === 'overall')
				return true;
		}
		return false;
	}
}

