/**
 * Describes the layout the form should render
 */
import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';
import { FormLayoutAnnotation }         from './form-layout-annotation.model';
import { WidgetAnnotation }             from './widget-annotation.model';
import { WidgetCollection }             from './widget-collection.model';
import { DataDescribedGroup }           from '../data-described/data-described-group.model';
import { GenericAction }                from '../data-described/actions/generic-action.model';


export class FormAnnotation<T> {
	constructor(form: Partial<FormAnnotation<T>>) {
		this.id     = getPropertyOf(form, 'id', null);
		this.label  = getPropertyOf(form, 'label', null);
		this.layout = getPropertyOf(form, 'layout');


		this.fieldSets = hasPropertyOf(form, 'fieldSets') ?
			form.fieldSets.map(fieldset => new DataDescribedGroup(fieldset)) : [];

		this.widgets = hasPropertyOf(form, 'widgets') ?
			form.widgets.map(widget => new WidgetAnnotation(widget)) : [];

		this.widgetCollections = hasPropertyOf(form, 'widgetCollections') ?
			form.widgetCollections.map(widget => new WidgetCollection(widget)) : [];
	}

	/**
	 * Identification of the form
	 */
	id: string;
	/**
	 * Name to show in the UI
	 */
	label?: string;
	/**
	 * Settings for the Form
	 */
	layout?: FormLayoutAnnotation;
	/**
	 * Group of Formfields could be used to visually group items in one form
	 */
	fieldSets?: DataDescribedGroup[];
	/**
	 * Group of widgets that should render as a group, like name = forename + lastname
	 */
	widgetCollections?: WidgetCollection[];

	widgets?: WidgetAnnotation<T>[];

	actions?: GenericAction[];

}
