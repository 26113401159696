import {
	ChangeDetectionStrategy, ChangeDetectorRef,
	Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild
}                                        from '@angular/core';
import {
	CsFormGeneratorComponent, CsFormGeneratorDataSource,
	FilterCompareBarQuery, get_browser_info, Logger
}                                        from '@cs/components';
import { Report }                        from './models/report';
import { Thumbnail }                     from './models/thumbnail';
import { ReportingConfigService }        from './reporting-config.service';
import {
	ActivatedRoute,
	Router
}                                        from '@angular/router';
import { isNullOrUndefined }             from '@cs/core';
import { BehaviorSubject, Observable }   from 'rxjs';
import { delay, filter as filter$, map } from 'rxjs/operators';
import {
	CsHttpRequestOptions, FileUtils,
	getPropertyOf, Result
}                                        from '@cs/core';
import {
	animate,
	state,
	style,
	transition,
	trigger
}                                        from '@angular/animations';
import { ReportFilterSelection }         from './models/form-definition-selection';
import { HttpErrorResponse }             from '@angular/common/http';
import {
	DomSanitizer,
	SafeHtml
}                                        from '@angular/platform-browser';
import { CsApplicationSettings }         from '@cs/performance-manager/shared';
import { ToastService }                  from '@cs/performance-manager/shared';
import { UntilDestroy, untilDestroyed }  from '@ngneat/until-destroy';
import { ImageSliderComponent }          from '@cs/components';
import { MatDialog }                     from '@angular/material/dialog';
import { TranslateService }              from '@ngx-translate/core';
import { SafeMethods }                   from '@cs/common';


@UntilDestroy()
@Component({
	selector:        'pmc-reporting',
	templateUrl:     './reporting.component.html',
	styleUrls:       ['./reporting.component.scss'],
	animations:      [
		trigger('isLoading', [
			state('inProgress',
				style({
					filter:        'blur(3px)',
					opacity:       0,
					pointerEvents: 'none'
				})
			),
			state('done',
				style({
					filter:        'blur(0px)',
					opacity:       1,
					pointerEvents: 'all'
				})
			),
			// When the element goes from 'selected' state to whatever...
			transition('inProgress <=> done', [
				animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)')
			])
		]),
		trigger('isLoadingReport', [
			state('inProgress',
				style({
					opacity: 1
				})
			),
			state('done',
				style({
					opacity: 0
				})
			),
			// When the element goes from 'selected' state to whatever...
			transition('inProgress <=> done', [
				animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)')
			])
		]),
		trigger('showReport', [
			state('show',
				style({
					display: 'flex'
				})
			),
			state('hide',
				style({
					display: 'none'
				})
			),
			// When the element goes from 'selected' state to whatever...
			transition('show => hide', [
				style({
					opacity:   1,
					transform: 'translate3d(0,0,0)'
				}),
				animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity:   0,
						transform: 'translate3d(0,-50px,0)'
					}))
			]),
			transition('hide => show', [
				style({
					opacity:   0,
					transform: 'translate3d(0,-50px,0)'
				}),
				animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity:   1,
						transform: 'translate3d(0,0,0)'
					}))
			])
		]),
		trigger('hideFilter', [
			state('show',
				style({
					display: 'flex'
				})
			),
			state('hide',
				style({
					display: 'none'
				})
			),
			// When the element goes from 'selected' state to whatever...
			transition('show => hide', [
				style({
					opacity:   1,
					transform: 'translate3d(0,0,0)'
				}),
				animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity:   0,
						transform: 'translate3d(0,-50px,0)'
					}))
			]),
			transition('hide => show', [
				style({
					opacity:   0,
					transform: 'translate3d(0,-50px,0)'
				}),
				animate('0.2s 0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity:   1,
						transform: 'translate3d(0,0,0)'
					}))
			])
		]),
		trigger('showCharts', [
			state('show',
				style({
					display: 'block'
				})
			),
			state('hide',
				style({
					display: 'none'
				})
			),
			// When the element goes from 'selected' state to whatever...
			transition('show => hide', [
				style({
					opacity:   1,
					transform: 'translate3d(0,0,0)'
				}),
				animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity:   0,
						transform: 'translate3d(0,-50px,0)'
					}))
			]),
			transition('hide => show', [
				style({
					opacity:   0,
					transform: 'translate3d(0,-50px,0)'
				}),
				animate('0.2s 0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity:   1,
						transform: 'translate3d(0,0,0)'
					}))
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingComponent implements OnInit, OnDestroy {
	/**
	 * Instance to the form-generator
	 */
	@ViewChild(CsFormGeneratorComponent) formGenerator: CsFormGeneratorComponent;

	@ViewChild('reportHtmlContainer') reportHTMLContainer: ElementRef;

	/**
	 * Flag for loading animation purposes.
	 * Turns on the loading indicator, and fade-in and out the content
	 */
	isLoadingCategoryItem = new BehaviorSubject('inProgress');
	/**
	 * Flag for animating the progress-button
	 */
	isLoadingContent: 'pdf' | 'excel' | 'report';
	/**
	 * The current selected Report, this contains all filters
	 * and all the info for rendering the report
	 */
	report: Report;
	/**
	 * The data needed for rendering the current selected report filter
	 */
	selectedReportFormDefinition: CsFormGeneratorDataSource;
	/**
	 * Flag indicating if report allows Excel export
	 */
	hasExcelExport: boolean;
	/**
	 * Flag indicating if report allows Pdf export
	 */
	hasPdfExport: boolean;
	/**
	 * Flag indicating if report allows Html export
	 */
	hasHtmlExport: boolean;

	/**
	 * string of selected filter items
	 */
	selectedFilter: string;
	/**
	 * Html to show as report
	 */
	reportHtml: SafeHtml;

	showNestedHtmlContainer: boolean;
	showFullScreenContainer: boolean;
	reportContainerAnimationState: 'show' | 'hide' = 'show';
	reportRenderedAnimationState: 'show' | 'hide'  = 'hide';
	reportFilterAnimationState: 'show' | 'hide'    = 'show';

	progressDescription: 'MESSAGE_LOADING_REPORT'         = 'MESSAGE_LOADING_REPORT'; // Placeholder text if not report has been select yet (e.g. empty navfilter bar)
	progressValue: BehaviorSubject<'10%' | '75%' | '50%'> = new BehaviorSubject('10%');

	private devmode = false;
	selectedCategoryId: number;
	selectedReportCategoryItemId: number;

	get isDisabled() {
		return get_browser_info().name === 'IE';
	}

	constructor(private reportingConfig: ReportingConfigService,
							private csAppSettings: CsApplicationSettings,
							private filterCompareBarQuery: FilterCompareBarQuery,
							private router: Router,
							private ngZone: NgZone,
							private toasts: ToastService,
							private changeRef: ChangeDetectorRef,
							private activeRoute: ActivatedRoute,
							private renderer: Renderer2,
							private dialog: MatDialog,
							private i8n: TranslateService,
							private sanitizer: DomSanitizer) {


	}

	ngOnInit() {
		this.devmode = !isNullOrUndefined(this.activeRoute.snapshot.queryParamMap.get('reportingDevmode'));

		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
				.pipe(
					untilDestroyed(this),
					//   debounceTime(300), // A little workaround for firing twice when switching form home
					filter$(value => !isNullOrUndefined(value))
				)
				.subscribe((value) => {
					this.setupComponent(value);

				});

		// expose function to the world
		window['loadReportingScript'] = this.loadReportingScript;

		window['pmMessageBus'] = {
			emit: (message: string, payload: { reportKeys: { categoryId: number, reportId: number } }) => {
				console.log(message, payload);

				switch (message) {
					case 'goToReport':
						this.goToReport(payload.reportKeys.categoryId, payload.reportKeys.reportId);
						break;
				}
			}
		};


		// Hard-coded keyboard short cut to refresh the report with current filter selection
		// Useful when creating/debugging reports
		this.renderer.listen('window', 'keydown', (event: KeyboardEvent) => {
			if (event.altKey && event.code === 'KeyR') {
				console.log('Update report');
				this.onUpdateReport();
			}
		});


	}

	/**
	 * Go to report based on categoryId and ReportId
	 */
	private goToReport(categoryId: number, reportId: number) {

		// Remove the current Html
		this.reportHtml = null;
		this.detectChanges();
		this.cleanupReport();

		// Select the reports
		this.onSelectCategory(categoryId, reportId);
		this.detectChanges();
	}

	/**
	 * This will select the category and will select the first item from the categoryitems,
	 * after the first selection this will update UI with selected report
	 */
	onSelectCategory(selectedCategoryId: number, selectedReportCategoryItemId: number) {
		Logger.ThrowError('DEPRECATED - this is now handled by the navfilterbar');
		this.isLoadingCategoryItem.next('inProgress');
		this.getReport(selectedCategoryId, selectedReportCategoryItemId)
				.subscribe(report => this.setReport(report));
	}

	/**
	 * After the report filter has changed, ask the server for updated
	 * formDefintions based on the current selection.
	 * For example: selecting a Continent will update the country field with valid options
	 * @param data  selected values from form Report Filter
	 */
	onSelectionChange(data: Array<string>) {
		// this.updateFilterSelection();
		if (isNullOrUndefined(this.report.formDefinition.lookups)) {
			return false;
		}
		// find lookup dependencies we need to change
		const lookupDependencies = this.report.formDefinition.lookups.filter((lookup) => {
			if (isNullOrUndefined(lookup.dependsOn)) {
				return false;
			}
			return lookup.dependsOn.some((lookupitem) => {
				return data.some((dataitem) => dataitem === lookupitem);
			});
		});

		if (lookupDependencies.length) {
			const formDefinitionSelection: ReportFilterSelection = {
				tabname:   this.report.tabs.length ? this.report.tabs[0].name : '',
				selection: this.formGenerator.formGroup.value
			};
			this.reportingConfig.getReportFormDefinitions(
						this.selectedCategoryId,
						this.selectedReportCategoryItemId,
						formDefinitionSelection)
					.subscribe((x) => {
						this.selectedReportFormDefinition = x.value as CsFormGeneratorDataSource;
						this.detectChanges();
					});
		}
	}

	showLargerImageDialog(thumbnails: Array<Thumbnail>) {
		this.dialog.open(ImageSliderComponent, {
			height:     '60%', width: '60%',
			panelClass: ['mat-dialog-rounded-with-close-btn-header'],
			data:       {
				thumbnails: thumbnails,
				rootUrl:    window.location.origin
			}
		});
	}

	onUpdateReport(overrideSelection: any = null) {
		if (this.report) {
			this.updateFilterSelection();
			const formDefinitionSelection = {
				tabname:   this.report.tabs.length ? this.report.tabs[0].name : '',
				selection: isNullOrUndefined(overrideSelection) ? this.formGenerator.formGroup.value : overrideSelection
			};

			// collapse report filter
			this.isLoadingContent           = 'report';
			this.reportFilterAnimationState = 'hide';

			this.cleanupReport();
			// TODO: cancel current request before getting a new one (switchMap?)
			// currently typed input is not supported in the API. Convert complex objects to JSON

			// Handle documented response codes (in reusable method)
			const errorHandler: CsHttpRequestOptions = new CsHttpRequestOptions();
			errorHandler.errorResponseHandler        = (error): boolean => this.handleGetReportFailure(error);


			this.reportingConfig.getHtmlReport(
				this.selectedCategoryId,
				this.selectedReportCategoryItemId,
				formDefinitionSelection,
				errorHandler
			).subscribe((result) => {

				this.isLoadingContent = null;
				const html            = result.value;

				// Setup the correct placeholder
				if (this.report.actions.htmlFullscreen || this.report.actions.html) {
					this.showNestedHtmlContainer = true;
				}


				if (!isNullOrUndefined(html)) {
					//if (false) {

					this.reportHtml                   = this.sanitizer.bypassSecurityTrustHtml(html);
					this.reportRenderedAnimationState = 'show';

					// hack: execute scripts after they have been loaded into the innerHTML --jv
					this.ngZone.runOutsideAngular(() =>
						setTimeout(() => {
							let scripts;
							this.detectChanges();
							if (this.report.actions.html || this.report.actions.htmlFullscreen) {
								scripts = this.reportHTMLContainer.nativeElement.getElementsByTagName('script');
							}
							const addHeadScript = function (src) {
								const head   = document.getElementsByTagName('head')[0];
								const script = document.createElement('script');
								script.type  = 'text/javascript';
								script.src   = src;
								head.appendChild(script);
							};

							// TODO: change deep extend to loading by server
							this.addDeepExtend();

							// concat all script tags
							const allscripts = [];
							for (const script of scripts) {
								if (script.src) {
									const cleanUri = script.src.replace(script.baseURI, window.location.origin + '/');
									addHeadScript(cleanUri);
								} else {
									allscripts.push(script.text);
								}
							}

							// Call eval() on all script script tags in one go,
							// most reports rely on sharing classes/variables between script-tags
							try {
								/*tslint:disable-next-line*/
								eval(allscripts.join('\n'));

							} catch (e) {
								if (e instanceof SyntaxError) {
									// Output the relevant script source
									// CF server returns scripts as single line. Make sure function definitions are ended by a semi-colon (;).
									console.debug(allscripts[(e as any).lineNumber - 1]);
								}
								throw(e);
							}
						})
					);
				} else {
					this.isLoadingContent = null;
					this.reportHtml = `<div class="badge badge--info">${this.i8n.instant('MESSAGE_NO_DATA_AVAILABLE')}</div>`;
					this.detectChanges();
				}
			});
		}
	}

	/**
	 * Load script defined by report and run the callback(s).
	 * @param src
	 * @param onloadCallback
	 * @param onerrorCallback
	 */
	loadReportingScript(src, onloadCallback, onerrorCallback) {
		var js     = document.createElement('script');
		js.src     = src;
		js.onload  = function () {
			if (!isNullOrUndefined(onloadCallback))
				onloadCallback();
		};
		js.onerror = function () {
			if (isNullOrUndefined(onerrorCallback) || !onerrorCallback())
				// throw when error is not handled
				throw(new Error('Failed to load script ' + src));
		};

		document.head.appendChild(js);
	}

	/**
	 * Download PDF file
	 */
	onExportPDF() {
		const formDefinitionSelection = {
			tabname:   this.report.tabs.length ? this.report.tabs[0].name : '',
			selection: this.formGenerator.formGroup.value
		};
		this.isLoadingContent         = 'pdf';
		this.cleanupReport();
		// Request BLOB & Handle documented response codes (in reusable method)
		const options: CsHttpRequestOptions = new CsHttpRequestOptions();
		options.errorResponseHandler        = (error) => this.handleGetReportFailure(error);

		this.reportingConfig.getPdfReport(
			this.selectedCategoryId,
			this.selectedReportCategoryItemId,
			formDefinitionSelection,
			options
		).subscribe(
			(response) => {
				this.isLoadingContent = null;
				if (!isNullOrUndefined(response)) {
					FileUtils.downloadFile(response.value);
					this.detectChanges();
				}
			});
	}

	/**
	 * Download excel file
	 */
	onExportExcel() {
		const formDefinitionSelection: ReportFilterSelection = {
			tabname:   this.report.tabs.length ? this.report.tabs[0].name : '',
			selection: this.formGenerator.formGroup.value
		};
		this.isLoadingContent                                = 'excel';
		this.cleanupReport();
		// Request BLOB & Handle documented response codes (in reusable method)
		const options: CsHttpRequestOptions = new CsHttpRequestOptions();
		options.errorResponseHandler        = (error) => this.handleGetReportFailure(error);


		this.reportingConfig.getExcelReport(
			this.selectedCategoryId,
			this.selectedReportCategoryItemId,
			formDefinitionSelection,
			options
		).subscribe((response) => {
			this.isLoadingContent = null;
			if (!isNullOrUndefined(response)) {
				FileUtils.downloadFile(response.value);
				this.detectChanges();
			} else {
				this.toasts.warning(this.i8n.instant('NO_REPORT_DATA'), this.i8n.instant('COULD_NOT_CREATE_AN_EXPORT'));
			}
		});
	}

	/**
	 * Resolve the image url to the base url
	 */
	getFullUrl(url: string) {
		return window.location.origin + url;
	}

	/**
	 * Get the report from the server and update the url to match the selection
	 * @param categoryId the id of the selected category
	 * @param reportId the id of the selected report
	 */
	getReport(categoryId: number, reportId: number): Observable<Report> {
		return this.reportingConfig.getReportInfo(categoryId, reportId).pipe(map(value => value.value));
	}

	ngOnDestroy(): void {
		this.cleanupReport();
	}

	updateFilterSelection() {
		const _selectedFilter = [];
		const values          = this.formGenerator.getCurrentDisplayValues();
		for (const key of Object.keys(values)) {
			_selectedFilter.push(values[key]);
		}
		this.selectedFilter = _selectedFilter.join(', ');
	}

	/**
	 * Show filter and delete the report
	 */
	showFilter() {
		this.reportContainerAnimationState = 'hide';
		this.cleanupReport();

	}

	closeFullScreen() {
		this.reportContainerAnimationState = 'hide';
		this.showFullScreenContainer       = false;
	}

	reportContainerAnimationDone($event) {
		this.reportRenderedAnimationState = this.reportContainerAnimationState === 'show' ? 'show' : 'hide';

		if (this.reportContainerAnimationState === 'hide') {
			this.cleanupReport();
		}
	}

	filterAnimationDone($event) {
		this.reportContainerAnimationState = this.reportFilterAnimationState === 'hide' ? 'show' : 'hide';
	}

	filterSettingsAnimationDone($event) {
		if (this.reportContainerAnimationState === 'hide')
			this.reportFilterAnimationState = 'show';
	}

	/**
	 * A safer method for calling change detection.
	 * It checks if the changeRef is not destroyed
	 */
	private detectChanges() {
		if (!this.changeRef['destroyed'])
			this.changeRef.detectChanges();
	}

	private setReport(report: Report) {
		this.showFilter();

		this.hasExcelExport               = getPropertyOf(report.actions, 'exportExcel', false);
		this.hasPdfExport                 = getPropertyOf(report.actions, 'exportPDF', false);
		this.hasHtmlExport                = getPropertyOf(report.actions, 'html', null) || getPropertyOf(report.actions, 'htmlFullscreen', false);
		this.progressValue.next('75%');
		this.report                       = report;
		this.selectedReportFormDefinition = report.formDefinition as CsFormGeneratorDataSource;
		this.isLoadingCategoryItem.next('done');

		this.detectChanges();
		this.progressValue.next('10%');
	}

	private setupComponent(params: { [key: string]: any }) {

		this.isLoadingCategoryItem.next('inProgress');
		this.progressDescription = 'MESSAGE_LOADING_REPORT';
		this.progressValue.next('10%');

		SafeMethods.detectChanges(this.changeRef);
		this.selectedCategoryId           = params.selectedCategory;
		this.selectedReportCategoryItemId = params.selectedReportCategoryItem;

		// Early out if parameters are not set (yet) by the filterbar
		if (params.selectedReportCategoryItem === null || params.selectedReportCategoryItem === undefined)
			return;

		this.progressDescription = 'MESSAGE_LOADING_REPORT';
		this.progressValue.next('50%');
		// Get report filter/form details

		setTimeout(() => {

			this.getReport(this.selectedCategoryId, this.selectedReportCategoryItemId)
					.subscribe(report => {
						this.setReport(report);
					});
		}, 100);

	}

	private cleanupReport(): any {
		// Clean html report
		if ((<any>window).destroyReport) {
			(<any>window).destroyReport();
		}

		this.reportHtml              = null;
		this.showNestedHtmlContainer = false;

	}

	private handleGetReportFailure(error: HttpErrorResponse): boolean {

		this.isLoadingContent = null;
		this.reportHtml       = this.i8n.instant('MESSAGE_ERROR_LOADING_REPORT');
		this.detectChanges();

		if (!error) {
			return false;
		}

		switch (error.status) {
			case 204:
				this.isLoadingContent = null;
				this.reportHtml       = this.i8n.instant('MESSAGE_EMPTY_REPORT') + ' ' + this.i8n.instant('MESSAGE_NOT_DOWNLOAD_REPORT');
				this.detectChanges();
				return true;
			case 400:
				// Handle documented 400 error by displaying toast
				// todo: this should not be possible: log as client-side error?
				this.toasts.info(this.i8n.instant('MESSAGE_INVALID_SELECTION'), error.message);
				return true;
			case 404:
				// Handle documented 404 error by displaying toast
				this.toasts.info(this.i8n.instant('MESSAGE_NO_REPORT_FOR_THIS_SELECTION'), this.i8n.instant('NO_REPORT_DATA'));
				return true;
			default:
				// Try to render everything the server returns.
				if (this.devmode) {
					let html = '';
					try {
						html = error.error;
					} catch (e) {
						if (this.devmode) {
							html = error.message;
						}
					}
					this.reportHtml = this.sanitizer.bypassSecurityTrustHtml(html);
					this.detectChanges();
					return true;
				}
				return false; // Response is deemed as UNhandled.
		}
	}

	private updateWindowOverflow(val: boolean) {
		// Because of the overlay fix turn off html scrollbar
		const htmlTag                  = document.getElementsByTagName('html');
		htmlTag.item(0).style.overflow = val ? 'hidden' : 'auto';
	}

	/**
	 * HACK FOR ADDING DEEP EXTEND FUNCTION
	 */
	private addDeepExtend() {
		/*tslint:disable-next-line*/
		eval(`(function () {
        var arrays, basicObjects, deepClone, deepExtend, deepExtendStrict, deepExtendCouple, isBasicObject,
            __slice = [].slice;

        deepClone = function (obj) {
            var func, isArr;
            if (!_.isObject(obj) || _.isFunction(obj)) {
                return obj;
            }
            if (_.isDate(obj)) {
                return new Date(obj.getTime());
            }
            if (_.isRegExp(obj)) {
                return new RegExp(obj.source, obj.toString().replace(/.*\\//, ""));
            }
            isArr = _.isArray(obj || _.isArguments(obj));
            func = function (memo, value, key) {
                if (isArr) {
                    memo.push(deepClone(value));
                } else {
                    memo[key] = deepClone(value);
                }
                return memo;
            };
            return _.reduce(obj, func, isArr ? [] : {});
        };

        isBasicObject = function (object) {
            return (object.prototype === {}.prototype || object.prototype === Object.prototype) && _.isObject(object) && !_.isArray(object)
                && !_.isFunction(object) && !_.isDate(object) && !_.isRegExp(object) && !_.isArguments(object);
        };

        basicObjects = function (object) {
            return _.filter(_.keys(object), function (key) {
                return isBasicObject(object[key]);
            });
        };

        arrays = function (object) {
            return _.filter(_.keys(object), function (key) {
                return _.isArray(object[key]);
            });
        };

        deepExtendCouple = function (destination, source, combineArrays, maxDepth) {
            var combine, recurse, sharedArrayKey, sharedArrayKeys, sharedObjectKey, sharedObjectKeys, _i, _j, _len, _len1;
            if (maxDepth == null) {
                maxDepth = 20;
            }
            if (maxDepth <= 0) {
                console.warn('_.deepExtend(): Maximum depth of recursion hit.');
                return _.extend(destination, source);
            }
            sharedObjectKeys = _.intersection(basicObjects(destination), basicObjects(source));
            recurse = function (key) {
                return source[key] = deepExtendCouple(destination[key], source[key], maxDepth - 1);
            };
            for (_i = 0, _len = sharedObjectKeys.length; _i < _len; _i++) {
                sharedObjectKey = sharedObjectKeys[_i];
                recurse(sharedObjectKey);
            }
            sharedArrayKeys = _.intersection(arrays(destination), arrays(source));
            combine = function (key) {
                return source[key] = _.union(destination[key], source[key]);
            };
            for (_j = 0, _len1 = sharedArrayKeys.length; _j < _len1; _j++) {
                sharedArrayKey = sharedArrayKeys[_j];
                combine(sharedArrayKey);
            }
            return _.extend(destination, source);
        };

        deepExtend = function () {
            var finalObj, maxDepth, objects, _i;
            objects = 2 <= arguments.length ? __slice.call(arguments, 0, _i = arguments.length - 1) : (_i = 0, []), maxDepth = arguments[_i++];
            if (!_.isNumber(maxDepth)) {
                objects.push(maxDepth);
                maxDepth = 20;
            }
            if (objects.length <= 1) {
                return objects[0];
            }
            if (maxDepth <= 0) {
                return _.extend.apply(this, objects);
            }
            finalObj = objects.shift();
            while (objects.length > 0) {
                finalObj = deepExtendCouple(finalObj, deepClone(objects.shift()), true, maxDepth);
            }
            return finalObj;
        };

        deepExtendStrict = function () {
            var finalObj, maxDepth, objects, _i;
            objects = 2 <= arguments.length ? __slice.call(arguments, 0, _i = arguments.length - 1) : (_i = 0, []), maxDepth = arguments[_i++];
            if (!_.isNumber(maxDepth)) {
                objects.push(maxDepth);
                maxDepth = 20;
            }
            if (objects.length <= 1) {
                return objects[0];
            }
            if (maxDepth <= 0) {
                return _.extend.apply(this, objects);
            }
            finalObj = objects.shift();
            while (objects.length > 0) {
                finalObj = deepExtendCouple(finalObj, deepClone(objects.shift()), false, maxDepth);
            }
            return finalObj;
        };

        _.mixin({
            deepClone: deepClone,
            isBasicObject: isBasicObject,
            basicObjects: basicObjects,
            arrays: arrays,
            deepExtend: deepExtend,
            deepExtendStrict: deepExtendStrict
        });

    }).call(this);
		`);
	}


	/**
	 * Setup a listener for router changes, this will check if there is a categoryId and a reportId.
	 * If these values are updated, the UI will select this new selection
	 */
	private setupLocationChanges() {
		this.activeRoute.paramMap
				.pipe(untilDestroyed(this))
				.subscribe(value => {
					const categoryId = value.get('categoryId');
					const reportId   = value.get('reportId');
					if (!isNullOrUndefined(categoryId) && !isNullOrUndefined(reportId))
						this.goToReport(parseInt(categoryId, 0), parseInt(reportId, 0));
				});
	}

	onReset() {
		this.formGenerator.onRevertForm();
	}
}
