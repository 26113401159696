import {
	AfterViewInit,
	ChangeDetectionStrategy, ChangeDetectorRef,
	Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild
}                                        from '@angular/core';
import { FormGroup }                     from '@angular/forms';
import { FormGeneratorNxtParser }        from './form-generator-nxt-parser.util';
import { CsValidatorRegistry }           from './cs-validator-registry';
import { FormGeneratorAgentService }     from './form-generator.agent';
import {
	DataDescribed, FormDataDescribed,
	FormLayout, LabelPosition, PropertyAnnotation,
	WidgetInfo
}                                        from '@cs/core';
import { hasPropertyOf }                 from '@cs/core';
import { isNullOrUndefined }             from '@cs/core';
import { ValidationResult }              from '@cs/core';
import { getErrorMessages }              from '@cs/core';
import { Subscription, fromEvent }       from 'rxjs';
import { LoggerUtil }                    from '@cs/core';
import { debounceTime, take }            from 'rxjs/operators';
import { UntilDestroy, untilDestroyed }  from '@ngneat/until-destroy';
import { LookupControlWidget }           from './models/lookup-control-widget.directive';
import { IFormGeneratorNxtComponent }    from './models/form-generator-nxt-component.interface';
import { FORM_GENERATOR_AGENT_ACCESSOR } from './i-form-generator-agent.service';


@UntilDestroy()
@Component({
	selector:        'cs-form-generator-nxt',
	templateUrl:     './form-generator-nxt.component.html',
	exportAs:        'form-generator-nxt',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles:          [
		`:host {
		width: 100%;
	}`],
	providers:       [
		FormGeneratorAgentService,
		{provide: FORM_GENERATOR_AGENT_ACCESSOR, useExisting: forwardRef(() => FormGeneratorAgentService)}
	]
})
/**
 * A next gen. Form generator build with new and learned insights.
 */
export class FormGeneratorNxtComponent<T> implements IFormGeneratorNxtComponent<T>, OnChanges, AfterViewInit, OnDestroy {
	/**
	 * Get the ruler to get the current width of the form
	 */
	@ViewChild('formWidthRuler', {static: true}) formWidthRuler: ElementRef;
	/**
	 * The parsed @Link(DataDescribed) object that is going to be parsed for rendering
	 */
	@Input() data: FormDataDescribed<T>;
	/**
	 * Disable the error checking for the form. This is useful when the component is used as a filter
	 */
	@Input() disableFormErrorChecking = false;
	/**
	 * Disable setting default values for lookups. This is useful when the component is used as a filter
	 */
	@Input() dontSetDefaultValues     = false;
	/**
	 * Disable the sizing detection and let the css do the work
	 */
	@Input() autoSizing: boolean;
	/**
	 * Provide context for the dependent lookup service
	 */
	@Input() contextObject: { [key: string]: any };
	/**
	 * Emits the changed from data
	 */
	@Output() onValuesChange          = new EventEmitter<T>();
	/**
	 * Emits event when there is a action requested by a widget
	 */
	@Output() onActionRequested       = new EventEmitter<PropertyAnnotation<any>>();

	get formWidth() {
		const formRuler     = <HTMLDivElement>this.formWidthRuler.nativeElement;
		const viewPortWidth = formRuler.clientWidth;
		return viewPortWidth;
	}

	/**
	 * The working object for the form component. This object is used for rendering the form and controls
	 */
	form: { widgets: Array<WidgetInfo<T>>; formGroup: FormGroup, layout: FormLayout<any> };
	/**
	 * Stores the initial loaded data so the form is able to reset it to original state
	 */
	protected initialData: T;

	/**
	 * Flag to indicate the form allows auto-completion

	 */
	hasAutoComplete                          = 'off';
	/**
	 * List with subscriptions for easy management and cleanup
	 */
	private subscriptionList: Subscription[] = [];

	/**
	 * Flag to indicate if the fieldset Containers should wrap or just use available space,
	 * is used for dynamic spacing and ordering of the fieldsets
	 */
	wrapFieldSetContainers = false;

	/**
	 * Align the form collection from the center
	 */
	alignCenter  = false;
	alignStretch = false;

	constructor(private csValidatorRegistry: CsValidatorRegistry,
							private formGeneratorAgent: FormGeneratorAgentService<FormGeneratorNxtComponent<T>>,
							private elRef: ElementRef,
							private ngZone: NgZone,
							private cdRef: ChangeDetectorRef) {
		formGeneratorAgent.registerForm(this);
		this.setupEventHandlers();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('data')) {
			if (!isNullOrUndefined(changes.data.currentValue)) {
				this.initiateForm(changes.data.currentValue);
			}
		}
	}

	private initiateForm(data: DataDescribed<T>) {

		this.createForm(data);

		// This is used to wait until all angular changes are done
		this.ngZone.onStable.pipe(take(1)).subscribe(() => {
			this.detectTruncatedFields();
			this.updateForm();

			if (this.form.layout.layout.validateOnInit)
				this.showCurrentErrorStatuses();

			this.form.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(x => this.onValuesChange.emit(this.getFormData()));
		});
	}

	private createForm(dataDescribed: DataDescribed<T>) {
		if (hasPropertyOf(<DataDescribed<T>>dataDescribed, 'dataAnnotation')) {
			this.form            = FormGeneratorNxtParser.parseDataAnnotations(dataDescribed, this.csValidatorRegistry,
				{
					disableFormErrorChecking: this.disableFormErrorChecking,
					dontSetDefaultValues:     this.dontSetDefaultValues
				});
			this.initialData     = JSON.parse(JSON.stringify(this.data.data));
			this.hasAutoComplete = this.form.layout.layout.autoComplete;
			this.alignCenter     = this.form.layout.layout.alignment === 'center';
		}
	}

	showCurrentErrorStatuses() {
		for (const widget of this.form.widgets) {
			widget.control.updateValueAndValidity({onlySelf: true});
			// If not included then something must be wrong with the configuration
			if (!widget.include && !widget.propertyAnnotation.lookup) {
				const errors = getErrorMessages(widget.control, widget.propertyAnnotation);
				errors.forEach(error => {
					alert(`${widget.propertyAnnotation.id} is not found as field please provide it programmatically.
        Error: ${error}`);
				});
			}
		}
		this.cdRef.detectChanges();
	}

	/**
	 * Get the value of the form
	 */
	getFormData(stripKeys: boolean = false): T {

		const data = <T>{};
		Object.keys(this.form.formGroup.controls).forEach(c => data[c] = this.form.formGroup.controls[c].value);
		if (stripKeys)
			FormGeneratorNxtParser.stripKeys(data, this.form);

		return data;
	}

	/**
	 * Get the value of the form

	 */
	async getFormDataAsync(stripKeys: boolean = false): Promise<T> {
		const data = <T>{};
		Object.keys(this.form.formGroup.controls).forEach(c => data[c] = this.form.formGroup.controls[c].value);
		if (stripKeys)
			FormGeneratorNxtParser.stripKeys(data, this.form);

		await this.formGeneratorAgent.convertToFiles(data, this.form);

		return data;
	}

	resetFormData() {
		const data = new DataDescribed(this.data);
		data.data  = this.initialData;

		this.initiateForm(data);
		this.cdRef.detectChanges();
	}

	@HostListener('click', ['$event']) debugClick(e: MouseEvent) {
		if (e.shiftKey) {
			console.log(this);
		}
	}

	/**
	 * Detects if the labels are truncated and if so add an tooltip
	 */
	detectTruncatedFields() {

		const elementList = this.elRef.nativeElement.querySelectorAll('.form-collection-label');

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}

		for (let idx = 0; idx < elementList.length; idx++) {
			if (isEllipsisActive(elementList.item(idx))) {
				elementList.item(idx).title = elementList.item(idx).innerHTML;
			}
		}

	}

	showErrorResponse(errors: ValidationResult[]) {

		for (const error of errors) {

			if (error.memberNames.length === 0 && error.errorMessage) {
				alert(error.errorMessage);
				continue;
			}

			const widget = this.formGeneratorAgent.findWidget(error.memberNames[0]);
			if (isNullOrUndefined(widget)) {
				alert(`${error.memberNames[0]} is not found as field please provide it programmatically.
        Error: ${error.errorMessage}`);
			} else {
				widget.errorMessages = [];
				widget.errorMessages.push(error);
				this.cdRef.detectChanges();
			}

		}
	}

	updateForm() {
		this.hideEmptyFieldsWithEmptyLookups();
		this.cdRef.detectChanges();
	}

	/**
	 * This is for depandent fields. It checks if the field has an lookup and a value if not.
	 * this is than hidden because we need a lookup to set the value
	 */
	private hideEmptyFieldsWithEmptyLookups() {
		for (const field of this.formGeneratorAgent.getAllWidgets()) {
			if (field instanceof LookupControlWidget) {
				if (isNullOrUndefined(field.lookup) || isNullOrUndefined(field.lookup.values) || field.lookup.values.length === 0) {
					const wi = this.formGeneratorAgent.findWidgetInfo(field.id);
					if (!isNullOrUndefined(wi)) {
						wi.include = false;
						LoggerUtil.debug(`Field: ${wi.propertyAnnotation.id.toString()} is hidden because of an empty lookup and a value is not set`);
					}
					const fs = this.formGeneratorAgent.findFormCollectionContainingField(field.id);
					if (!isNullOrUndefined(fs) && fs.widgets.length === 1) {
						fs.include = false;
						LoggerUtil.debug(`Form Collection: ${fs.id} is hidden because of an empty lookup and a value is not set`);
					}

				} else {
					const wi = this.formGeneratorAgent.findWidgetInfo(field.id);
					if (!isNullOrUndefined(wi)) {
						wi.include = true;
					}
					const fs = this.formGeneratorAgent.findFormCollectionContainingField(field.id);
					if (!isNullOrUndefined(fs) && fs.widgets.length === 1) {
						fs.include = true;
					}
				}

			}
		}
	}

	getComponentStyle(index: number) {
		if (!this.form)
			return {};


		if (this.autoSizing) {
			const formWidth = this.formWidth;
			if (formWidth < 700) {
				return {
					width:    '100%',
					flexGrow: 1,
					maxWidth: '100%'
				};
			} else if (formWidth < 1240) {
				return {
					width:    this.form.layout.fieldSets.length === 1 ? 'auto' : this.detectFieldsetDistribution(),
					flexGrow: this.form.layout.fieldSets.length === 1 ? 1 : 0,
					maxWidth: this.getMaxWidth(this.form)
				};
			} else {
				return {
					width:    '100%',
					flexGrow: 1,
					maxWidth: '100%'
				};
			}
		}

		return {
			width:    this.form.layout.fieldSets.length === 1 ? 'auto' : this.detectFieldsetDistribution(),
			flexGrow: this.form.layout.fieldSets.length === 1 ? 1 : 0,
			maxWidth: this.getMaxWidth(this.form)
		};
	}

	ngAfterViewInit(): void {

	}

	ngOnDestroy(): void {
		this.subscriptionList.forEach(x => x.unsubscribe());
	}

	private setupEventHandlers() {
		const obs = fromEvent(window, 'resize');
		obs.pipe(
			debounceTime(300),
			untilDestroyed(this)).subscribe(() => {
			this.detectTruncatedFields();
			this.detectFieldsetDistribution();
			this.cdRef.markForCheck();
		});

		this.formGeneratorAgent.subscribeActionRequested()
				.pipe(untilDestroyed(this))
				.subscribe(value => {
					this.onActionRequested.emit(value);
				});

		// this.subscriptionList.push(subscription);

	}

	private detectFieldsetDistribution() {

		let distribution = 'calc(100% / ' + this.form.layout.fieldSets.length.toString() + ')';
		const formWidth  = this.formWidth;
		const element    = <HTMLElement>this.elRef.nativeElement.querySelector('.nxt-form-fieldset-spacer');

		if (formWidth < 500) {
			distribution = '100%';

			this.setFieldsetSpacerWidth(1);
			this.wrapFieldSetContainers = true;
		} else if (formWidth < 700) {
			distribution = `calc(100% / 2 - ${!element ? 0 : (element.clientWidth / 2)}px)`;

			this.setFieldsetSpacerWidth(2);
			this.wrapFieldSetContainers = true;
		} else {
			this.wrapFieldSetContainers = false;
			this.setFieldsetSpacerWidth(0);
		}

		return distribution;
	}

	private setFieldsetSpacerWidth(number: number) {
		if (this.form.layout.layout.labelPosition === LabelPosition.Left)
			return;

		const elementList = this.elRef.nativeElement.querySelectorAll('.nxt-form-fieldset-spacer');
		if (isNullOrUndefined(elementList))
			return;

		for (let idx = 0; idx < elementList.length; idx++) {
			const elem = elementList.item(idx);
			if (parseInt(elem.id, 0) % number === 0) {
				elem.classList.toggle('d-none', true);
			} else {
				elem.classList.remove('d-none');
			}
		}
	}

	setPristineAgain() {
		this.form.formGroup.markAsPristine({onlySelf: true});
		this.form.formGroup.markAsUntouched({onlySelf: true});
		this.initialData = this.getFormData();
	}

	getContextObject() {
		return this.contextObject;
	}

	private getMaxWidth(form: { widgets: Array<WidgetInfo<T>>; formGroup: FormGroup; layout: FormLayout<any> }) {

		let width = '100%';

		if (form.layout.fieldSets.length === 1 && this.formWidth > 500 && this.formWidth < 700) {
			width = '85%';
		} else if (form.layout.fieldSets.length === 1 && this.formWidth > 700 && this.formWidth < 1024) {
			width = '75%';
		} else if (form.layout.fieldSets.length === 1 && this.formWidth > 1023 && this.formWidth < 1280) {
			width = '50%';
		} else if (form.layout.fieldSets.length === 1 && this.formWidth > 1279) {
			width = '40%';
		}

		return width;
	}
}
