<div class="formgenerator-container">
	<cs-form-generator-nxt
			(onValuesChange)="valueChanged($event)"
			(onActionRequested)="actionRequestedHandler($event)"
			[contextObject]="contextObject"
			[data]="data">
	</cs-form-generator-nxt>
</div>
<div class="btn-group"
	 (mouseover)="showFormState($event)">
	<div
			[matTooltip]="(formGenerator?.form?.formGroup?.pristine ? 'NO_CHANGE_YET': (formGenerator?.form?.formGroup?.invalid?'INVALID_FORM':'UNKNOWN')) | translate"
			[matTooltipDisabled]="formGenerator?.form?.formGroup?.dirty && !formGenerator?.form?.formGroup?.invalid">
		<button class="btn btn--icon-and-text btn--primary shadow-subtle mr-2"
				#saveBtn
				[csLoader]="requestInProgress$ | async"
				[disabled]="formGenerator?.form?.formGroup?.pristine || forceDisable || formGenerator?.form?.formGroup?.invalid"
				[loaderAdditionalClasses]="['loader--white']"
				(click)="notifyChangesToServer()">
			<i class="mdi mdi-content-save"></i>{{'SAVE' | translate}}
		</button>
	</div>
	<div [matTooltip]="'NO_CHANGE_YET' | translate"
		 [matTooltipDisabled]="formGenerator?.form?.formGroup?.dirty"
	>
		<button class="btn btn--white btn--icon-and-text shadow-subtle"

				[disabled]="formGenerator?.form?.formGroup?.pristine"
				*ngIf="data?.layout?.form?.layout?.showResetButton"
				(click)="cancelChanges()">
			<i class="mdi mdi-undo"></i>{{'UNDO_CHANGES' | translate}}
		</button>
	</div>

	<div    [matTooltip]="(formGenerator?.form?.formGroup?.pristine ? 'NO_CHANGE_YET': (formGenerator?.form?.formGroup?.invalid?'INVALID_FORM':'UNKNOWN')) | translate"
			[matTooltipDisabled]="formGenerator?.form?.formGroup?.dirty && !formGenerator?.form?.formGroup?.invalid">
		<button class="btn btn--white btn--icon-and-text shadow-subtle"
				*ngIf="data?.layout?.form?.layout?.showSaveAndCloseButton || true"
				[disabled]="formGenerator?.form?.formGroup?.pristine || forceDisable || formGenerator?.form?.formGroup?.invalid"
				(click)="notifyChangesToServer(true)">
			<i class="mdi mdi-close"></i>{{'CLOSE_AND_SAVE' | translate}}
		</button>
	</div>
	<button class="btn btn--white btn--icon-and-text shadow-subtle"
			*ngIf="data?.layout?.form?.layout?.showCloseButton && formGenerator?.form?.formGroup?.pristine"
			matDialogClose>
		<i class="mdi mdi-close"></i>{{'CLOSE' | translate}}
	</button>
</div>

