import { CsCultureProvider } from './culture-provider';
import { Subject }           from 'rxjs';
import * as parser           from 'accept-language-parser';
import { isNullOrUndefined } from '@cs/core';
import { Injectable }        from '@angular/core';

@Injectable({providedIn: 'root'})
export class CsCultureProviderDefault extends CsCultureProvider {

	currentCulture     = navigator.language;
	availableLanguages = ['en'];

	protected translationLocation = '';

	protected cacheBusterHash         = '';
	onCultureChanged: Subject<string> = new Subject<string>();

	constructor() {
		super();
		this.setAvailableLanguages(this.availableLanguages);
	}

	getCulture(): string {
		return this.currentCulture;
	}

	setCulture(culture) {
		this.currentCulture = culture;
		this.onCultureChanged.next(culture);
	}

	setTranslationLocation(location: string) {
		this.translationLocation = location;
	}

	getTranslationLocation() {
		return this.translationLocation;
	}

	setCacheBusterHash(hash: string) {
		this.cacheBusterHash = hash;
	}

	getCacheBusterHash() {
		return this.cacheBusterHash;
	}

	setAvailableLanguages(languages: string[]) {
		this.availableLanguages = languages;
		const checkLang         = parser.pick(this.availableLanguages, this.currentCulture, {loose: true});
		if (isNullOrUndefined(checkLang)) {
			this.currentCulture = this.availableLanguages[0];
		} else {
			this.currentCulture = checkLang;
		}
	}

}
