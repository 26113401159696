import { FormControl, FormGroup }                  from '@angular/forms';
import { noop }                                    from 'rxjs';
import { Logger }                                  from '@cs/components/util';
import { CsValidatorRegistry }                     from './cs-validator-registry';
import { isNullOrUndefined, LayoutAnnotationForm } from '@cs/core';
import {
	AwbNumberValidationAnnotation,
	DataDescribed,
	DateRangeValidationAnnotation,
	FormLayout,
	FormLayoutFieldSets,
	FormLayoutWidgetCollection,
	FormSettings,
	hasPropertyOf,
	InputMaskValidationAnnotation,
	IValidationAnnotation,
	LabelPosition,
	LoggerUtil,
	MaxlengthValidationAnnotation,
	MinlengthValidationAnnotation,
	pathChecked,
	PropertyAnnotation,
	RangeValidationAnnotation,
	RegexValidationAnnotation,
	RequiredValidationAnnotation,
	ValidationAnnotationType,
	WidgetAnnotation,
	WidgetCollection,
	WidgetInfo,
	WidgetLayout
}                                                  from '@cs/core';
import { WidgetFactory }                           from './widget-factory.service';


export class FormGeneratorNxtParser {

	static parseDataAnnotations<T>(dataDescr: DataDescribed<T, LayoutAnnotationForm<any>>, csValidatorRegistry: CsValidatorRegistry,
																 options: { disableFormErrorChecking: boolean, dontSetDefaultValues: boolean }):
		{ widgets: Array<WidgetInfo<T>>, formGroup: FormGroup, layout: FormLayout<any> } {

		const controls                         = {};
		const widgetList: Array<WidgetInfo<T>> = [];

		if (Array.isArray(dataDescr.data))
			LoggerUtil.error('The data in data-describe is an array. please correct this or create a table', true);

		for (const annot of dataDescr.dataAnnotation.fields) {
			const fieldName = annot.id.toString();
			dataDescr.data.hasOwnProperty(fieldName) ? noop()
				: LoggerUtil.error(`${fieldName} is not found in the DataDescribed.data property`, true);

			const validators    = options.disableFormErrorChecking ? [] : FormGeneratorNxtParser.getValidators(annot, csValidatorRegistry);
			const formControl   = new FormControl(dataDescr.data[fieldName], validators);
			controls[fieldName] = formControl;
			const wi            = new WidgetInfo(new PropertyAnnotation(annot), formControl);
			widgetList.push(wi);
		}

		const layout = this.parseLayoutAnnotation(dataDescr, widgetList);

		const formGroup = new FormGroup(controls);

		return {formGroup: formGroup, widgets: widgetList, layout: layout};
	}

	static parseValidatorsList(validators: IValidationAnnotation[],
														 csValidatorRegistry: CsValidatorRegistry,
														 propType?: string) {
		const output = [];

		if (isNullOrUndefined(validators))
			return output;

		// Switch to the function variant because a switch in a for loop with breaks. breaks the for loop :S
		validators.forEach(val => {
			switch (val.type) {
				case ValidationAnnotationType.Minlength:
					output.push(new MinlengthValidationAnnotation(val as MinlengthValidationAnnotation));
					break;
				case ValidationAnnotationType.Maxlength:
					output.push(new MaxlengthValidationAnnotation(val as MaxlengthValidationAnnotation));
					break;
				case ValidationAnnotationType.Required:
					output.push(new RequiredValidationAnnotation(val as RequiredValidationAnnotation));
					break;
				case ValidationAnnotationType.Range:
					const rangeVal = val as RangeValidationAnnotation;
					// Convert a date to a UNIX timestamp, so the validator can compare with numbers instead of dates
					// TODO: this should be formalised by the server
					if (propType === 'DateTime')
						output.push(new DateRangeValidationAnnotation(rangeVal));
					else
						output.push(new RangeValidationAnnotation(rangeVal));
					break;
				case ValidationAnnotationType.Regex:
				case 'regex':
					output.push(new RegexValidationAnnotation(val as RegexValidationAnnotation));
					break;
				case ValidationAnnotationType.InputMask:
					output.push(new InputMaskValidationAnnotation(val as InputMaskValidationAnnotation));
					break;
				case ValidationAnnotationType.AwbNumber:
					output.push(new AwbNumberValidationAnnotation(val as AwbNumberValidationAnnotation));
					break;
				default:
					if (isNullOrUndefined(val.type)) {
						Logger.Warning(`${JSON.stringify(val)} is not a valid validator`);
						break;
					}
					if (csValidatorRegistry.hasValidator(val.type)) {
						const validator = csValidatorRegistry.getValidator(val.type);
						if (isNullOrUndefined(validator)) {
							Logger.Warning(`${val.type} was found as validator, but the entry is NULL`);
							break;
						}

						output.push(new validator(val));
					} else {
						Logger.Warning(`${val.type} is not found as validator`);
					}

			}
		});
		return output;
	}

	static parseValidators(propertyAnnot: PropertyAnnotation<any>, csValidatorRegistry: CsValidatorRegistry) {
		return FormGeneratorNxtParser.parseValidatorsList(propertyAnnot.validators, csValidatorRegistry, propertyAnnot.type);
	}

	static parseLayoutAnnotation(dataDescribe: DataDescribed<any, LayoutAnnotationForm<any>>, widgetList: Array<WidgetInfo<any>>): FormLayout<any> {

		const layout     = new LayoutAnnotationForm(dataDescribe.layout);
		const formLayout = new FormLayout(layout.form);

		this.setVisibilityOfTheFields(widgetList, layout);

		const filteredWidgetList = widgetList; // .filter(w => w.include);

		layout.form.fieldSets.push(...dataDescribe.dataAnnotation.groups);

		const defaultFieldSetGroupName = isNullOrUndefined(dataDescribe.layout)
		|| isNullOrUndefined(dataDescribe.layout.form)
		|| isNullOrUndefined(dataDescribe.layout.form.layout)
		|| isNullOrUndefined(dataDescribe.layout.form.layout.defaultFieldSetGroupName)
			? '' : dataDescribe.layout.form.layout.defaultFieldSetGroupName;

		// Check if there are fieldsets created
		// if not fill the default field set
		if (layout.form.fieldSets.length === 0) {
			formLayout.fieldSets.push(new FormLayoutFieldSets('default', defaultFieldSetGroupName));
		} else if (layout.form.fieldSets.length > 0) {
			formLayout.fieldSets.push(new FormLayoutFieldSets('default', defaultFieldSetGroupName),
				...layout.form.fieldSets.map(fs => new FormLayoutFieldSets(fs.id, fs.label)));
		}
		// use this list to check if all the widget have been added
		const foundWidgetsTrackList: string[] = [];

		// loop over the newly create FormFieldsets
		for (const fieldSet of formLayout.fieldSets) {
			let found: WidgetCollection[] = [];
			// Find all widgetCollections for this fieldSet
			found                         = layout.form.widgetCollections.filter(item => item.fieldSetId === fieldSet.id);

			if (fieldSet.id === 'default') {
				found.push(...dataDescribe.dataAnnotation.fields
																	.filter(f => isNullOrUndefined(f.groupId))
																	.map((value, index) => new WidgetCollection({
																		label: value.label,
																		id:    value.id.toString()
																	})));
			} else {
				found.push(...dataDescribe.dataAnnotation.fields
																	.filter(f => f.groupId === fieldSet.id)
																	.map((value, index) => new WidgetCollection({
																		fieldSetId: value.groupId,
																		label:      value.label,
																		id:         value.id.toString()
																	})));
			}

			// fieldSet.widgetCollections = found.map(wc => new FormLayoutWidgetCollection(wc));

			// Ignore the widgets that have been added manual to a form collection
			const ignoreWidgets = pathChecked(layout, ['form', 'widgets'], [], false)
				.filter(value => value.widgetCollectionId != null)
				.map(value => value.id);
			found               = found.filter(value => ignoreWidgets.indexOf(value.id) === -1);

			for (const foundWidget of found) {

				const widget = filteredWidgetList.find(fw => fw.propertyAnnotation.id === foundWidget.id);

				// check if the widget is already added
				if (isNullOrUndefined(widget)) {
					const manualWidgets = layout.form.widgets.filter(pw => pw.widgetCollectionId === foundWidget.id);

					if (manualWidgets.length === 0)
						continue;

					// Create the WidgetCollection with label and id set to the widget
					const widgetCollection   = new FormLayoutWidgetCollection({
						label: foundWidget.label,
						id:    foundWidget.id
					}, foundWidget.include);
					widgetCollection.widgets = manualWidgets
						.map(value => filteredWidgetList.find(value1 => value1.propertyAnnotation.id === value.id));
					fieldSet.widgetCollections.push(widgetCollection);
				} else {

					// Create the WidgetCollection with label and id set to the widget
					const widgetCollection   = new FormLayoutWidgetCollection({
						label: widget.propertyAnnotation.label,
						id:    widget.propertyAnnotation.id.toString()
					}, widget.include);
					widgetCollection.widgets = [widget];
					fieldSet.widgetCollections.push(widgetCollection);
				}
			}
		}

		for (const fieldSet of formLayout.fieldSets) {
			for (const coll of fieldSet.widgetCollections) {
				coll.showLabel = formLayout.layout.labelPosition === LabelPosition.Left;
				for (const widget of coll.widgets) {
					coll.widgets.forEach(w => {
						const foundParsedWidget: WidgetAnnotation<any> = layout.form.widgets.find(pw => pw.id === w.propertyAnnotation.id);
						const formDefaultSetting                       = {
							showLabel: formLayout.layout.labelPosition === LabelPosition.Top
						};
						const layoutSettings                           = {};
						Object.assign(layoutSettings,
							formDefaultSetting,
							!isNullOrUndefined(foundParsedWidget)
							&& hasPropertyOf(foundParsedWidget, 'layout') ? foundParsedWidget.layout : {});
						w.layout = new WidgetLayout(layoutSettings);
					});
				}
			}
		}

		formLayout.fieldSets = formLayout
			.fieldSets
			.filter(fs => fs.widgetCollections.length > 0 && fs.isVisible);

		return formLayout;
	}

	private static fillCollectionWithWidgetInfo(widgetCollection: FormLayoutWidgetCollection<any>,
																							widgetList: Array<WidgetInfo<any>>, fields: PropertyAnnotation<any>[]) {
		const foundWidgets = fields.filter(w => w.id === widgetCollection.id);

		widgetCollection.widgets = widgetList.filter(wi =>
			foundWidgets.find(w => w.id === wi.propertyAnnotation.id &&
				!isNullOrUndefined(fields.find(pw => pw.id === w.id))));

		return foundWidgets;
	}


	private static getValidators(annotation: PropertyAnnotation<any>, csValidatorRegistry: CsValidatorRegistry) {
		// extra is required check because some properies
		// could have been optional false but the required attribute is missing so no required validation is added
		if (!annotation.optional && !isNullOrUndefined(annotation.validators)
			&& isNullOrUndefined(annotation.validators.find(a => a.type === ValidationAnnotationType.Required))) {
			annotation.validators.push({
				type:          ValidationAnnotationType.Required,
				errorMessage:  `${annotation.label} is required`, // TODO: TRanslate?
				validatorFunc: null // is ignored by the constructor
			});
			LoggerUtil.warn(
				`${annotation.id.toString()} is required, but no required attribute is added to the model. So we added an fallback validator`);
		}

		annotation.validators = FormGeneratorNxtParser.parseValidators(annotation, csValidatorRegistry);

		// when optional is true but we have a required validator change it to optional false
		if (annotation.optional && !isNullOrUndefined(annotation.validators)
			&& annotation.validators.find(a => a.type === ValidationAnnotationType.Required)) {
			annotation.optional = false;
		}

		const output = [];
		for (const validator of annotation.validators) {
			output.push(...validator.validatorFunc());
		}
		return output;
	}

	/**
	 * Get the widgetType that should be rendered. THis is the place to add some smart handling of attribute to set the type.
	 * instead of providing it as widgetType in the layout
	 * @returns The type the form needs to render
	 */
	static getWidgetTypeToRender(widgetInfo: WidgetInfo<any>, formSettings: FormSettings, widgetFactory: WidgetFactory) {
		// check if the widget is readonly
		if ((formSettings.readOnlyAsText && formSettings.readOnly) || (widgetInfo.layout.readOnly || widgetInfo.propertyAnnotation.readOnly)) {
			widgetInfo.layout.readOnly             = true;
			widgetInfo.propertyAnnotation.readOnly = true;
			if (// When the form setting is
				formSettings.readOnlyAsText
				// When the property had an override to be displayed as a label when readonly
				|| widgetInfo.layout.readOnlyAsText
				|| widgetFactory.hasWidgetReadOnlyState(widgetInfo.propertyAnnotation.type)) {
				return 'label';
			}

		}

		// check if the PropertyAnnotation has an lookup
		// return the widgetType accordingly
		if (!isNullOrUndefined(widgetInfo.propertyAnnotation.lookup))
			return 'select';

		if (widgetInfo.propertyAnnotation.validators.find(value => value.type === ValidationAnnotationType.InputMask))
			return 'input-mask';

		// The default type of the data-annotation
		return widgetInfo.propertyAnnotation.type;
	}

	static stripKeys(value: any, form: { widgets: Array<WidgetInfo<any>>; formGroup: FormGroup; layout: FormLayout<any> }) {
		const obj = {};
		for (const key of Object.keys(value)) {
			const foundWidget = form.widgets.find(w => w.propertyAnnotation.key && w.propertyAnnotation.id === key);
			if (isNullOrUndefined(foundWidget)) {
				obj[key] = value[key];
			}
		}
		return obj;
	}


	/**
	 * Set the include flag, so the form knows which fields should be rendered
	 */
	private static setVisibilityOfTheFields(widgetList: Array<WidgetInfo<any>>, layout: LayoutAnnotationForm<any>) {
		for (const w of widgetList) {
			const found = layout.form.widgets.find((lfw: WidgetAnnotation<any>) => lfw.id === w.propertyAnnotation.id);
			// if explicit included override all
			if (!isNullOrUndefined(found) && hasPropertyOf(found, 'include')) {
				w.include = found.include;
				continue;
			}

			let result = true;

			// Check if property is set to visible
			if (w.propertyAnnotation.visible === false)
				result = false;
			// Show field when form is set to include Keys
			else if (!isNullOrUndefined(layout.form.layout) && layout.form.layout.includeKeyFields)
				result = true;
			// Hide the field when it's a key and it's generated so the user can't set the value
			else if (w.propertyAnnotation.key === true && w.propertyAnnotation.generatedKey === true)
				result = false;
			// Hide the field when it's a key but not generated and the value is already set
			else if ((w.propertyAnnotation.key === true
				&& !w.propertyAnnotation.generatedKey
				&& !isNullOrUndefined(w.control.value) && w.control.value !== 0))
				result = false;

			w.include = result;
		}
	}


}
