import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerRoutes }       from '@cs/customer/pages/module.routes';
import { IsLoggedInGuard }      from '@cs/performance-manager';
import { pmAppSettings }        from '../environments/environment';
import { Environment }          from '@cs/common';

const routes: Routes = [{
	path:     '',
	children: [
		...CustomerRoutes.appRoutingModule,
		{
			path:         'login',
			canActivate:  [IsLoggedInGuard],
			loadChildren: () => import('../module-wrappers/pm-login-module-loader').then(m => m.PmLoginLoaderModule)
		},
		{
			path:         'error',
			loadChildren: () => import('../module-wrappers/pm-error-module-loader').then(m => m.PmErrorLoaderModule)
		},
		{
			path:         '',
			loadChildren: () => import('../module-wrappers/pm-shell-module-loader').then(m => m.PmShellLoaderModule)
		},
		// To be changed to 404 page
		{
			path:       '**',
			redirectTo: 'login'
		}
	]
}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {enableTracing: pmAppSettings.environment !== Environment.PRODUCTION})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
