import { CsCultureProvider }            from './culture-provider';
import { Inject, Injectable }           from '@angular/core';
import { CsCultureDateParserFormatter } from './date-culture-parser-formatter';
import { isNullOrUndefined }            from '@cs/core';

@Injectable({providedIn: 'root'})
export class CsCultureDateParserFormatterDefault extends CsCultureDateParserFormatter {
	private dateFormats = {'en-GB': 'dd/MM/yyyy', 'en-US': 'MM/dd/yyyy', 'nl-NL': 'dd-MM-yyyy'};

	constructor(@Inject(CsCultureProvider) private csCultureProvider: CsCultureProvider) {
		super();
	}

	private normalizeDate(datestr: string): string {
		const splitter  = this.csCultureProvider.getCulture() === 'nl-NL' ? '-' : '/';
		const dateParts = datestr.split(splitter);
		dateParts[0]    = dateParts[0].length === 1 ? '0' + dateParts[0] : dateParts[0];
		dateParts[1]    = dateParts[1].length === 1 ? '0' + dateParts[1] : dateParts[1];
		return dateParts.join(splitter);
	}

	parseDate(date: any): Date {
		if (Object.prototype.toString.call(date) === '[object Date]' || isNullOrUndefined(date)) {
			return date;
		}
		const dateString = date;
		const culture    = this.csCultureProvider.getCulture();
		let year, month, day;
		const dateSplit  = dateString.split('/').join(',').split('-').join(',').split(',');
		const isISO      = dateSplit[0].length > 2;
		if (isISO) {
			return new Date(+dateSplit[0], +dateSplit[1] - 1, parseInt(dateSplit[2], 10), 0, 0, 0, 0);
		}
		year = +dateSplit[2];
		if (culture === 'en-GB' || culture === 'nl-NL') {
			day   = +dateSplit[0];
			month = +dateSplit[1];
		} else if (culture === 'en-US') {
			day   = +dateSplit[1];
			month = +dateSplit[0];
		}
		if (month > 12) {
			month = 12;
		}
		let _date = new Date(year, month - 1, day, 0, 0, 0, 0);
		if (_date.getDate() !== +day) {
			_date = new Date(year, month, 0, 0, 0, 0, 0);
		}
		return _date;
	}


	formatDate(date: string): string {
		const _date = new Date(date);
		if (!_date) {
			return date;
		}
		return this.normalizeDate(_date.toLocaleDateString(this.csCultureProvider.getCulture()));
	}

	getDateFormat(): string {
		return this.dateFormats[this.csCultureProvider.getCulture()];
	}
}
