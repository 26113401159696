import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef
}                                                    from '@angular/core';
import { RowButton }                                 from '../classes';
import { SpinnerChangedEventArgs }                   from '@cs/components/spinner';
import { DataGridLookupAction }                      from '../classes/data-grid-action';
import {
	CellActionClickEventArgs,
	CellClickEventArgs,
	RowButtonClickEventArgs,
	RowClickEventArgs,
	SheetActionEventArgs
}                                                    from '../event-args';
import { IProperty, isNullOrUndefined, isUndefined } from '@cs/core';

import { CsDataGridPopoversService, CsPopoverSetup, DataGridMessageHubService } from '../services';

import { Subscription }                                      from 'rxjs';
import { Logger }                                            from '@cs/components/util';
import { DataGridCellType, PopoverTypes, RowState, UiTypes } from '../enums/data-grid.enum';

import { GridDataCell }                                    from '../models/grid-data-cell.model';
import { GridGroup }                                       from '../models/grid-group.model';
import { GridSheet }                                       from '../models/grid-sheet.model';
import { DataGridHelpers }                                 from '../utils/data-grid-helpers';
import { GridDataRow }                                     from '../models';
import { AdvancedDropdownItem, SelectionChangedEventArgs } from '@cs/components/advanced-dropdown';
import { SafeMethods }                                     from '@cs/common';
import { CsDataGridParentService }                         from '../services/cs-data-grid-parent.service';


@Component({
	selector:        'td[grid-data-td-component]',
	templateUrl:     './grid-data-td.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CsGridDataTdComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() cell: GridDataCell;
	@Input() row: GridDataRow;
	@Input() group: GridGroup;
	@Input() sheet: GridSheet;
	@Input() index: number;

	@ViewChild('container', {read: ViewContainerRef}) _viewContainerRef;
	@ViewChild('container') containerElementRef;

	@ViewChild('hovercorner', {read: ViewContainerRef}) _hoverCornerViewContainerRef;
	@ViewChild('hovercorner') hoverCornerElementRef;

	//#region Template Defintion
	// @ViewChild('select', {static: false}) select;
	@ViewChild('selectSearch') select;
	@ViewChild('datepicker') datepicker;
	@ViewChild('datagrid') datagrid;
	@ViewChild('checkbox') checkbox;
	@ViewChild('cellcheckbox') cellcheckbox;
	@ViewChild('cellcheckboxlabel') cellcheckboxlabel;
	@ViewChild('spinner') spinner;
	@ViewChild('rowmenu') rowmenu;
	//#endregion

	/**
	 * Id added to the html node
	 */
	@HostBinding('attr.id') attrId: string;
	/**
	 * set the colspan for the td
	 */
	@HostBinding('attr.colspan') attrColspan: number;
	/**
	 * Binding to the dynamic classes updated by @Link updateClasses()
	 */
	@HostBinding('class') class: string;
	/**
	 * Popover host instance for showing the mouse over popover
	 */
	private dataCellPopover: CsPopoverSetup<GridDataCell>;
	/**
	 * Popver for showing events
	 */
	private hoverCornerPopover: CsPopoverSetup<GridDataCell>;
	/**
	 * list of classes updated by @Link updateClasses()
	 */
	private classList: string[] = [];

	/**
	 * Collection of Observable subscriptions
	 */
	private subscriptions: Subscription[] = [];

	/**
	 * Disallow negative values
	 */
	notNegative = true;

	/**
	 * The minimum value allowed to enter
	 */
	minimumValue = -1000000;
	private lookupCache: {
		identifier: string; search: { searchEndPoint: string; hasSearchBar: boolean };
		values: { data: any[]; label: string }[]; label: string; selectedValue: string
	};
	advancedDropdown: any;

	get parent() {
		return this.parentService;
	}

	constructor(private _elementRef: ElementRef,
							private changeRef: ChangeDetectorRef,
							private dataGridHub: DataGridMessageHubService,
							private dataGridPopoversService: CsDataGridPopoversService,
							private parentService: CsDataGridParentService) {

	}

	@HostListener('mouseenter', ['$event'])
	showOnHover(event): void {
		this.setupPopovers();
	}

	/**
	 * There is no onClick for the cells. The cell is resolved by the id. See @Link CsDataGrid
	 */
	onClick($event: MouseEvent): void {
	}

	ngOnInit() {
		this.attrId      = this.cell.id;
		this.attrColspan = this.cell.colSpan;
		this.notNegative = this.parent.notNegative;

		if (this.notNegative)
			this.minimumValue = 0;

		this.updateClasses();
		this.checkForDisabledOptions();
	}

	/**
	 * Update the classes based on the rules, described in the function
	 */
	updateClasses() {
		// Reset the classlist so all classes are added from scratch
		this.classList = [];
		/**
		 * Add injected class when cell is injected
		 */
		this.addToClassList(this.cell.cellType === DataGridCellType.Injected, 'injected');
		/**
		 * Add the checkbox style when the cell is a row selection checkbox
		 */
		this.addToClassList(this.cell.cellUIState.uiType === UiTypes.Checkbox
			|| this.cell.cellUIState.uiType === UiTypes.CellCheckbox, 'checkbox');
		/**
		 * Use the label class when the cell is a label
		 */
		this.addToClassList(this.cell.cellUIState.uiType === UiTypes.CellCheckboxLabel, 'checkboxlabel');
		/**
		 * Add the rowMenu class when the cell is a rowMenu
		 */
		this.addToClassList(this.cell.cellUIState.uiType === UiTypes.RowMenu, 'rowMenu');
		/**
		 * Use the label class when the cell is a label
		 */
		this.addToClassList(this.cell.cellType === DataGridCellType.Label, 'label');
		/**
		 * Add class when the cell is in editable state
		 */
		this.addToClassList(this.cell.cellState.editable
			&& this.cell.cellUIState.uiType !== UiTypes.Checkbox, 'edit-cell');
		/**
		 * Add the class when cell is read only
		 */
		this.addToClassList(!this.cell.cellState.editable, 'read-only-cell');
		/**
		 * Add the class when the cell is changed
		 */
		this.addToClassList(this.cell.cellUIState.dirty, 'dirty');
		/**
		 * Add class when the td should be hidden
		 */
		this.addToClassList(this.cell.cellUIState.isHidden, 'is-hidden');
		/**
		 * Add the class when the cell has an hover-corner
		 */
		this.addToClassList(this.cell.cellUIState.hasDataEvents, 'highlighted');
		/**
		 * Add the class when the cell has invalid input
		 */
		this.addToClassList(this.cell.cellUIState.invalid, 'invalid');
		/**
		 * Add the class when the cell is editable but not yet in editable state
		 */
		this.addToClassList(!this.cell.cellState.readonly
			&& this.cell.cellUIState.uiType !== UiTypes.Checkbox
			&& this.cell.cellUIState.uiType !== UiTypes.CellCheckbox
			&& this.cell.cellUIState.uiType !== UiTypes.CellCheckboxLabel
			&& this.cell.cellUIState.uiType !== UiTypes.RowMenu, 'CanEdit');


		this.class = this.applyRuleBasedCss();
	}

	private addToClassList(conditionResult: boolean, classToAdd: string) {
		if (conditionResult)
			this.classList.push(classToAdd);
	}

	/**
	 * Function to apply the correct css classes to the TD, Should be replaced to a more performable solution
	 * @param cssObject Html version of the css object
	 * @param cell The datagrid cell
	 * @returns Returns the object with the classes angular should apply
	 */
	private addCss(cssObject, cell): object {
		let found           = cell.dynamicCssClass.split(' ');
		found               = found.concat(cell.staticCssClass.split(' '));
		const objectClasses = {};
		for (const fclass of found) {
			objectClasses[fclass] = true;
		}
		Object.assign(objectClasses, cssObject);
		return objectClasses;
	}

	/**
	 * Function to apply the correct css classes to the TD
	 * @returns Returns the class string with the classes angular should apply
	 */
	private applyRuleBasedCss(): string {
		let found           = this.cell.dynamicCssClass.split(' ');
		found               = found.concat(this.cell.staticCssClass.split(' '));
		found               = [...found, ...this.classList];
		const objectClasses = {};
		for (const fclass of found) {
			objectClasses[fclass] = true;
		}
		return Object.keys(objectClasses).join(' ');
	}

	/**
	 * Get the template for the cell
	 * @param type The component we want to show
	 * @returns The template that should be loaded in the cell
	 */
	getTemplate(type: UiTypes): TemplateRef<any> {
		if (this.hasOwnProperty(type.toString().toLowerCase())) {
			return this[type.toString().toLowerCase()];
		} else {
			Logger.ThrowError(`${type} is not found on datagrid templates`);
		}
	}

	ngAfterViewInit(): void {
	}

	setupPopovers() {

		if (this.cell.cellUIState.uiType === UiTypes.DataGrid)
			return;


		if (isNullOrUndefined(this.dataCellPopover)) {
			this.dataCellPopover = {
				lookupId:          this.cell.id,
				elementIdentifier: 'cs-grid-data-cell',
				elementRef:        this.containerElementRef,
				data:              this.cell,
				hasNoPopover:      (celldata) => {
					return celldata.cellUIState.hasPopoverHover === PopoverTypes.None // has no popover attached
						|| celldata.cellData.length === 0																// when no datasources
						|| celldata.cellState.editable																	// when in editmode
						|| this.row.rowState === RowState.New;													// when the cell is in a newly created row
				},
				getPopoverType:    data => data.cellUIState.hasPopoverHover
			};

			this.dataGridPopoversService.registerPopover(this.dataCellPopover);
		}

		if (isNullOrUndefined(this.hoverCornerPopover)) {

			this.hoverCornerPopover = {
				lookupId:          this.cell.id,
				elementIdentifier: 'hover-corner',
				elementRef:        this.hoverCornerElementRef,
				data:              this.cell,
				hasNoPopover:      (celldata) => !celldata.cellUIState.hasDataEvents,
				getPopoverType:    data => data.cellUIState.hasPopoverHover
			};

			this.dataGridPopoversService.registerPopover(this.hoverCornerPopover);

			// Close DataCellPopover when HoverCornerPopover is shown
			// this.subscriptions.push(this.hoverCornerPopover.popoverOpened.subscribe((event) => {
			//   if (!isNullOrUndefined(this.dataCellPopover) && this.dataCellPopover.isOpen)
			//     this.dataCellPopover.popoverRef.popover.hideOnHover();
			// }));

		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => {
			if (!isNullOrUndefined(sub))
				sub.unsubscribe();
		});

	}

	handleCellKeyboardInput($event: KeyboardEvent) {
		this.notifyCheckForTabPress($event); // calls cellInputChanges internally
		if ($event.which !== 9) {
			// also call on keyinput, otherwise last user entered number is not saved (waiting for the <input> (change) event to occur)
			this.notifyCellInputChanged($event);
		}
	}

	notifyCellInputChanged($event: Event) {
		//  this.cellInputChanged.emit($event);
		// this.dataGridHub.cellInputChanged.next(new CellChangedEventArgs(this, $event))
		this.parent.cellInputChanged(this.cell, this.row, this.group, this.sheet, $event);
	}

	notifySelectionChanged($event: MouseEvent) {
		// this.dataGridHub.selectionChanged.next(new CellSelectionChangedEventArgs(this.cell, this.row, this.group, this.sheet, $event))
		this.parent.selectionChanged(this.cell, this.row, this.group, this.sheet, $event);
	}

	notifyCheckForTabPress($event: KeyboardEvent) {
		// this.dataGridHub.checkForTabPress.next(new CellTabbedEventArgs(this.i, this.cell, this.row, this.sheet, $event))
		this.parent.checkForTabPress(this.cell, this.row, this.group, this.sheet, this.index, $event);
	}

	notifyUpdateRowValues(changeEvent: SpinnerChangedEventArgs) {
		// this.dataGridHub.updateRowValues.next(new CellSpinnerChangedEventArgs(this.cell, this.row, this.group, this.sheet, changeEvent))
		this.parent.updateRowValues(this.cell, this.row, this.group, this.sheet, changeEvent);
	}

	notifyRowSelected() {
		this.row.selected = !this.row.selected;

		if (this.row.spinnerOnSelected) {
			this.row.selected ? this.row.enableSpinnerRow() : this.row.disableSpinnerRow();
		}
		// this.dataGridHub.rowSelected.next(new RowClickEventArgs(this.row, this.sheet));
		this.parent.rowSelected(new RowClickEventArgs(this.row, this.sheet));
	}

	notifyRowButtonClicked(button: RowButton) {
		this.parent.rowButtonClicked(button, this.row, this.group, this.sheet);
	}

	markForCheck() {
		this.updateClasses();
		this.changeRef.markForCheck();
	}

	detectChanges() {
		this.changeRef.markForCheck();
		SafeMethods.detectChanges(this.changeRef);
	}

	/**
	 * Allow checkbox to be selected without triggering the cellClicked() function
	 */
	cellCheckboxClicked(event: MouseEvent, cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet) {
		if (!cell.cellState.readonly) {
			const fakeEvent: any = {target: {value: !cell.value}};
			this.notifyCellInputChanged(fakeEvent);
		}

		// Make sure the event will not bubble up to the row click
		event.preventDefault();
		event.stopPropagation();
		event.cancelBubble = true;
	}

	getLookup(lookups: Array<DataGridLookupAction>, cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet) {
		if (isNullOrUndefined(lookups)) {
			return [{label: 'Not found'}];
		}
		const found = lookups.find(x => x.lookupType === 'ChoiceSet');
		if (isUndefined(found)) {
			Logger.Warning(`ChoiceSet is not found in: ${JSON.stringify(lookups)}`);
			return [];
		}

		cell.lookupLabel = found.display;

		let list = this.parent._resolvedMemberList.get(found.key);
		if (isNullOrUndefined(list)) {
			console.warn(`No Member list found for "${found.key}". (memberLists should be defined in both config.structure
      and structureData and memberList property should be set on choiceset)`);
			return [];
		}
		list = list.filter(x => !isNullOrUndefined(x));
		list.forEach(x => x.disabled = false);

		const cellValueIsDisabled = this.disableOptions(found, cell, row, group, sheet, list);
		// TODO : fix when cell value is disabld
		if ((!isNullOrUndefined(list) && isNullOrUndefined(cell.value)) || cellValueIsDisabled) {
			// Get the first not disabled option
			const foundNotDisabled = list.find(x => !x.disabled);
			if (found) {
				cell.updateValue(foundNotDisabled.id);

				this.notifySelectionChanged(null);
				this.detectChanges();
			} else {
				cell.updateValue(0);

				this.notifySelectionChanged(null);
				this.detectChanges();
			}
		}

		return list;
	}

	/**
	 * Disable lookup when unique item is already selected in the sheet
	 */
	private disableOptions(found: DataGridLookupAction, cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet,
												 list: Array<IProperty>) {
		let cellValueIsDisabled = false;

		const foundSets = this.parent.options.choiceSets.rowSets.filter(x => found.key === x.name && x.unique);
		if (isNullOrUndefined(foundSets) || foundSets.length === 0)
			return;

		// Combined (Cell) keys in row for all ChoiceSets
		const rowValues = DataGridHelpers.findKeyByRowSet(row, [this.sheet], foundSets);

		// rows.keys does not have injectedKey property. Remove if exists.
		if (rowValues.hasOwnProperty('injectedkey'))
			delete rowValues.injectedkey;

		const foundRows = DataGridHelpers.findRows(sheet, rowValues);

		if (!isNullOrUndefined(foundRows) && foundRows.length === 0) {
			for (const foundRow of foundRows) {
				const foundEntry = list.find(item => item.id === foundRow.keys[found.key.toLowerCase()]);

				if (isNullOrUndefined(foundEntry))
					continue;

				foundEntry.disabled = true;
				if (foundEntry.id === cell.value) {
					cellValueIsDisabled = true;
				}
			}
		}
		return cellValueIsDisabled;
	}

	/**
	 * Re-emit event to parent grid
	 */
	nestedCellEditedHandler($event: CellClickEventArgs) {
		this.parent.onCellsEdited.emit($event);
	}

	/**
	 * Re-emit event to parent grid
	 */
	nestedSheetActionClickedHandler($event: SheetActionEventArgs) {
		this.parent.onSheetActionClicked.emit($event);
	}


	/**
	 * Re-emit event to parent grid
	 */
	nestedRequestingCellActionOnClickHandler($event: CellActionClickEventArgs) {
		this.parent.requestingCellActionOnClick.emit($event);
	}

	/**
	 * Re-emit event to parent grid
	 */
	nestedNavigationRequestHandler($event: CellClickEventArgs) {
		this.parent.onNavigationRequested.emit($event);
	}

	/**
	 * Re-emit event to parent grid
	 */
	nestedRowAddedHandler($event: RowClickEventArgs) {
		this.parent.onRowAdded.emit($event);
	}

	/**
	 * Re-emit event to parent grid
	 */
	nestedRowSelectedHandler($event: RowClickEventArgs) {
		this.parent.onRowSelected.emit($event);
	}

	/**
	 * Re-emit event to parent grid
	 */
	nestedRowButtonClickedHandler($event: RowButtonClickEventArgs) {
		this.parent.onRowButtonClicked.emit($event);
	}

	/**
	 * Selection change triggerd by advanced dropdown
	 */
	onAdvancedDropdownSelectionChanged($event: SelectionChangedEventArgs) {
		if ($event.item == null || $event.item[0] == null)
			return;

		this.cell.updateValue($event.item[0].identifier);
		this.parent.selectionChanged(this.cell, this.row, this.group, this.sheet, null);


		const newRows = DataGridHelpers.flattenRows(this.sheet).filter(value => value.rowState === RowState.New);
		for (const row of newRows) {
			for (const cell of row.values) {
				if (cell.cellType !== DataGridCellType.Injected)
					continue;

				const found = this.parent.findCellTdById(cell.id) as CsGridDataTdComponent;
				found.checkForDisabledOptions();
			}
		}

	}

	convertToDropdown(lookups: Array<DataGridLookupAction>, cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet) {

		if (isNullOrUndefined(lookups)) {
			return [{label: 'Not found'}];
		}

		const found = lookups.find(x => x.lookupType === 'ChoiceSet');
		if (isUndefined(found)) {
			Logger.Warning(`ChoiceSet is not found in: ${JSON.stringify(lookups)}`);
			return [];
		}

		cell.lookupLabel = found.display;

		let list = this.parent._resolvedMemberList.get(found.key);
		if (isNullOrUndefined(list)) {
			console.warn(`No Member list found for "${found.key}". (memberLists should be defined in both config.structure and structureData and memberList property should be set on choiceset)`);
			return [];
		}
		list = list.filter(x => !isNullOrUndefined(x));
		list.forEach(x => x.disabled = false);

		// Set default values for dropdowns if the defaultValue property exists
		const foundSets    = this.parent.options.choiceSets.rowSets.filter(x => found.key === x.name);
		const defaultValue = isNullOrUndefined(foundSets[0].default) ? null : foundSets[0].default;

		const cellValueIsDisabled = this.disableOptions(found, cell, row, group, sheet, list);
		if ((!isNullOrUndefined(list) && isNullOrUndefined(cell.value)) || (cellValueIsDisabled && cell.cellState.editable)) {
			// Get the first not disabled option
			const found = list.find(x => !x.disabled);
			if (found) {
				if (!isNullOrUndefined(defaultValue)) {
					if (defaultValue !== 'none')
						cell.updateValue(defaultValue);
				} else {
					cell.updateValue(found.id);
				}

				this.notifySelectionChanged(null);
			} else {
				cell.updateValue(list[0].id);

				this.notifySelectionChanged(null);
			}
		}


		const items = list.map(value => {
			return {
				label:      cell.lookupLabel === 'label' ? value.label : value.labelMin,
				identifier: value.id,
				disabled:   value.disabled
			} as AdvancedDropdownItem;
		});

		const output = {
			search:        {
				searchEndPoint: '',
				hasSearchBar:   items.length > 10
			},
			label:         '',
			values:        [
				{
					data:    items,
					'label': ''
				}
			],
			identifier:    cell.keys,
			default:       defaultValue,
			selectedValue: cell.value
		};


		this.lookupCache = output;
		return output;
	}

	checkForDisabledOptions() {
		if (this.cell.cellUIState.uiType === UiTypes.Select)
			this.advancedDropdown = this.convertToDropdown(this.cell.lookup, this.cell, this.row, this.group, this.sheet);
		// Work arround to detect the changes and load the dropdowns
		setTimeout(() => this.detectChanges(), 10);

	}
}


