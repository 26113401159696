import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Injector, NgZone, OnDestroy,
	ViewChild
}                                                                                                      from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                                                               from '@angular/material/dialog';
import { DashboardBase }                                                                               from '../dashboard-base';
import { AppService }                                                                                  from '@cs/performance-manager/shared';
import { DashboardConfigService }                                                                      from '../dashboard-config.service';
import {
	CsToastManagerService,
	DashboardComponent as CsDashboardComponent,
	DashboardGridData,
	DashboardPanelSettingEventArgs, FilterBarDataSource,
	FilterBarResultParams,
	FilterCompareBarQuery,
	NotifyServerForChangesDashboardPanelEventArgs
}                                                                                                      from '@cs/components';
import { TranslateService }                                                                            from '@ngx-translate/core';
import { SelectionTargetResult }                                                                       from '@cs/core';
import { onDashboardEntryClick }                                                                       from '../dashboard-helpers';
import { Router }                                                                                      from '@angular/router';
import { AppMessageHubService, AppMessageType, AppNavigationService, SafeMethods, WaitingForResponse } from '@cs/common';
import { TabService }                                                                                  from '@cs/performance-manager/tabbed-page';
import { skipUntil, skipWhile, take, takeWhile, tap }                                                  from 'rxjs/operators';


export class DashboardPopupData {
	filterData: FilterBarResultParams;
	panelName: string;
	parentInstanceId: string;
}

@Component({
	selector:        'pmc-dashboard-popup',
	templateUrl:     './dashboard-popup.component.html',
	styles:          [`
						:host {
							display: flex;
							height: auto;
							flex-grow: 1;
							width: 100%;
							max-width: 100%;
						}
										`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardPopupComponent extends DashboardBase implements AfterViewInit {
	hiddenBadges: HTMLDivElement[];

	get hiddenBadgesText(): string {
		return this.hiddenBadges.map(value => value.innerText).join('\n');
	}

	get panelContentTopBar(): ElementRef {
		return this._panelContentTopBar;
	}

	@ViewChild('panelContentTopBar', {static: false, read: ElementRef})
	set panelContentTopBar(value: ElementRef) {
		this._panelContentTopBar = value;
	}

	@ViewChild('dashboard', {static: true}) dashboard: CsDashboardComponent;
	private _panelContentTopBar: ElementRef;

	constructor(@Inject(MAT_DIALOG_DATA) public readonly popupData: DashboardPopupData,
							private injector: Injector,
							readonly matDialog: MatDialogRef<DashboardPopupComponent>,
							readonly dashboardConfigService: DashboardConfigService,
							readonly filterCompareBarQuery: FilterCompareBarQuery,
							readonly appNavigationService: AppNavigationService,
							readonly appService: AppService,
							readonly cdRef: ChangeDetectorRef,
							readonly i8n: TranslateService,
							readonly toastService: CsToastManagerService,
							readonly tabService: TabService,
							readonly appMessageHub: AppMessageHubService,
							readonly router: Router,
							readonly ngZone: NgZone) {
		super(dashboardConfigService, filterCompareBarQuery, appNavigationService,
			appService, cdRef, i8n, toastService, router, tabService, appMessageHub);
		this.setDetailsData(this.popupData.filterData, this.popupData.panelName, result => {
			// when only one panel, show as panel modal
			if (result.value.panels.length === 1)
				this.matDialog.addPanelClass('single-panel-dashboard-dialog');
		});
	}

	close() {
		this.matDialog.close();
	}

	onDashboardEntryClick($event: SelectionTargetResult) {
		onDashboardEntryClick($event, this.injector, this.dashboardConfigService);
	}

	/**
	 * Overide because the default handler add the query params to the url, this is not necessary for the popup
	 * @param args Selected panel option
	 */
	onPanelOptionSelected(args: DashboardPanelSettingEventArgs) {
		const patchedParams = Object.assign({}, args.selectionObject, this.popupData.filterData, {panelSettings: args.panelSettings});

		this.dashboardConfigService.getPanel(this._parentName, patchedParams, args.panelName)
				.pipe(tap(WaitingForResponse.new(isLoading => {
					this.dashboard.panelIsLoading(isLoading, args.panelName);
				})))
				.subscribe(result => {
					const newPanel             = result.value;
					const index                = this.data.panels.findIndex(panel => panel.gridSlot === newPanel.gridSlot);
					const newDashboard         = new DashboardGridData(this.data);
					newDashboard.panels[index] = Object.assign({}, newDashboard.panels[index], newPanel);
					this.data                  = newDashboard;
					SafeMethods.detectChanges(this.cdRef);
				});

	}

	ngAfterViewInit() {
		super.ngAfterViewInit();

		this.fillAvailableSpaceWithPills();

	}

	onNotifyChangesToServerSuccess($event: NotifyServerForChangesDashboardPanelEventArgs<any>) {
		this.appMessageHub.publishMessage(AppMessageType.REFRESH_DATA, this.popupData.parentInstanceId);
	}

	refreshData() {
		this.setDetailsData(this.popupData.filterData, this.popupData.panelName);
	}

	getContentWidth(element) {
		const styles = getComputedStyle(element);

		return element.clientWidth
			- parseFloat(styles.paddingLeft)
			- parseFloat(styles.paddingRight);
	}

	fillAvailableSpaceWithPills() {

		this.ngZone.onStable.pipe(skipWhile(value => this.panelContentTopBar == null), take(1)).subscribe(value1 => {

			const element        = <HTMLDivElement>this.panelContentTopBar.nativeElement;
			const availableSpace = element.offsetWidth - 70;

			const foundBadges    = element.querySelectorAll('.badge');
			let totalWidth       = 0;
			const _visibleBadges = [];
			const _hiddenBadges  = [];

			foundBadges.forEach((elementBadge: HTMLDivElement) => {

				totalWidth += elementBadge.offsetWidth;

				if (totalWidth > availableSpace) {
					_hiddenBadges.push(elementBadge);
					elementBadge.style.display = 'none';
				} else
					_visibleBadges.push(elementBadge);
			});

			this.hiddenBadges = _hiddenBadges;

			SafeMethods.detectChanges(this.cdRef);
		});


	}


}
