import { isNull, isNullOrUndefined } from './determine.utils';
import { HttpResponse }              from '@angular/common/http';
import { saveAs }                    from 'file-saver';

export function findByIdObject<T>(
  array,
  searchObj,
  property: string = null,
  removeWhenFound  = false
): T {
  let output: T     = null;
  const searchClone = searchObj;
  for (let index = 0; index < array.length; index++) {
    const aItem = array[index];
    const sItem = !isNull(property) ? aItem[property] : aItem;
    const item  = sItem;
    let isSame  = true;
    for (const sKey of Object.keys(searchClone)) {
      if (searchClone[sKey] !== item[sKey]) {
        isSame = false;
        break;
      }
    }
    if (isSame) {
      output = aItem;
      if (removeWhenFound) array.splice(index, 1);
    }
  }
  return output;
}

export function filterByIdObject<T>(
  array: T[],
  searchObj,
  property: keyof T         = null,
  filter: 'all' | 'partial' = 'all'
) {
  const output      = [];
  const searchClone = searchObj;
  for (const aItem of array) {
    const sItem = !isNull(property) ? aItem[property] : aItem;
    const item  = sItem;
    let isSame  = true;

    const search  = filter === 'all' ? searchClone : item;
    const compare = filter === 'all' ? item : searchClone;
    for (const sKey of Object.keys(search)) {
      if (search[sKey] !== compare[sKey]) {
        isSame = false;
        break;
      }
    }
    if (isSame) {
      output.push(aItem);
    }
  }
  return output;
}

export class FileUtils {
  static downloadFile(response: HttpResponse<Blob>) {
    // Request BLOB & Handle documented response codes (in reusable method)
    const contentDisposition: string = response.headers.get('Content-Disposition');
    // In case there's no Content-Disposition header. Won't work for non excels
    let filename                     = 'data.xlsx';
    if (!isNullOrUndefined(contentDisposition)) {
      // Extract filename. Can be with- or without quotes and terminating semi-colon.
      const regexp = /filename="?([^;"]*)"?;?/;
      if (!isNullOrUndefined(contentDisposition.match(regexp)))
        filename = contentDisposition.match(regexp)[1];
    }
    const contentType = response.headers.get('Content-type'); // TODO: MIME-TYPE???
    const blob        = new Blob([response.body], {type: contentType});
    saveAs(blob, filename);
  }
}

export class ArrayUtils {
  static groupBy<T>(property: keyof T, array: T[]): { [key: string]: T[] } {
    const filters = (groups: any, item) => {
      const val   = item[property];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    };
    return array.reduce(filters, {});
  }

  static isEqual(value, other) {

    // Get the value type
    const type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    const compare = (item1, item2) => {

      // Get the object type
      const itemType = Object.prototype.toString.call(item1);

      // If an object or array, compare recursively
      if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!ArrayUtils.isEqual(item1, item2)) return false;
      } else {

        // If the two items are not the same type, return false
        if (itemType !== Object.prototype.toString.call(item2)) return false;

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) return false;
        } else {
          if (item1 !== item2) return false;
        }

      }
    };

    // Compare properties
    if (type === '[object Array]') {
      for (let i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) return false;
        }
      }
    }

    // If nothing failed, return true
    return true;

  }
  //
  // static zipObject(props, values) {
  //   return props.reduce(function (prev, prop, i) {
  //     return Object.assign(prev, {[prop]: values[i]});
  //   }, {});
  // }

}


