import {
  Component, ElementRef, EventEmitter, Inject, Injector,
  Input, OnChanges, OnInit, Optional, Output, Type, ViewChild
}                                           from '@angular/core';
import { IButton }                          from '@cs/components';
import { isNullOrUndefined }                from '@cs/core';
import { DynamicButton }                    from './models/dynamic-button';
import {
  DynamicButtonRegistration,
  DynamicButtonsAgent
}                                           from './dynamic-button-bar.agent';
import { DynamicButtonBarClickedEventArgs } from './dynamic-button-bar.event-args';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector:    'pmc-dynamic-button-bar',
  templateUrl: './dynamic-button-bar.component.html',
  styleUrls:   ['./dynamic-button-bar.component.scss']
})
export class PmDynamicButtonBarComponent implements OnInit, OnChanges {

  @ViewChild('fileinput') fileInputElement: ElementRef;

  /**
   * String that contains the title of the button bar.
   */
  @Input() title: string;
  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() dynamicButtons: Array<IButton>;

  /**
   * Event that is triggered when an dynamic button is clicked.
   */
  @Output() clicked: EventEmitter<DynamicButtonBarClickedEventArgs> = new EventEmitter<DynamicButtonBarClickedEventArgs>();

  /**
   * Don't show the events button
   */
  @Input() hideEventsButton = true;

  /**
   * Collapse the bar so it doesn't take any space when there is nothing to show
   */
  @Input() collapseWhenEmpty = true;

  /**
   * Don't show the collapse button
   */
  @Input() hideCollapseButton = false;

  /**
   * Number of unconfirmed Events
   */
  @Input() unconfirmedEvents = 0;

  /**
   * Show an alertMessage
   */
  @Input() alertMessage: string;

  /**
   * When using the {@link DynamicButtonsAgent} the Host instance should be provided.
   * So it can be passed on to the {@link IDynamicButtonState.executeTransition} method
   */
  @Input() currentPageComponent: Type<unknown>;

  registeredDynamicButtons: DynamicButtonRegistration[];


  constructor(@Optional() @Inject(DynamicButtonsAgent)
              private dynamicPageButtonAgent: DynamicButtonsAgent,
              private injector: Injector,
              private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {

    if (changes.hasOwnProperty('dynamicButtons')) {
      if (!isNullOrUndefined(this.dynamicButtons)) {
        this.registeredDynamicButtons = [
          ...this.dynamicButtons.map(x => {
            const btn = new DynamicButton(x);
            return this.dynamicPageButtonAgent.createServerProvidedButton(btn, this.dialog);
          }), ...this.dynamicPageButtonAgent.getButtons()];
      }
    }
  }

  dynamicButtonClicked(dynamicButton: DynamicButtonRegistration, event: any) {

    let files = null;
    if (!isNullOrUndefined(event))
      files = event.target.files as FileList;

    // Detect if the component uses the button agent
    if (this.dynamicPageButtonAgent)
      this.dynamicPageButtonAgent.goNewState(dynamicButton, this.injector, this.currentPageComponent, files);
    else {
      // fallback to use the default Angular output emitter
      const eventArgs = new DynamicButtonBarClickedEventArgs(dynamicButton, files);
      this.clicked.emit(eventArgs);
    }

    this.clearInputElementValue();
  }

  /**
   * Clear the Input type=file element, to allow uploading of the same file
   */
  clearInputElementValue() {
    if (this.fileInputElement != null)
      this.fileInputElement.nativeElement.value = '';
  }

  isVisible(dynamicButton: DynamicButtonRegistration) {
    if (dynamicButton.getCurrentState().hasOwnProperty('isVisible'))
      return dynamicButton.getCurrentState().isVisible(dynamicButton, this.injector, this.currentPageComponent);
    else
      return true;
  }
}
