import { Injector, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule }                                  from '@angular/common';

import { DataEntryRoutingModule }                      from './data-entry-routing.module';
import { DataEntryComponent }                          from './data-entry.component';
import { CsApplicationSettings, PmDetailPanelModule }  from '@cs/performance-manager/shared';
import { DataEntryConfigService }                      from './data-entry-config.service';
import { HttpClient }                                  from '@angular/common/http';
import { IConfigService }                              from '@cs/performance-manager/shared';
import { CsTranslationLoader }                         from '@cs/performance-manager/shared';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService
}                                                      from '@ngx-translate/core';
import { LoggerUtil }                                  from '@cs/core';
import { PmDynamicButtonBarModule, DialogBasicModule } from '@cs/performance-manager/shared';

import { ComponentTranslationLoaderService }     from '@cs/common';
import { CsChartPanelModule }                    from './components/pm-chart-panel/chart-panel.module';
import { DefaultDataEntryAgent, DataEntryAgent } from './agents/data-entry.agent';
import { PmPopoversModule }                      from './components/popovers/popovers.module';
import {
	CellClickedHandlerService,
	DefaultCellClickedHandlerService
}                                                from './handlers/cell-clicked-handler.service';
import { DataEntryDynamicPageButtonsAgent }      from './handlers/data-entry-dynamic-button-bar';
import { DynamicButtonsAgent }                   from '@cs/performance-manager/shared';
import { DataGridAddMemberDialogModule }         from './components/data-grid-add-member-dialog/data-grid-add-member-dialog.module';

import { PMAuditTrailModule }          from '@cs/performance-manager/audit-trail';
import { CsDataGridModule }            from '@cs/components/data-grid';
import { CsScrollPageModule }          from '@cs/components/shared';
import { CsFilterAndCompareBarModule } from '@cs/components/filter-and-compare-bar';


export function dataEntryTranslationLoader(client: HttpClient,
																					 pmAppSettings: CsApplicationSettings,
																					 service: IConfigService,
																					 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
		client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}


@NgModule({
	declarations: [DataEntryComponent],
	imports:      [
		CommonModule,
		PmDynamicButtonBarModule,
		CsDataGridModule,
		CsChartPanelModule,
		PmDetailPanelModule,
		DialogBasicModule,
		PmPopoversModule,
		CsScrollPageModule,
		DataGridAddMemberDialogModule,
		PMAuditTrailModule,
		CsFilterAndCompareBarModule,
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: dataEntryTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, DataEntryConfigService, ComponentTranslationLoaderService]
			},
			isolate: true
		}),
		DataEntryRoutingModule
	]
})
export class PmDataEntryModule {
	static forRoot(moduleConfig: Type<DataEntryConfigService>,
								 appSetting: CsApplicationSettings,
								 dynamicPageButtons: Type<DynamicButtonsAgent>       = DataEntryDynamicPageButtonsAgent,
								 dataEntryAgent: Type<DataEntryAgent>                = DefaultDataEntryAgent,
								 cellClickedHandler: Type<CellClickedHandlerService> = DefaultCellClickedHandlerService
	): ModuleWithProviders<PmDataEntryModule> {
		return {
			ngModule:  PmDataEntryModule,
			providers: [
				{
					provide:  DataEntryConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				},
				{
					provide:  DataEntryAgent,
					useClass: dataEntryAgent
				},
				{
					provide:  DynamicButtonsAgent,
					useClass: dynamicPageButtons
				},
				{
					provide:  CellClickedHandlerService,
					useClass: cellClickedHandler,
					deps:     [Injector]
				}
			]
		};
	}

	constructor(translate: TranslateService) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
		translate.setDefaultLang('en');

		// the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
		translate.use('en');
	}
}
