import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
	ViewChild
}                                                            from '@angular/core';
import {
	CsDataGrid,
	CsDataGridPopoversService,
	DataGridCellType, dimensionTreeToFilterNavbar, FilterBarDataSource, FilterBarResultParams,
	FilterBarResultParamsSelection,
	FilterCompareBarQuery,
	FilterCompareBarService, FilterSelectionChangedEventArgs,
	IInitData
}                                                            from '@cs/components';
import { DataEntryConfigService }                            from './data-entry-config.service';
import {
	UntilDestroy,
	untilDestroyed
}                                                            from '@ngneat/until-destroy';
import { filter }                                            from 'rxjs/operators';
import { FastZipObject, isNullOrUndefined }                  from '@cs/core/utils';
import { DataEntryResultParams }                             from './models/data-entry-result-params';
import { DataGridWorker }                                    from './agents/data-grid.worker';
import { CsChartPanelComponent }                             from './components/pm-chart-panel/chart-panel.component';
import { BottomBarService }                                  from '@cs/performance-manager/shared';
import { ChartPanelAgent }                                   from './agents/chart-panel.agent';
import { DataEntryStateQuery }                               from './state/data-entry-state.query';
import { Observable, Subscription }                          from 'rxjs';
import { DataEntryStateService }                             from './state/data-entry-state.service';
import {
	flattenObject, LoggerUtil,
	restoreFlattenObject,
	generateQuickGuid, TableDataDescribed
}                                                            from '@cs/core';
import { AppNavigationService, opacityAndBlur, SafeMethods } from '@cs/common';
import { DataEntryAgent }                                    from './agents/data-entry.agent';
import { FilterNavbarLoadedComponent }                       from '@cs/performance-manager/filter-and-navbar-shell';
import { AuditTrailStateQuery }                              from '@cs/performance-manager/audit-trail';
import { AuditTrailChangesComponent }                        from '@cs/performance-manager/audit-trail';
import { TranslateService }                                  from '@ngx-translate/core';
import { AuditTrailStateService }                            from '@cs/performance-manager/audit-trail';
import { ContainerSettings }                                 from '@cs/performance-manager/shared';
import { IDataSource }                                       from './components/statistics-panel/interfaces/interfaces';
import { ActivatedRoute, Router }                            from '@angular/router';
import { DataGridUtils }                                     from './agents/data-grid.utils';


@UntilDestroy()
@Component({
	selector:        'pmc-data-entry',
	templateUrl:     './data-entry.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations:      [
		opacityAndBlur('loadingPanelState')
	]
})
export class DataEntryComponent extends ContainerSettings implements FilterNavbarLoadedComponent, OnInit, AfterViewInit, OnDestroy {

	/**
	 * Angular instance of the grid on this page
	 */
	@ViewChild('data')
	grid: CsDataGrid;

	/**
	 * Angular instance of the chartpanel on this page
	 */
	@ViewChild('chartPanel')
	private chartPanel: CsChartPanelComponent;

	/**
	 * Angular instance of the audit trail changes component
	 */
	@ViewChild('auditChanges')
	private auditChanges: AuditTrailChangesComponent;

	/**
	 * The agent that handles the functionality for the chart panel
	 */
	chartPanelAgent: ChartPanelAgent;
	/**
	 * The agent that handles the data entry grid actions
	 */
	dataEntryGrid: DataGridWorker;
	/**
	 * Flag indicating when to show or collapse the chart
	 */
	showChart$: Observable<boolean>;
	/**
	 * Loading flag. Will show a loader in the statistic panel
	 */
	isStatisticsPanelLoading$: Observable<boolean>;
	/**
	 * Loading flag. Will show a loader in the chart panel
	 */
	isChartPanelLoading$: Observable<boolean>;
	/**
	 * Loading flag. Will show a blurred data grid
	 */
	isLoadingDataGrids$: Observable<boolean>;
	/**
	 * Flag if the end of page scroll event should fire
	 */
	enableScrollDetection$: Observable<boolean>;
	/**
	 * Flag indicating when to show the panel containing audit trail changes
	 */
	isChangesPanelVisible$: Observable<boolean>;
	/**
	 * Flag loading new content
	 */
	isLoadingNewContent$: Observable<boolean>;
	/**
	 * unique id for the page
	 */
	private ticket: string                                          = generateQuickGuid();
	/**
	 * Current request handeling a navbar update
	 */
	currentRequest: Subscription;
	/**
	 * Datasource for the subfilter navbar
	 */
	subFilterDataSource: FilterBarDataSource<FilterBarResultParams> = null;


	constructor(public readonly config: DataEntryConfigService,
							public readonly dataEntryAgent: DataEntryAgent,
							private readonly i8n: TranslateService,
							private readonly router: Router,
							private readonly activeRoute: ActivatedRoute,
							private dataEntryStateQuery: DataEntryStateQuery,
							private dataEntryStateService: DataEntryStateService,
							private filterCompareBarQuery: FilterCompareBarQuery,
							private filterCompareBarService: FilterCompareBarService,
							private auditTrailStateQuery: AuditTrailStateQuery,
							private auditTrailStateService: AuditTrailStateService,
							private bottomBarService: BottomBarService,
							private csDataGridPopoversService: CsDataGridPopoversService,
							private appNavigationService: AppNavigationService,
							private injector: Injector,
							private changeRef: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		// Setup show chartpanel here because of not rendering the chartpanel on change when added to the ChartPanelAgent
		this.showChart$                = this.dataEntryStateQuery.select(store => store.showChart);
		this.isStatisticsPanelLoading$ = this.dataEntryStateQuery.select(store => store.isStatisticsPanelLoading);
		this.isChartPanelLoading$      = this.dataEntryStateQuery.select(store => store.isChartPanelLoading);
		this.isLoadingDataGrids$       = this.dataEntryStateQuery.select(store => store.isDataGridsLoading);
		this.isLoadingNewContent$      = this.dataEntryStateQuery.select(store => store.isLoadingNewContent);
		this.enableScrollDetection$    = this.dataEntryStateQuery.select(store => store.enableScrollDetection);
		this.isChangesPanelVisible$    = this.auditTrailStateQuery.select(state => state.isChangesPanelVisible);

		this.appNavigationService.registerForStoppingNavigationChanges(this.ticket, () => {
			const hasChangedCells = this.dataEntryGrid.getChangedCells();
			return hasChangedCells === 0 || confirm(this.i8n.instant('NAVIGATE'));
		});

	}

	private getAllMembers() {
		const obs = new Observable<boolean>(observer => {

			// Check if alredy loaded
			if (!isNullOrUndefined(window['members'])) {
				observer.next(true);
				observer.complete();
			} else {
				window['members'] = {};
				let common        = {};

				// get the latest allMembers and update the local version
				this.config.getCommonMembers().subscribe((result) => {

					common = this.prepareLevelMembers(result.value);

					window['members'] = common;

					this.config.getExtraMembers().subscribe((resultExtra) => {

						window['members'] = Object.assign(common, this.prepareLevelMembers(resultExtra.value));

					});

					observer.next(true);
					observer.complete();
				});


			}

		});

		return obs;
	}

	ngAfterViewInit(): void {
		this.getAllMembers().subscribe(value => {
			this.filterCompareBarService.filterbarSelectionChanged.pipe(
				untilDestroyed(this)
			).subscribe(value => {
				this.bottomBarService.registerChange(0);
				this.bottomBarService.toggleBottomBar(false);
				this.dataEntryStateService.update({
					isStatisticsPanelLoading: true,
					isChartPanelLoading:      true,
					isDataGridsLoading:       true
				});
			});

			this.filterCompareBarQuery.select(store => store.comparebarResultParams)
					.pipe(
						untilDestroyed(this)
					).subscribe(value => {
				if (value) {
					this.applyCompareDataToDataGrid(value as DataEntryResultParams);
				}
			});
			this.filterCompareBarService.toggleCompareBarComplete.subscribe(value => {
				if (!value && !isNullOrUndefined(this.dataEntryGrid)) {
					this.dataEntryGrid.cleanCompare();
				}
			});

			this.bottomBarService.onButtonClicked.pipe(untilDestroyed(this)).subscribe(button => {
				this.dataEntryAgent.bottomBarButtonClicked(button, this);
			});

			this.filterCompareBarQuery.select(store => store.mainbarResultParams)
					.pipe(
						untilDestroyed(this),
						filter(value => !isNullOrUndefined(value))
					)
					.subscribe((value: DataEntryResultParams) => {
						this.renderDataGrid(value, Object.assign({}, value, {selection: undefined}));
					});

			// update audit trail details when they are available
			this.auditTrailStateQuery.select(state => state.changesData).pipe(
				untilDestroyed(this)
			).subscribe(value => {
				// component is only shown when need, check if exists
				if (isNullOrUndefined(this.auditChanges))
					return;

				this.auditChanges.metaData = new TableDataDescribed<[]>(value.meta);
				this.auditChanges.data     = new TableDataDescribed<[]>(value.data);
			});
		});

	}

	ngOnDestroy(): void {
		this.appNavigationService.unregisterForStoppingNavigationChanges(this.ticket);
	}


	renderDataGrid(value: DataEntryResultParams, params = {}, subFilterRequest: boolean = false) {

		if (isNullOrUndefined(value.dataEntryGrid) || value.dataEntryGrid === '')
			return;

		if (!isNullOrUndefined(this.currentRequest) && !this.currentRequest.closed)
			this.currentRequest.unsubscribe();

		params = this.patchParamsWithDataGridSubFilters(this.subFilterDataSource, params);

		this.currentRequest = this.config.getInitBundle(value.dataEntryGrid, value.selection, params)
															.pipe(untilDestroyed(this))
															.subscribe(result => {
																const initBundle = result.value;

																if (initBundle.structureData.dimensionTrees.filters
																	&& initBundle.structureData.dimensionTrees.filters.length > 0) {
																	const value1 = dimensionTreeToFilterNavbar(initBundle.structureData.dimensionTrees.filters, false);

																	this.subFilterDataSource = value1;
																	this.dataEntryStateService.setDataGridSubFilter(this.subFilterDataSource.resultParams);
																	this.patchBaseKeysWithSubfilterKeys(this.subFilterDataSource.resultParams, initBundle);

																	this.router.navigate([], {
																		queryParamsHandling: 'merge',
																		queryParams:         flattenObject({gridFilterBar: value1.resultParams})
																	});

																} else {
																	this.subFilterDataSource = null;
																	this.router.navigate([], {
																		queryParamsHandling: 'merge',
																		queryParams:         Object.keys(this.activeRoute.snapshot.queryParams)
																															 .reduce((previousValue, currentValue) => {
																																 const gridFilter = previousValue;
																																 // if the key starts with gridfilter remove it
																																 if (currentValue.startsWith('gridFilterBar')) {
																																	 gridFilter[currentValue] = undefined;
																																 }

																																 return gridFilter;
																															 }, {})
																	});
																}

																if (!subFilterRequest)
																	this.updateStatsAndChart(initBundle, value);

																if (!isNullOrUndefined(value.dataEntryGrids)) {
																	LoggerUtil.error('This page has multiple dataentygrids, to support this you need to add a custom module');
																}

																this.dataEntryGrid = new DataGridWorker(value.dataEntryGrid, this.grid, this.injector);

																this.dataEntryStateService.update({
																	isDataGridsLoading: true
																});

																this.dataEntryGrid.updateData(initBundle)
																		.then(value1 => {
																			this.dataEntryStateService.update({
																				isDataGridsLoading: false
																			});
																			this.grid.updateCells(DataGridCellType.All);
																			this.changeRef.detectChanges();
																		});

															});
	}

	private patchParamsWithDataGridSubFilters(subFilterGrid: FilterBarDataSource<any>, params: {}) {
		if (subFilterGrid == null) {
			let subFilterGridSelection;
			// Check in the query params if the subfilter has selected values
			const restoredParams = restoreFlattenObject(this.activeRoute.snapshot.queryParams) as { gridFilterBar: { [key: string]: any } };
			if (restoredParams.gridFilterBar) {
				subFilterGridSelection = restoredParams.gridFilterBar;
				this.dataEntryStateService.setDataGridSubFilter(subFilterGridSelection);

				return Object.assign({}, params, {filters: DataGridUtils.convertToSelectionKey(subFilterGridSelection)});
			}
			return params;
		} else {

			const subFilterGridSelection = subFilterGrid.resultParams;

			this.dataEntryStateService.setDataGridSubFilter({});
			return Object.assign({}, params, {filters: DataGridUtils.convertToSelectionKey(subFilterGridSelection)});
		}
	}

	private applyCompareDataToDataGrid(value: DataEntryResultParams) {

		if (isNullOrUndefined(value.dataEntryGrid) || value.dataEntryGrid === '')
			return;

		this.dataEntryStateService.update({
			isStatisticsPanelLoading: true
		});

		this.config.getInitBundle(value.dataEntryGrid, value.selection, Object.assign({}, value, {selection: undefined}))
				.subscribe(result => {

					const initBundle = result.value;

					const mainbarSelection = this.filterCompareBarQuery.getValue().mainbarResultParams.selection;
					this.updateStats(mainbarSelection, value);
					if (!isNullOrUndefined(value.dataEntryGrids)) {
						LoggerUtil.error('This page has multiple dataentygrids, to support this you need to add a custom module');
					}

					this.dataEntryGrid.updateCompareData(initBundle).then(value1 => {
						this.dataEntryStateService.update({
							isDataGridsLoading:  false,
							isChartPanelLoading: false
						});
					});
				});

	}

	refreshAllData() {
		const mainbarSelection = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
		this.renderDataGrid(mainbarSelection, Object.assign({}, mainbarSelection, {selection: undefined}));
	}

	updateStats(mainbarSelection: FilterBarResultParamsSelection = null, params: DataEntryResultParams = null) {

		if (mainbarSelection === null)
			mainbarSelection = this.filterCompareBarQuery.getValue().mainbarResultParams.selection;

		if (params === null)
			params = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;


		if (ChartPanelAgent.canShowChart(this.dataEntryGrid.data)) {

			if (isNullOrUndefined(this.chartPanelAgent)) {
				LoggerUtil.error('No chart agent defined, but should have one according to the main init bundle');
				return;
			}

			this.chartPanelAgent.getStatistics(mainbarSelection, params.dataEntryGrid, params.selection)
					.subscribe(value1 => {
						this.dataEntryStateService.update({
							isStatisticsPanelLoading: false
						});

						this.changeRef.detectChanges();
					});
		}
	}

	updateStatsAndChart(initBundle: IInitData = null, params: DataEntryResultParams = null) {

		if (initBundle === null)
			initBundle = this.dataEntryGrid.data;

		if (params === null)
			params = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;

		if (ChartPanelAgent.canShowChart(initBundle)) {

			if (isNullOrUndefined(this.chartPanelAgent))
				this.chartPanelAgent = new ChartPanelAgent(this.chartPanel, this.injector);

			this.dataEntryStateService.showChartPanel();

			this.chartPanelAgent.initChart(initBundle, initBundle.config.structure.chartSeries.native.selection)
					.subscribe(value1 => {
						this.dataEntryStateService.update({
							isChartPanelLoading: false
						});

						this.changeRef.detectChanges();
					});

			this.chartPanelAgent.getStatistics(params.selection, params.dataEntryGrid)
					.subscribe(value1 => {
						this.dataEntryStateService.update({
							isStatisticsPanelLoading: false
						});

						this.changeRef.detectChanges();
					});

		} else {
			this.dataEntryStateService.disableChartPanel();
		}
	}

	detectChanges() {
		SafeMethods.detectChanges(this.changeRef);
	}

	/**
	 * Closes / hides the panel
	 */
	hideChangesPanel() {
		this.auditTrailStateService.closeDetailsPanel();
	}

	statisticsChanged($event: IDataSource) {
		if ($event.type !== 'AuditTrailAudits') {
			this.auditTrailStateService.closeDetailsPanel();
		}
	}

	/**
	 * The handler for subfilter selection.
	 * @param $event The selected item
	 */
	subFilterBarSelectionChanged($event: FilterSelectionChangedEventArgs) {
		const mainbarSelection                = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
		// patch the sub filters
		this.subFilterDataSource.resultParams = {...this.subFilterDataSource.resultParams, ...$event.newApiParams};

		this.dataEntryStateService.setDataGridSubFilter(this.subFilterDataSource.resultParams);

		this.dataEntryStateService.update({
			isDataGridsLoading: true
		});

		this.renderDataGrid(mainbarSelection, Object.assign({}, mainbarSelection,
			{selection: undefined}), true);
	}

	patchBaseKeysWithSubfilterKeys(resultParams: FilterBarResultParams, initBundle: IInitData) {
		const filteredObject = {};
		for (const key of Object.keys(resultParams)) {
			const value = resultParams[key];

			// Ignore all values that are 'id' === 0 this is used as filter for 'ALL' items
			// tslint:disable-next-line:triple-equals
			if (value != 0) {
				filteredObject[key] = value;
			}
		}

		Object.assign(initBundle.structureData.baseKeys, filteredObject);
	}

	prepareLevelMembers(data: { [memberKey: string]: Array<any> }) {
		const levelMembers = Object.keys(data);
		const memberResult = {};

		for (const levelMember of levelMembers) {
			const levelMemberData   = data[levelMember] as Array<any>;
			const levelMemberResult = {};
			const headers           = levelMemberData.splice(0, 1)[0];
			const idHeaderIndex     = headers.findIndex(value => value === 'id');

			for (const dataRow of levelMemberData) {
				levelMemberResult[dataRow[idHeaderIndex]] = FastZipObject.createZipObject(headers, dataRow);
			}

			memberResult[levelMember] = levelMemberResult;

		}
		return memberResult;
	}
}


