import { enableProdMode }         from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule }      from './app/app.module';
import { pmAppSettings }  from './environments/environment';
import { Environment }    from '@cs/common';
import { setAppSettings } from '@cs/customer/pages/module.routes';

setAppSettings(pmAppSettings);

if (pmAppSettings.environment === Environment.PRODUCTION) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
