import { NgModule }                  from '@angular/core';
import { SelectOnFocusDirective }    from './directives/select-on-focus.directive';
import { ClickOutsideDirective }     from './directives/click-outside.directive';
import { CsAutoFocusDirective }      from './directives/cs-auto-focus.directive';
import { LookupPipe }                from './pipes/lookup.pipe';
import { LoaderDirective }           from './directives/loader-directive';
import { EscapeHtmlPipe }            from './pipes/html.pipe';
import { CsCultureModule }           from '@cs/common';
import { CsTemplateHandleDirective } from './directives/cs-template-handle.directive';
import { CsTemplateLoaderDirective } from './directives/cs-template-loader.directive';


@NgModule({
		imports:      [CsCultureModule],
		exports:      [EscapeHtmlPipe, LookupPipe, ClickOutsideDirective, CsAutoFocusDirective, LoaderDirective,
									 CsTemplateHandleDirective,
									 CsTemplateLoaderDirective,
									 SelectOnFocusDirective, CsCultureModule],
		declarations: [EscapeHtmlPipe, LookupPipe, ClickOutsideDirective, CsAutoFocusDirective,
									 CsTemplateHandleDirective,
									 CsTemplateLoaderDirective,
									 LoaderDirective, SelectOnFocusDirective]
	}
)
export class CsSharedModule {
}
