import { NgModule, Type, ModuleWithProviders }               from '@angular/core';
import { CommonModule }                                      from '@angular/common';
import { ShellComponent }                                    from './shell.component';
import { ShellConfigService }                                from './shell-config.service';
import { CsApplicationSettings }                             from '@cs/performance-manager/shared';
import { FormsModule }                                       from '@angular/forms';
import {
	TranslateModule,
	TranslateLoader,
	TranslateService
}                                                            from '@ngx-translate/core';
import { LoggerUtil }                                        from '@cs/core';
import { IConfigService }                                    from '@cs/performance-manager/shared';
import { CsTranslationLoader }                               from '@cs/performance-manager/shared';
import { RouterModule }                                      from '@angular/router';
import { PmNavbarModule }                                    from '@cs/performance-manager/shared';
import { HttpClient }                                        from '@angular/common/http';
import { ResourcesResolver }                                 from './resolvers/resource.resolver';
import { ErrorHandlingModule }                               from '@cs/performance-manager/shared';
import { CsToastManagerModule }                              from '@cs/components';
import { PmDeployInfoModule }                                from '@cs/performance-manager/shared';
import { BrandingModule, ComponentTranslationLoaderService } from '@cs/common';
import { MatTooltipModule }                                  from '@angular/material/tooltip';


export function shellTranslationLoader(client: HttpClient,
																			 pmAppSettings: CsApplicationSettings,
																			 service: IConfigService,
																			 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
		client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
	declarations: [
		ShellComponent
	],
	providers:    [
		ResourcesResolver
	],
	imports:      [
		CommonModule,
		FormsModule,
		RouterModule,
		ErrorHandlingModule,
		CsToastManagerModule,
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: shellTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, ShellConfigService, ComponentTranslationLoaderService]
			},
			isolate: true
		}),
		PmNavbarModule,
		PmDeployInfoModule,
		MatTooltipModule
	]
})
export class PmShellModule {
	static forRoot(moduleConfig: Type<ShellConfigService>,
								 appSetting: CsApplicationSettings): ModuleWithProviders<PmShellModule> {
		return {
			ngModule:  PmShellModule,
			providers: [
				{
					provide:  ShellConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not setup');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('en');

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use('en');
	}
}
