import { IConfigService }                 from '@cs/performance-manager/shared';
import { PmNavbarSelection }              from '@cs/performance-manager/shared';
import { Observable }                     from 'rxjs';
import { CsHttpRequestOptions, Result }   from '@cs/core';
import { PmNavbarItem, PmNavbarItemType } from '@cs/performance-manager/shared';
import { TranslateService }               from '@ngx-translate/core';
import { AuthenticationService }          from '@cs/performance-manager/shared';
import { AppQuery }                       from '@cs/performance-manager/shared';
import { DeploymentDetails }              from '@cs/performance-manager/shared';
import { PmNavbarBody }                   from '@cs/performance-manager/shared';
import { PmNavbarModuleChangedEventArgs } from '@cs/performance-manager/shared';
import { Route }                          from '@angular/router';


export abstract class ShellConfigService implements IConfigService {

	moduleName = 'shell';

	patchRootPathWIthModule = false;

	enableFooter = true;

	abstract getPoweredByBarItems(): Observable<Result<PmNavbarItem[]>>;

	abstract getNavigationBarItems(currentSelection?: PmNavbarSelection): Observable<Result<PmNavbarBody>>;

	abstract getUserResources(): Observable<Result<{ [p: string]: boolean }>>;

	abstract applicationReset(): Observable<Window>;

	abstract goToAdminWebtop(): Observable<Window>;

	abstract revertToSelf(): Observable<Window>;

	abstract getDeploymentDetails(): Observable<Result<DeploymentDetails>>;

	abstract resolveNewModulePath($event: PmNavbarModuleChangedEventArgs, currentPath: Route[]): string[];

	abstract getBrandingLogo(image: string, queryParams: {}, options: CsHttpRequestOptions);
}


export function defaultPmAvatarItems(i18n: TranslateService,
																		 authenticationService: AuthenticationService,
																		 appStateQuery: AppQuery,
																		 shellConfig: ShellConfigService
) {
	return [
		new PmNavbarItem({
			label:     i18n.instant('ADMINISTRATION_WEBTOP'),
			fullLabel: i18n.instant('ADMINISTRATION_PAGES'),
			icon:      'mdi mdi-settings',
			name:      'ADMINISTRATION_PAGES',
			show:      appStateQuery.select(store => store.canAccessAdmin),
			type:      PmNavbarItemType.APP_ACTION,
			action:    item => {
				shellConfig.goToAdminWebtop();
			}
		}),
		new PmNavbarItem({
			label:     i18n.instant('REVERT_TO_SELF'),
			fullLabel: i18n.instant('REVERT_TO_SELF_DESCR'),
			icon:      'mdi mdi-refresh',
			name:      'REVERT_TO_SELF',
			action:    item => {
				shellConfig.revertToSelf();
			}
		}),
		new PmNavbarItem({
			label:     i18n.instant('DEBUG_TOOLS'),
			fullLabel: i18n.instant('DEBUG_TOOLS_DESCR'),
			icon:      'mdi mdi-widgets',
			name:      'DEBUG_TOOLS',
			values:    [
				new PmNavbarItem({
					label:     i18n.instant('CHANGELOG'),
					fullLabel: i18n.instant('CHANGELOG_DESCR'),
					icon:      'mdi mdi-radar',
					name:      'CHANGELOG'
				}),
				new PmNavbarItem({
					label:     i18n.instant('APPLICATION_RESET'),
					fullLabel: i18n.instant('APPLICATION_RESET_DESCR'),
					icon:      'mdi mdi-application',
					name:      'APPLICATION_RESET',
					type:      PmNavbarItemType.APP_ACTION,
					action:    item => {
						shellConfig.applicationReset();
					}
				}),
				new PmNavbarItem({
					label:     i18n.instant('REFRESH_PAGE'),
					fullLabel: i18n.instant('REFRESH_PAGE_DESCR'),
					icon:      'mdi mdi-refresh',
					name:      'REFRESH_PAGE',
					type:      PmNavbarItemType.APP_ACTION,
					action:    item => {
						document.location.reload(true);
					}
				})
			],
			show:      appStateQuery.select(store => store.isDebugUser)
		}),
		new PmNavbarItem({
			label:             i18n.instant('MANAGED_BY_ME'),
			fullLabel:         i18n.instant('MANAGED_BY_ME_DESCR'),
			icon:              'mdi mdi-briefcase',
			resolveFromModule: 'user',
			name:              'MANAGED_BY_ME'
		}),
		new PmNavbarItem({
			label:             i18n.instant('USER_PROFILE'),
			fullLabel:         i18n.instant('USER_PROFILE_DESCR'),
			resolveFromModule: 'user',
			icon:              'mdi mdi-account',
			path:              'profile',
			name:              'USER_PROFILE'
		}),
		new PmNavbarItem({
			label:     i18n.instant('LOGOUT'),
			fullLabel: i18n.instant('LOGOUT_DESCR'),
			icon:      'mdi mdi-logout',
			name:      'LOGOUT',
			type:      PmNavbarItemType.APP_ACTION,
			action:    item => {
				authenticationService.logOut();
			}
		})
	];
}
