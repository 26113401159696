import { Injectable, Inject, OnDestroy }            from '@angular/core';
import { AuditTrailStateStore }                     from './audit-trail-state.store';
import { AuditTrailConfigService }                  from '../audit-trail-config.service';
import { AuditTrailItemClickedEventArgs }           from '../components/audit-trail-audits/audit-trail-audits.model';
import { DataDescribedUtil, FilterCompareBarQuery } from '@cs/components';
import { isNullOrUndefined, ArrayUtils }            from '@cs/core/utils';
import { AuditTrailStateQuery }                     from './audit-trail-state.query';

@Injectable({providedIn: 'root'})
export class AuditTrailStateService implements OnDestroy {
	previousSelection: any;

	constructor(@Inject(AuditTrailConfigService) private auditTrailService: AuditTrailConfigService,
							@Inject(AuditTrailStateStore) private store: AuditTrailStateStore,
							@Inject(AuditTrailStateQuery) private query: AuditTrailStateQuery,
							@Inject(FilterCompareBarQuery) private filterBarQuery: FilterCompareBarQuery) {
	}

	/**
	 * Handle AfterViewInit of AuditsComponent.
	 */
	onAfterAuditsComponentViewInit() {
		// sets flag to indicator we have something to render data.
		this.store.update({isAuditPanelVisible: true});

		this.onFactTableNameChanged(this.query.getValue().factTableName);
	}

	/**
	 * Handle FactTableName changes. Updates store with new value.
	 */
	onFactTableNameChanged(factTableName: string) {

		const mainbarResultParams = this.filterBarQuery.getValue().mainbarResultParams;
		if (isNullOrUndefined(factTableName) && isNullOrUndefined(mainbarResultParams))
			return;

		this.store.update({factTableName: factTableName});
		// When navigating away hide the changes panel when it's open, so compare without selection to detect real navigation
		if (this.query.getValue().isChangesPanelVisible
			&& !ArrayUtils.isEqual(
				{...mainbarResultParams, ...{selection: null}},
				{...this.previousSelection, ...{selection: null}})) {
			this.store.update({isChangesPanelVisible: false});
		}

		this.previousSelection = mainbarResultParams;

		// Is the Audit panel is in view, update it
		if (this.query.getValue().isAuditPanelVisible)
			this.refreshAuditsData();

	}

	/**
	 * Handle Audit Item clicks. Get and propagate the changes associated with the audit item.
	 */
	auditItemClicked(event: AuditTrailItemClickedEventArgs) {
		const factTableName = this.getFactTableName();
		if (isNullOrUndefined(factTableName))
			return;

		this.store.update({
			isChangesPanelVisible: true,
			isChangesPanelLoading: true
		});
		// patch with navbar selection
		const selection = {...this.getSelection(), ...event.selection};
		this.auditTrailService.getAuditTrailChanges(factTableName, selection).subscribe(result => {

			// deep copy result value, as store is immutable (deepfreeze) and we want to patch the data
			const changesData = JSON.parse(JSON.stringify(result.value));

			// patch the lookups for DataDescribe to use AllMembers
			changesData.meta.lookups = [...result.value.meta.lookups, ...DataDescribedUtil.GetAllMemberLookupLists(result.value.meta)];
			changesData.data.lookups = [...result.value.data.lookups, ...DataDescribedUtil.GetAllMemberLookupLists(result.value.data)];

			// update the store
			this.store.update({
				changesData:           changesData,
				isChangesPanelLoading: false,
				lastChangesSelection:  event.selection
			});
		});
	}


	/**
	 * Get the data for the audits panel
	 */
	refreshAuditsData() {
		const factTableName = this.getFactTableName();
		if (isNullOrUndefined(factTableName))
			return;

		const selection = this.getSelection();
		if (isNullOrUndefined(selection))
			return;

		this.store.update({isAuditPanelLoading: true});
		this.auditTrailService.getAuditTrailAudits(factTableName, selection).subscribe(result => {
			this.store.update({
				auditsData:          result.value,
				isAuditPanelLoading: false
			});
			if (!this.isCurrentChangesInAudits()) {
				// reset audit changes data and state
				this.store.update({
					changesData:           {meta: null, data: null},
					isChangesPanelLoading: false,
					isChangesPanelVisible: false
				});
			}
		});
	}

	/**
	 * Returns selection from the current filter bar
	 */
	private getSelection(): { [p: string]: string | number } {
		const params = this.filterBarQuery.getValue().mainbarResultParams;
		if (isNullOrUndefined(params) || !params.hasOwnProperty('selection'))
			return null;
		return params.selection;
	}

	/**
	 * Returns current factFactTableName
	 */
	private getFactTableName(): string {
		return this.query.getValue().factTableName;
	}

	/**
	 * Returns true if the selection used to get the Changes is present in the updated Audits set.
	 */
	private isCurrentChangesInAudits() {
		const lastSelection = this.query.getValue().lastChangesSelection;

		const found = this.query.getValue().auditsData.items.findIndex((item) => {
			return ArrayUtils.isEqual(item.selection, lastSelection);
		});

		return found > -1;
	}

	ngOnDestroy() {

	}

	/**
	 * Closes/ hides the changespanel
	 */
	closeDetailsPanel() {
		this.store.update({isChangesPanelVisible: false});
	}
}
