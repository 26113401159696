import {
	SelectionTargetEnum,
	SelectionTargetResult
}                                                                                from '@cs/core/generate';
import {
	isEmptyObject,
	LoggerUtil,
	PlaceholderRegexPathUrlVariable,
	replacePlaceholders,
	restoreFlattenObject
}                                                                                from '@cs/core/utils';
import {
	CsHttpRequestOptions,
	GenericHttpClientService,
	GenericHttpRequest,
	GenericHttpRequestOptionAction,
	HTTP_METHOD
}                                                                                from '@cs/core/http';
import { Injector }                                                              from '@angular/core';
import { MatDialog }                                                             from '@angular/material/dialog';
import {
	DashboardPopupComponent,
	DashboardPopupData
}                                                                                from './dashboard-popup/dashboard-popup.component';
import { FilterBarResultParams, FilterCompareBarQuery, FilterCompareBarService } from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                                                 from '@cs/components/toast-manager';
import { DashboardConfigService }                                                from './dashboard-config.service';
import { ActivatedRoute, Router }                                                from '@angular/router';
import { AppService }                                                            from '@cs/performance-manager/shared';
import { TranslateService }                                                      from '@ngx-translate/core';
import { Observable }                                                            from 'rxjs/internal/Observable';
import { HttpResponse }                                                          from '@angular/common/http';
import { DialogType }                                                            from '@cs/performance-manager/shared';
import { DialogBasicComponent }                                                  from '@cs/performance-manager/shared';
import { AppMessageHubService, AppMessageType }                                  from '@cs/common';

export function getPanelSettings(route: ActivatedRoute) {
	const params   = route.snapshot.queryParams || {};
	const restored = restoreFlattenObject(params);

	if (restored.hasOwnProperty('panelSettings')) {
		return {panelSettings: restored.panelSettings};
	}
	return {};
}

export async function onDashboardEntryClick($event: SelectionTargetResult, injector: Injector, config: DashboardConfigService) {
	if ($event.selectionAction == null) {
		LoggerUtil.error('No action provided', true);
		return;
	}
	const dialog                  = injector.get(MatDialog);
	const filterCompareBarService = injector.get(FilterCompareBarService);
	const router                  = injector.get(Router);
	const navbar                  = injector.get(FilterCompareBarQuery);
	const appService              = injector.get(AppService);
	const toastManagerService     = injector.get(CsToastManagerService);
	const i8n                     = injector.get(TranslateService);
	const appMessageHub           = injector.get(AppMessageHubService);
	const activatedRoute          = injector.get(ActivatedRoute);

	const selectionAction = $event.selectionAction.toLowerCase();

	if (selectionAction === SelectionTargetEnum.CurrentWindow.toLowerCase()
		||
		// Check if the navigation targets the current page. if that's the case update the filter without navigating with the router
		(selectionAction === SelectionTargetEnum.Navigate.toLowerCase()
			&& router.isActive($event.selectionRoute, false))
	) {
		// ignore click event when empty selection object
		if (!isEmptyObject($event.selectionObject))
			filterCompareBarService.triggerNavigation($event.selectionObject);
	} else if (selectionAction === SelectionTargetEnum.ModalWindow.toLowerCase()) {
		const navObject = {
			...navbar.getValue().mainbarResultParams, ...$event.selectionObject,
			...$event.selectionMeta, ...getPanelSettings(activatedRoute)
		};
		const matDialog = dialog.open<DashboardPopupComponent, DashboardPopupData>(DashboardPopupComponent, {
			data:              {
				filterData:       navObject as unknown as FilterBarResultParams,
				panelName:        $event.panelName,
				parentInstanceId: $event.dashboardInstanceId
			},
			minHeight:         '80vh',
			minWidth:          '80vw',
			panelClass:        'dashboard-modal-window',
			disableClose:      false,
			closeOnNavigation: true
		});
	} else if (selectionAction === SelectionTargetEnum.Navigate.toLowerCase()) {
		const navObject                                     = {...navbar.getValue().mainbarResultParams, ...$event.selectionObject, ...$event.selectionMeta};
		const patchedSelectionObject: SelectionTargetResult = {...$event, selectionObject: navObject as unknown as { [key: string]: string }};

		config.navigateTo(patchedSelectionObject, activatedRoute).then(value => {
			// setTimeout(() => {
			// 	appService.refreshNavigationBar();
			// }, 10);
		});

	} else if (selectionAction === SelectionTargetEnum.HttpRequest.toLowerCase()) {
		const navObject                                     = {...navbar.getValue().mainbarResultParams, ...$event.selectionObject};
		const patchedSelectionObject: SelectionTargetResult = {...$event, selectionObject: navObject as unknown as { [key: string]: string }};

		const metaData = $event.selectionMeta as GenericHttpRequest;
		if (metaData.preAction && metaData.preAction.action.toLowerCase() === GenericHttpRequestOptionAction.CONFIRM.toLowerCase()) {
			const result = await getUserConfirmation(metaData.preAction.message, DialogType.none, dialog, i8n);

			if (!result)
				return;
		}
		const url = replacePlaceholders($event.selectionObject, $event.selectionRoute, PlaceholderRegexPathUrlVariable);
		config.triggerHttpRequest(metaData.method, url, patchedSelectionObject, metaData.queryParams).subscribe(value => {
			appMessageHub.publishMessage(AppMessageType.REFRESH_DATA, $event.dashboardInstanceId);
		});
	} else {
		toastManagerService.show({
			type:    'info', title: i8n.instant('IPA_ACTION_NOT_IMPLEMENTED'),
			content: i8n.instant('IPA_ACTION_NOT_IMPLEMENTED_CONTENT', {action: $event.selectionAction})
		});

	}
}

function getUserConfirmation(msg: string, type: DialogType = DialogType.none, dialog: MatDialog, i8n: TranslateService): Promise<boolean> {
	return new Promise<boolean>((resolve) => {
		const confirmDialogRef = dialog.open(DialogBasicComponent, {
			data: {
				dialogTitle: i8n.instant('CONFIRM'),
				type:        type,
				message:     msg,
				showNo:      false
			}

		});

		confirmDialogRef.afterClosed().subscribe(confirmed => {
			resolve(!!confirmed);
		});
	});
}

export function triggerHttpRequestHandler<T>(genericHttpService: GenericHttpClientService, method: HTTP_METHOD, endpoint: string, queryParams?: { [p: string]: any }, body?: any, csHttpRequestOptions?: CsHttpRequestOptions): Observable<HttpResponse<T>> {
	switch (method.toUpperCase()) {
		case HTTP_METHOD.DELETE:
			return genericHttpService.delete(endpoint, queryParams, csHttpRequestOptions);
		case HTTP_METHOD.GET:
			return genericHttpService.get(endpoint, queryParams, csHttpRequestOptions);
		case HTTP_METHOD.POST:
			return genericHttpService.post(endpoint, body, queryParams, csHttpRequestOptions);
		case HTTP_METHOD.PUT:
			return genericHttpService.put(endpoint, body, queryParams, csHttpRequestOptions);
		default:
			throw new Error(`${method} is not found as an HTTP option`);
	}

}

