import { ChangeDetectorRef, Component, forwardRef, Host, Inject, NgZone, OnInit, Renderer2 } from '@angular/core';

import { DomSanitizer, SafeHtml }     from '@angular/platform-browser';
import { ActivatedRoute, Router }     from '@angular/router';
import { CsToastManagerService }      from '@cs/components';
import { CsHttpRequestOptions }       from '@cs/core';
import { ResetPasswordConfigService } from '../reset-password-config.service';

import { TranslateService }                         from '@ngx-translate/core';
import { LoginService }                             from '@cs/performance-manager/login';
import { LoginConfigService }                       from '@cs/performance-manager/login';
import { isWaitingForResponse, WaitingForResponse } from '@cs/common';
import { Subject }                                  from 'rxjs';
import { take, tap }                                from 'rxjs/operators';


declare var grecaptcha: any;

@Component({
	selector:    'pmc-step-one',
	templateUrl: './step-one.component.html'
})
export class StepOneComponent implements OnInit {

	/**
	 * Binding to the username field
	 */
	username     = '';
	/**
	 * Binding to the captcha field
	 */
	captcha      = '';
	/**
	 * The state of the form. Defaults to true because it's empty
	 */
	isErrorState = true;

	/**
	 * HTML of the Captcha
	 */
	captchaHtml: SafeHtml;

	/**
	 * Indicator for the loader
	 */
	isLoadingCaptcha = true;

	//#region Loaders

	/**
	 * Loader flag for the reset password button
	 */
	isWaitingForReset = false;

	//#endregion


	constructor(private sanitizer: DomSanitizer,
							@Inject(forwardRef(() => LoginService)) private loginService: LoginService,
							@Inject(ResetPasswordConfigService) private resetPasswordConfig: ResetPasswordConfigService,
							@Inject(forwardRef(() => LoginConfigService)) public loginConfig: LoginConfigService,
							private l8n: TranslateService,
							private toastService: CsToastManagerService,
							private renderer: Renderer2,
							private changeRef: ChangeDetectorRef,
							private route: ActivatedRoute,
							private ngzone: NgZone,
							private router: Router) {
		window['onloadRecaptchaCallback'] = () => this.refreshCaptcha();

		this.l8n.get('RESTORE_ACCOUNT').subscribe(value => this.loginService.setTitleMessage(value));
		loginService.setUnderTitleMessage('');

	}

	private injectDependency() {
		const script   = document.createElement('script');
		script.src     = 'https://www.google.com/recaptcha/api.js?onload=onloadRecaptchaCallback&render=explicit';
		script.type    = 'text/javascript';
		script.async   = true;
		script.defer   = true;
		script.charset = 'utf-8';
		this.renderer.appendChild(document.head, script);
	}

	ngOnInit() {
		this.injectDependency();
	}

	refreshCaptcha() {
		this.resetPasswordConfig.initiatePasswordReset().subscribe((result) => {
			this.isLoadingCaptcha     = false;
			const injectedRootUrlHtml = result.value.captchaHtml;
			this.captchaHtml          = this.sanitizer.bypassSecurityTrustHtml(injectedRootUrlHtml);
			this.changeRef.detectChanges();
			this.ngzone.onStable.pipe(take(1)).subscribe(() => {

				grecaptcha.render(document.querySelector('.reset-password .g-recaptcha'), {
					'callback': (output) => {
						this.captcha = output;
						this.checkErrorState();
					}
				});

			});

		});
	}

	/**
	 * Send the password request to the server, it contains the captcha and email
	 */
	resetPassword() {

		const options: CsHttpRequestOptions = {
			errorResponseHandler: (error): boolean => {
				// Handle documented 400 error
				if (error && (error.status === 401 || error.status === 400)) {
					this.toastService.show({
						title:   this.l8n.instant('ERROR_SOMETHING_WRONG'),
						content: error.statusText,
						type:    'error'
					});

					this.refreshCaptcha();
					return true;
				}
			},
			headers:              null
		};

		this.resetPasswordConfig.requestPasswordReset(this.username, this.captcha, options)
				.pipe(tap(WaitingForResponse.new(isLoading => this.isWaitingForReset = isLoading)))
				.subscribe((result) => {
					this.router.navigate(['verify-reset'], {relativeTo: this.route.parent});
				});
	}

	getErrorMessage() {
		if (this.isLoadingCaptcha) {
			return this.l8n.instant('CAPTCHA_STILL_LOADING');
		} else if (this.username.length === 0) {
			return this.l8n.instant('NO_USERNAME');
		}
	}

	checkErrorState() {
		this.isErrorState = this.captcha.length === 0 || this.username.length === 0 || this.isLoadingCaptcha;
	}
}
