import { IDashboardPanel } from './i-dashboard-panel';
import { IEmptyPanel }     from './i-empty-panel';
import { IHtmlPanel }      from './i-html-panel';


export enum DashboardPanelMetaInfoEnum {

	LittleLarger = 'LittleLarger'

}


export type AllDashboardPanelType = IDashboardPanel & IEmptyPanel & IHtmlPanel;


