<div class="home-page-container">
	<!-- Header  -->
	<div class="home-header-container" #headerContent>

		<div class="home-header-overlay" [style.height.px]="containerHeight"></div>
		<div class="home-header-content">
			<div class="home-welcome-container">
				<h1>{{greeting$ | async}}</h1>
				<p class="last-login-text"
				   *ngIf="lastLogin$ | async">{{'LAST_LOGIN_TEXT' | translate}} {{lastLogin$ | async}}</p>
			</div>
			<div class="home-header-data-container">
				<div class="home-header-data-values-container"
					 [@listAnimation]="homeData?.stats?.indicators?.length">
					<ng-container *ngFor="let item of homeData?.stats?.indicators">
						<div class="value-panel effect-glass">
							<div class="value-panel-container ">
								<label class="value-panel--title">{{item.title}}</label>
								<div class="value-panel--value">{{item.value}}</div>
								<div class="value-panel--value-description">{{item.subTitle}}</div>
								<div class="value-panel--value-fact-description">{{item.description}}</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="home-header-data-list-container effect-glass"
					 *ngIf="homeData?.stats?.list?.length > 0" [@fadeInOnEnter]>

					<div class="cs-list" [@listSideAnimation]="homeData?.stats?.list?.length">
						<ng-container *ngFor="let item of homeData?.stats?.list">
							<div class="cs-list-item">
								<div class="cs-list-item-left">
									<label class="cs-list-item--title">{{item.title}}</label>
									<p class="cs-list-item--sub-title">{{item.subTitle}}</p>
								</div>
								<div class="cs-list-item--action">
									{{item.value}}
								</div>
							</div>
						</ng-container>
					</div>

				</div>
			</div>
		</div>
	</div>

	<!-- Dashboard part -->
	<div class="home-dashboard-container">
		<router-outlet></router-outlet>
	</div>
</div>
