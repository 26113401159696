import { isNull, isNullOrUndefined }     from '@cs/core';
import * as numeral_                     from 'numeral';
import { createObjectWithLowerCaseKeys } from '@cs/core';

/**
 * NOTE: Import lib by creating a const in each file that imports moment, is done because of for failing builds
 * https://github.com/jvandemo/generator-angular2-library/issues/221
 */
const numeral: any = numeral_;

export function toInteger(value: any): number {
	return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
	return value !== undefined && value !== null ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
	return Math.max(Math.min(value, max), min);
}

export function isString(value: any): boolean {
	return typeof value === 'string';
}

export function isNumber(value: any): boolean {
	return !isNaN(toInteger(value));
}

export function isEmptyString(value: any) {
	return value === '';
}

export function isEmpty(value: any) {
	return isNullOrUndefined(value) || value.length === 0 || !value.toString().trim();
}


/**
 * Somewhat more efficient function that gives A false or the value as an result
 * @param value value to check if it's a number
 */
export function isNumberValue(value: any): boolean | number {
	if (value === undefined || value === null)
		return false;

	const parsedVal = value.toString().replace(/,/, '');
	const c         = !isNaN(parsedVal) ? numeral(value).value() : NaN;
	return isNaN(c) ? false : c;
}


export function isDefined(value: any): boolean {
	return value !== undefined && value !== null;
}

export function padNumber(value: number) {
	if (isNumber(value)) {
		return `0${value}`.slice(-2);
	} else {
		return '';
	}
}

export function isEmptyObject(value: Object): boolean {
	return Object.keys(value).length === 0;
}

export function addClass(value: string, target = ''): string {
	if (isNullOrUndefined(value))
		return target;

	const list = target.split(' ');
	list.push(value);
	const filtered = Array.from(new Set(list));
	return filtered.join(' ');
}

export function removeClass(value: string, target = ''): string {
	const list  = target.split(' ');
	const index = list.indexOf(value);
	if (index > -1) {
		list.splice(index, 1); // splice modifies original array (returns deleted items)
	}
	return list.join(' ');
}

export function regExpEscape(text) {
	return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function uniqueBy(array: Array<any>, keys: Array<string>) {
	return array.filter(function (a) {
		// create variable to store the combined values
		const valueStore = [];
		// Loop over the keys we have provided to be unique
		for (const key of keys) {
			valueStore.push(a[key]);
		}
		// create a key for the 'hash-table'
		const combinedKey = valueStore.join('|');
		// check if the combined key exists, if not add it
		if (!this[combinedKey]) {
			this[combinedKey] = true;
			return true;
		}
	}, Object.create(null));
}

export function find<T>(
	array,
	searchObj,
	property: string = null,
	removeWhenFound  = false
): T {
	let output: T     = null;
	const searchClone = createToObjectWithLowerCaseKeys(searchObj);
	for (let index = 0; index < array.length; index++) {
		const aItem = array[index];
		const sItem = !isNull(property) ? aItem[property] : aItem;
		const item  = createToObjectWithLowerCaseKeys(sItem);
		let isSame  = true;
		for (const sKey of Object.keys(searchClone)) {
			if (searchClone[sKey] !== item[sKey]) {
				isSame = false;
				break;
			}
		}
		if (isSame) {
			output = aItem;
			if (removeWhenFound) array.splice(index, 1);
		}
	}
	return output;
}

/**
 * Returns filtered array based on properties of searchObj
 */
export function filter<T>(array: T[], searchObj, property: keyof T = null, filter: 'all' | 'partial' = 'all', allButNoGroup = false): T[] {
	const output      = [];
	const searchClone = searchObj;
	for (const aItem of array) {
		const sItem = !isNull(property) ? aItem[property] : aItem;
		const item  = sItem;
		let isSame  = true;

		const search  = filter === 'all' ? searchClone : item;
		const compare = filter === 'all' ? item : searchClone;
		for (const sKey of Object.keys(search)) {
			if (allButNoGroup && sKey.includes('_group')) {
				continue;
			}
			if (search[sKey] !== compare[sKey]) {
				isSame = false;
				break;
			}
		}
		if (isSame) {
			output.push(aItem);
		}
	}
	return output;
}

export function createToObjectWithLowerCaseKeys<T>(obj): T {
	return createObjectWithLowerCaseKeys(obj);
}

export function transformToSelectionObject(item: {
	[key: string]: any;
}): { [key: string]: any } {
	const output = {};

	for (const key of Object.keys(item)) {
		output[key] = {id: item[key]};
	}

	return output;
}

export function precisionRound(number, precision) {
	const factor = Math.pow(10, precision);
	return Math.round(number * factor) / factor;
}

export function transformToSelectionMultiObject(
	item: { [key: string]: any }[]
): { [key: string]: any[] } {
	const output = {};

	for (const key of Object.keys(item)) {
		if (isNullOrUndefined(output[key])) output[key] = [];

		output[key].push({id: item[key]});
	}

	return output;
}

/**
 * Round the value with respect for formatting
 */
export function stringFormatToNumber(formatString: string, value: number) {
	let val;

	if (isNullOrUndefined((value)))
		return null;
	if (isNullOrUndefined(formatString))
		return value;
	if (isString(value)) {
		val = parseFloat('' + value);
		if (isNaN(val))
			return null;
	}
	const isDigit = '{[\\d][\\,\\d+]?:[NC|cn](\\d?)}';
	// Match all currency format strings and replace them for a number format, this has to due with some limitations.
	// LIke on the {0:C3} will only have {0:C2}
	const match = formatString.match(isDigit);
	if (match && !isString(val)) {
		if (match.length !== 2)
			return value;
		return precisionRound(value, parseInt(match[1], 0));
	}
}

/**
 * Robust detection of userAgent (browser) and version
 */
export function get_browser_info() {
	let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return {name: 'IE', version: (tem[1] || '')};
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR\/(\d+)/);
		if (tem != null) {
			return {name: 'Opera', version: tem[1]};
		}
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) {
		M.splice(1, 1, tem[1]);
	}
	return {
		name:    M[0],
		version: M[1]
	};
}

export function hasClassName(element: any, className: string): boolean {
	return element && element.className && element.className.split &&
		element.className.split(/\s+/).indexOf(className) >= 0;
}

if (typeof Element !== 'undefined' && !Element.prototype.closest) {
	// Polyfill for ie10+

	if (!Element.prototype.matches) {
		// IE uses the non-standard name: msMatchesSelector
		Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}

	Element.prototype.closest = function (s: string) {
		let el = this;
		if (!document.documentElement.contains(el)) {
			return null;
		}
		do {
			if (el.matches(s)) {
				return el;
			}
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

export function closest(element: HTMLElement, selector?: string): HTMLElement | null {
	if (!selector) {
		return null;
	}

	/*
	 * In certain browsers (e.g. Edge 44.18362.449.0) HTMLDocument does
	 * not support `Element.prototype.closest`. To emulate the correct behaviour
	 * we return null when the method is missing.
	 *
	 * Note that in evergreen browsers `closest(document.documentElement, 'html')`
	 * will return the document element whilst in Edge null will be returned. This
	 * compromise was deemed good enough.
	 */
	if (typeof element.closest === 'undefined') {
		return null;
	}

	return element.closest(selector);
}



