export class DeploymentDetails {
  repositories?: Array<Repository>;
  buildDate?: string;
  detailsUrl?: string;
  version?: string;
  libs?: { [key: string]: string };

  constructor(initial: Partial<DeploymentDetails>) {
    Object.assign(this, initial);

    if (this.buildDate === null)
      this.buildDate = new Date().toISOString();
  }
}

export class Repository {
  displayName?: string;
  branch?: string;
  url?: string;
  lastCommitHash?: string;
  tag?: string;
}
