import { getPropertyOf, hasPropertyOf } from '@cs/core';

export enum AuditTrailAuditsItemType {
  FEED       = 'feed',
  DATA_ENTRY = 'dataentry',
  SYSTEM     = 'system'
}

export enum AuditTrailAuditsBadgeType {
  NEW       = 'new',
  DELETE    = 'delete',
  UP        = 'up',
  DOWN      = 'down',
  STATSUP   = 'statsup',
  STATSDOWN = 'statsdown',
  CONFIRM   = 'confirm',
  UNCONFIRM = 'unconfirm',
  NONE      = 'none'
}

export class AuditTrailItemClickedEventArgs {
  selection: { [p: string]: string | number };
}

export enum AuditTrailAuditsBadgeOffsetType {
  BAD     = 'bad',
  NEUTRAL = 'neutral',
  GOOD    = 'good'
}

export interface AuditTrailAuditsBadgeOffset {
  type: AuditTrailAuditsBadgeOffsetType;
  description: string;
  value: string;
}

export class AuditTrailAuditsBadge {

  type: AuditTrailAuditsBadgeType;
  label: string;
  description: string;
  offset?: AuditTrailAuditsBadgeOffset;

  get icon(): string {
    switch (this.type) {
      case AuditTrailAuditsBadgeType.NEW:
        return 'mdi mdi-plus';
      case AuditTrailAuditsBadgeType.DELETE:
        return 'mdi mdi-minus';
      case AuditTrailAuditsBadgeType.UP:
        return 'mdi mdi-arrow-top-right';
      case AuditTrailAuditsBadgeType.DOWN:
        return 'mdi mdi-arrow-bottom-left';
      case AuditTrailAuditsBadgeType.STATSUP:
        return 'mdi mdi-menu-up';
      case AuditTrailAuditsBadgeType.STATSDOWN:
        return 'mdi mdi-menu-down';
      case AuditTrailAuditsBadgeType.CONFIRM:
        return 'mdi-lock-check';
      case AuditTrailAuditsBadgeType.UNCONFIRM:
        return 'mdi-lock-open';
      case AuditTrailAuditsBadgeType.NONE:
        return '';
      default:
        return '';
    }
  }

  constructor(init: AuditTrailAuditsBadge) {
    this.type        = getPropertyOf(init, 'type', null);
    this.label       = getPropertyOf(init, 'label', null);
    this.description = getPropertyOf(init, 'description', null);
    this.offset      = getPropertyOf(init, 'offset', null);
  }
}

export class AuditTrailAuditsItem {
  get icon(): string {
    switch (this.type) {
      case AuditTrailAuditsItemType.SYSTEM:
        return 'mdi mdi-calendar';
      case AuditTrailAuditsItemType.FEED:
        return 'mdi mdi-flag';
      case AuditTrailAuditsItemType.DATA_ENTRY:
        return 'mdi mdi-pencil';
      default:
        return 'mdi mdi-update';
    }
  }

  set icon(value: string | null) {
    this._icon = value;
  }

  private _icon: string | null;
  type: AuditTrailAuditsItemType;
  author: string;
  label: string;
  timestamp: Date;
  badges: AuditTrailAuditsBadge[];
  selection: { [p: string]: number | string };

  constructor(init: AuditTrailAuditsItem) {
    this.type      = getPropertyOf(init, 'type', null);
    this.author    = getPropertyOf(init, 'author', null);
    this.label     = getPropertyOf(init, 'label', null);
    this.timestamp = hasPropertyOf(init, 'timestamp') ? new Date(init.timestamp) : null;
    this.badges    = hasPropertyOf(init, 'badges') ? init.badges.map(value => new AuditTrailAuditsBadge(value)) : [];
    this.selection = getPropertyOf(init, 'selection', null);
  }
}

export class AuditTrailAudits {

  items: AuditTrailAuditsItem[];

  constructor(init: AuditTrailAudits) {
    this.items = hasPropertyOf(init, 'items') ? init.items.map(value => new AuditTrailAuditsItem(value)) : [];
  }
}
