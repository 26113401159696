<div class="audits-container" [class.no-categories]="categories?.length<2">
  <div class="combobox-container" *ngIf="categories?.length>1">
    <cs-combobox [(ngModel)]="activeCategories"
                 [multiple]="isCategoriesMultiSelect"
                 [options]="categories"
                 [filter]="true"
                 (selected)="onCategorySelectionChanged()"
                 (deselected)="onCategorySelectionChanged()">
    </cs-combobox>
  </div>


  <div class="audits-container--status-text" *ngIf="filteredData?.items.length === 0">No items to show</div>
  <div class="audits-container--scrollable">

    <div class="audits-items-container">
      <ng-container *ngFor="let item of filteredData?.items">

        <div class="audit-item"
             (click)="itemClicked(item)">
          <div class="audit-item--header">
            <div class="audit-item--author">
              {{item.author}}
            </div>
            <div class="audit-item--timestamp"
                 matTooltip="{{item.timestamp | format:'{0:f}'}}"
                 matTooltipPosition="above">
              {{item.timestamp | format:'{0:d}'}}
            </div>
          </div>
          <div class="audit-item--label">
            {{item.label}}
          </div>
          <div class="audit-item--footer">
            <span matTooltip="{{ 'DATA_ENTRY_TYPES.'+item.type.toUpperCase() | translate}}">
              <i class="audit-item--icon {{item.type}} {{item.icon}}"></i>
            </span>
            <div class="audit-item--change-badges">
              <ng-container *ngFor="let badge of item.badges">
                <div class="audit-item--change-badge">
                <span class="badge" matTooltip="{{badge.description}}" matTooltipPosition="above">
                  <i class="{{badge.icon}}"></i>
                  {{badge.label}}
                </span>
                  <small *ngIf="badge.offset" class="f-body-xs badge-offset {{badge.offset?.type}}"
                         matTooltip="{{badge?.offset?.description}}" matTooltipPosition="above">
                    {{badge?.offset?.value}}
                  </small>
                </div>
              </ng-container>
            </div>
          </div>

        </div>

      </ng-container>

    </div>

  </div>

</div>

