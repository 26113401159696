import { Component, ViewEncapsulation }                        from '@angular/core';
import { animate, state, style, transition, trigger }          from '@angular/animations';
import { filter, map, mergeMap }                               from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CUSTOMER_VARS }                                       from '../environments/environment';
import { Title }                                               from '@angular/platform-browser';
import { LoggerUtil, isNullOrUndefined }                       from '@cs/core';
import { routerFadeTransition }                                from '@cs/common';


@Component({
	selector:      'app-root',
	templateUrl:   './app.component.html',
	styleUrls:     ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations:    [
		routerFadeTransition('fadeAnimation', '350ms ease-out'),
		trigger('fade', [
			state('show', style({
				opacity: 1,
				display: 'block'
			})),
			state('hide', style({
				opacity: 0,
				display: 'none'
			})),
			transition('hide => show', animate('400ms ease-in')),
			transition('show => hide', animate('400ms ease-out'))
		])
	]
})
export class AppComponent {
	/**
	 * Flag to show the we're setting things ups message
	 */
	appIsLoaded: 'hide' | 'show' = 'show';

	constructor(private router: Router,
							private activatedRoute: ActivatedRoute,
							private titleService: Title) {
		this.initTitleChangeOnRouting();
		this.changeSplashLoaderText();
	}

	/**
	 * This is a automatic page title setting function.
	 * By default shows the title set in @link CUSTOMER_VARS.Title
	 * If title is set on the data attibute in the routingPaths.
	 * It shows "@link CUSTOMER_VARS.Title - @link Route.data ['title']"
	 */
	private initTitleChangeOnRouting() {
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => this.activatedRoute),
			map((route) => {
				while (route.firstChild) {
					route = route.firstChild;
				}
				return route;
			}),
			mergeMap((route) => route.data)
		).subscribe((event) => {
			let title = CUSTOMER_VARS.title;

			if (!isNullOrUndefined(event) && event.hasOwnProperty('title')) {
				title += ' - ' + event['title'];
			}
			this.titleService.setTitle(title);
			if (this.appIsLoaded === 'show') {
				this.appIsLoaded = 'hide';
				this.hideAppLoader();
			}
		});
	}

	/**
	 * find the splashloader and turn it off
	 */
	hideAppLoader() {
		const loader = document.querySelector('.splash-loader-container');
		if (loader) {
			loader.classList.add('done');
			// Wait till animation is over
			setTimeout(() => {
				if (loader)
					loader.remove();
			}, 600);
		} else
			LoggerUtil.warn('No splash loader found');
	}

	/**
	 * Changing the splash text so the user sees some progress
	 */
	private changeSplashLoaderText() {
		const loaderText     = document.querySelector('#loader-text');
		loaderText.innerHTML = 'Connecting to the server';
	}

	getRouterOutletState(outlet: RouterOutlet) {
		return outlet.isActivated ? outlet.activatedRoute : '';
	}
}
