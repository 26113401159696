import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, NgZone,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';

import { Tooltip }                                           from './models/tooltip';
import { StatisticsPanelData }                               from './models/statistics-models';
import { IDataSource }                                       from './interfaces/interfaces';
import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { fadeOutOnLeaveAnimation, SafeMethods }              from '@cs/common';
import { isNullOrUndefined }                                 from '@cs/core';
import { ComponentChanges, whenChanging }                    from '@cs/core';
import { Observable, Subject }                               from 'rxjs';


@Component({
  selector:        'cs-statistics-panel',
  templateUrl:     './statistics-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations:      [
    fadeOutOnLeaveAnimation(),

    trigger('statisticsPanelState', [
      state('isLoading', style({
        opacity: 0
      })),
      state('isLoaded', style({
        opacity: 1
      })),
      transition('isLoading <=>isLoaded',
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('statisticsState', [

      transition(':leave', [ // each time the binding value changes

        style({opacity: 1}),
        animate('200ms ease-in',
          style({
            opacity: 0
          }))

      ]), transition(':enter', [ // each time the binding value changes

        style({opacity: 0}),
        animate('375ms 150ms ease-out',
          style({
            opacity: 1
          }))

      ])
    ])
  ]
})
export class CsStatisticsPanelComponent implements OnChanges {
  loadingState: 'isLoading' | 'isLoaded' = 'isLoading';
  tooltip: Tooltip                       = new Tooltip();
  selectedDiv: ElementRef;
  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() dataSource: StatisticsPanelData[];

  /**
   * String that contains the title of the chart panel.
   */
  @Input() title: string;

  @Input() isLoading: boolean;

  /**
   * Use to set the current tab
   */
  @Input() currentPage: IDataSource;
  @Output() tabSwitched: EventEmitter<IDataSource> = new EventEmitter<IDataSource>();

  private isLoadingLongerEmitter: Subject<boolean> = new Subject<boolean>();

  isLoadingLonger$: Observable<boolean> = this.isLoadingLongerEmitter;
  private timeout: any | null           = null;


  constructor(private elementRef: ElementRef,
              private changeRef: ChangeDetectorRef,
              private ngZone: NgZone,
              private renderer: Renderer2) {
  }

  ngOnChanges(changes: ComponentChanges<this>): void {

    whenChanging(changes.isLoading, true).execute(value => {
      const loading = value.currentValue;
      if (loading && this.timeout === null) {
        this.timeout = window.setTimeout(() => {
          this.isLoadingLongerEmitter.next(loading);
          this.timeout = null;
        }, 300);

      } else if (!loading && this.timeout) {
        clearTimeout(this.timeout);
        this.isLoadingLongerEmitter.next(loading);
        this.timeout = null;
      } else if (!loading && this.timeout === null) {
        this.isLoadingLongerEmitter.next(loading);
      }
    });

    whenChanging(changes.dataSource, true).execute(value => {
      const statsdata = value.currentValue as StatisticsPanelData[];
      if (!isNullOrUndefined(statsdata) && statsdata.length > 0) {
        let page = statsdata[0];
        if (this.currentPage) {
          const found = statsdata.find(value1 => value1.type === this.currentPage.type);
          if (found)
            page = found;
        }
        this.currentPage = page;
      }
      if (isNullOrUndefined(statsdata) || statsdata.length === 0) {
        this.currentPage = {
          data:  {},
          type:  'None',
          label: ''
        };
      }

      if (statsdata !== null)
        setTimeout(() => {
          this.loadingState = 'isLoaded';
          SafeMethods.detectChanges(this.changeRef);
        }, 0);
    });


  }

  onItemClicked(iStat: number, iData: number, iValue: number) {
    this.resetTooltip();

    const tooltips = this.dataSource[iStat].data[iData].tooltip;
    for (const tooltip in tooltips) {
      if (tooltips[tooltip].index === (iValue + 1)) {
        this.tooltip     = tooltips[tooltip];
        this.selectedDiv = this.elementRef.nativeElement.querySelector('.index-' + (iStat + 1) + (iData + 1) + (iValue + 1));
        this.renderer.setStyle(this.selectedDiv, 'display', 'inline-table');
      }
    }
  }

  resetTooltip() {
    if (this.selectedDiv) {
      this.renderer.setStyle(this.selectedDiv, 'display', 'none');
    }
    this.tooltip = new Tooltip();
  }

  /**
   * Switch between the workflow page and statistic page
   * @param tab name of the tab
   */
  switchInfo(tab: IDataSource) {
    this.currentPage = tab;
    this.tabSwitched.emit(tab);
  }

  markAsChanged() {
    this.changeRef.markForCheck();
  }
}
