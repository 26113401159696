import { getPropertyOf } from '@cs/core/utils';


export class DataDescribedGroup {
	constructor(init: Partial<DataDescribedGroup>) {
		this.id    = getPropertyOf(init, 'id', null);
		this.label = getPropertyOf(init, 'label', '');

	}

	/**
	 * Identification of the FormFieldset
	 */
	id?: string;
	/**
	 * Name to show in the UI
	 */
	label?: string;

}
