import { getPropertyOf } from '@cs/core/utils';

export class TableLayoutAnnotation {
	/**
	 * Allow the rows to be clicked
	 */
	isSelectable?: boolean;
	/**
	 * Ignore the id called 'id' and don't add it to the rendering
	 */
	ignoreIdColumn?: boolean;

	/**
	 * Flag to turn on or off the automatic scroll detection
	 */
	hasHorizontalScrollEnabled?: boolean;

	constructor(init: Partial<TableLayoutAnnotation>) {
		this.isSelectable               = getPropertyOf(init, 'isSelectable', false);
		this.ignoreIdColumn             = getPropertyOf(init, 'ignoreIdColumn', true);
		this.hasHorizontalScrollEnabled = getPropertyOf(init, 'hasHorizontalScrollEnabled', true);
	}
}
