import { FormatRegistry } from "@cs/common";

export class FormatRegistryUnilode extends FormatRegistry {

  constructor() {
    super();
    this.register('DateTime', '{0:d}', DefaultConversions.DateTime);
    this.register('Date', '{0:d}', DefaultConversions.DateTime);
    this.register('Decimal', '{0:n2}');
    this.options.nullAsZero = false;
  }
}

export class DefaultConversions {
  static DateTime(value) {
    try {
      return new Date(value);
    } catch (e) {
      return value;
    }
  }

}
