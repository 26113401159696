import { Component, Input, Output, EventEmitter, forwardRef, SimpleChanges }   from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, FormControl } from '@angular/forms';

const CS_CHECKBOX_CONTROL_VALUE_ACCESSOR: any =
				[
					{
						provide:     NG_VALUE_ACCESSOR,
						useExisting: forwardRef(() => CsCheckboxComponent),
						multi:       true
					},
					{
						provide:     NG_VALIDATORS,
						useExisting: forwardRef(() => CsCheckboxComponent),
						multi:       true
					}
				];

/**
 * Ui checkbox component.
 *

 */
@Component({
	selector:    'cs-checkbox',
	templateUrl: './checkbox.component.html',
	providers:   [CS_CHECKBOX_CONTROL_VALUE_ACCESSOR]
})
export class CsCheckboxComponent implements ControlValueAccessor {
	_disabled: boolean;
	_checked: boolean;

	/**
	 * Model to be bind.
	 */
	@Input('ngModel') ngModel; // ngModel
	/**
	 *
	 * on change event!
	 */
	@Output() ngModelChange = new EventEmitter<boolean>();


	@Input() set checked(v: boolean) {
		this._checked = v !== false;
		this.ngModel  = this._checked;
	}

	get checked() {
		return this._checked;
	}

	@Input() set disabled(v: boolean) {
		this._disabled = v !== false;
	}

	get disabled() {
		return this._disabled;
	}

	private onTouchedCallback = (v: any) => {
	};
	private onChangeCallback  = (v: any) => {
	};
	private validateFn: any   = () => {
	};

	change(newValue) {
		this.ngModel = newValue;
		this.ngModelChange.emit(newValue);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['ngModel']) {
			this.ngModel = !!changes['ngModel'].currentValue;
		}
		//
		this.validateFn = () => null;
	}

	writeValue(obj: any): void {
		if (obj !== this.ngModel) {
			this.ngModel = !!obj;
		}
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	validate(c: FormControl) {
		return this.validateFn(c);
	}
}
