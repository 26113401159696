import { CsApplicationSettings } from '@cs/performance-manager';
import { Route }                 from '@angular/router';

export let appsettings: CsApplicationSettings = null;

export class CustomerRoutes {
  static appRoutingModule: Route[] = [];
  static shellRoutes: Route[]      = [];
}


export function setAppSettings(settings) {
  appsettings = settings;
}
