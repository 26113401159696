import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Injector, OnDestroy,
	OnInit,
	ViewChild
}                                                                from '@angular/core';
import {
	FilterBarResultParams,
	FilterCompareBarQuery,
	FilterCompareBarService,
	CsToastManagerService,
	CsFilterAndCompareBarComponent,
	FilterBarDataSource,
	FilterSelectionChangedEventArgs,
}                                                                from '@cs/components';
import { HomeCdConfigService }                                   from './home-config.service';
import { AuthenticationQuery }                                   from '@cs/performance-manager/shared';
import {
	distinctUntilChanged,
	filter,
	map,
	switchMap
}                                                                from 'rxjs/operators';
import { TranslateService }                                      from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed }                          from '@ngneat/until-destroy';
import { interval }                                              from 'rxjs';
import {
	AppNavigationService, BrandingQuery, fadeInOnEnterAnimation,
	FormatProviderService, getAllUrlParamsFromActiveComponents, SafeMethods
}                                                                from '@cs/common';
import { CsHttpRequestOptions, CsNavItem, FileUtil, LoggerUtil } from '@cs/core';

import { LoginUserProfile }                                      from '@cs/performance-manager/shared';
import { isNullOrUndefined }                                     from '@cs/core';
import { AppQuery }                                              from '@cs/performance-manager/shared';
import {
	ActivatedRoute,
	Router
}                                                                from '@angular/router';
import { AppService }                                            from '@cs/performance-manager/shared';
import { MatDialog }                                             from '@angular/material/dialog';
import { HomeCdData }                                            from './models/home-cd.model';
import {
	animate,
	query,
	stagger,
	style,
	transition,
	trigger
}                                                                from '@angular/animations';
import { PmNavbarExtendedSelection }                             from '@cs/performance-manager/shared';
import { DashboardComponent }                                    from '@cs/performance-manager/dashboard';
import { HttpErrorResponse }                                     from '@angular/common/http';


@UntilDestroy()
@Component({
	selector:    'pmc-home-cd',
	templateUrl: './home-cd.component.html',
	animations:  [
		fadeInOnEnterAnimation(),
		trigger('listAnimation', [
			transition('* => *', [ // each time the binding value changes
				// this causes a weird quirk
				// query(':leave', [
				//   stagger(100, [
				//     animate('0.25s cubic-bezier(0.4, 0.0, 0.2, 1)', style({opacity: 0}))
				//   ])
				// ], {optional: true}),
				query(':enter', [
					style({opacity: 0, transform: 'translate3d(0,-25px,0)'}),
					stagger(100, [
						animate('0.25s 400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
							opacity:   1,
							transform: 'translate3d(0,0,0)'
						}))
					])
				], {optional: true})
			])
		]),

		trigger('listSideAnimation', [
			transition('* => *', [ // each time the binding value changes
				query(':leave', [
					stagger(100, [
						animate('0.25s cubic-bezier(0.4, 0.0, 0.2, 1)', style({opacity: 0}))
					])
				], {optional: true}),
				query(':enter', [
					style({opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)'}),
					stagger(100, [
						animate('0.25s 400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
							opacity:   1,
							transform: 'scale3d(1, 1, 1)'
						}))
					])
				], {optional: true})
			])
		])
	]
})
export class HomeCdComponent implements OnInit, OnDestroy {

	@ViewChild('dashboard', {static: true}) dashboard: DashboardComponent;

	/**
	 * Instance of the FilterAndCompareComponent used for interacting directly with the component
	 */
	@ViewChild(CsFilterAndCompareBarComponent)
	filterAndCompareBar: CsFilterAndCompareBarComponent;

	/**
	 * Datasource for the first filter navbar
	 */
	mainbarDataSource: FilterBarDataSource<FilterBarResultParams> = {
		activateComparison: false,
		filterElements:     [],
		navElements:        [],
		apiParams:          null,
		resultParams:       null,
		subFilterItems:     []
	};
	/**
	 * Current selection of the FilterNavbar
	 */
	currentNavbarSelection: PmNavbarExtendedSelection;

	greeting$ = this.authQuery.select(store => store.userProfile)
									.pipe(
										untilDestroyed(this),
										filter(value => value !== null),
										switchMap(value => this.getCurrentDayPart(value)));

	lastLogin$ = this.authQuery.select(store => store.userProfile)
									 .pipe(
										 untilDestroyed(this),
										 filter(value => value !== null),
										 map(value => {
											 return this.relativeTime(value.lastLogin);
										 }));

	time$ = interval(1000).pipe(map(value => new Date().toLocaleTimeString()), distinctUntilChanged());

	date$ = interval(1000).pipe(map(value => new Date().toLocaleDateString()), distinctUntilChanged());

	homeData: HomeCdData;
	backgroundStyling: {
		backgroundImage: string,
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}     = null;

	constructor(public readonly config: HomeCdConfigService,
							private readonly authQuery: AuthenticationQuery,
							private readonly activatedRoute: ActivatedRoute,
							private readonly appStateQuery: AppQuery,
							private readonly format: FormatProviderService,
							private readonly injector: Injector,
							readonly filterCompareBarQuery: FilterCompareBarQuery,
							readonly filterCompareBarService: FilterCompareBarService,
							readonly appNavigationService: AppNavigationService,
							readonly appService: AppService,
							readonly cdRef: ChangeDetectorRef,
							readonly i8n: TranslateService,
							readonly toastService: CsToastManagerService,
							readonly dialog: MatDialog,
							readonly router: Router,
							private elRef: ElementRef,
							private readonly brandingQuery: BrandingQuery) {

	}


	ngOnInit() {
		// this.setData();

		this.setupNavbarChangeListener();
		this.setupBackdropChangeListener();
		this.setHeaderDataValuesSlider();
	}

	private setHomeData(selection: FilterBarResultParams) {
		// this.setData(selection);

		this.config.getHomepage(selection).subscribe(value => {
				this.homeData = value.value;
				SafeMethods.detectChanges(this.cdRef);
				this.filterAndCompareMovement();
			}
		);
	}

	private getCurrentDayPart(user: LoginUserProfile) {
		const currentTime = new Date();
		const currentHour = currentTime.getHours();
		let output        = 'HELLO';
		if (currentHour >= 0 && currentHour < 6) {
			output = 'GOODNIGHT';
		} else if (currentHour >= 6 && currentHour < 12) {
			output = 'GOODMORNING';
		} else if (currentHour >= 12 && currentHour < 18) {
			output = 'GOODAFTERNOON';
		} else if (currentHour >= 18 && currentHour < 24) {
			output = 'GOODEVENING';
		}
		return this.i8n.get(output).pipe(map(timeOfDay => `${timeOfDay} ${user.firstName}`));
	}

	relativeTime(time: Date) {
		if (isNullOrUndefined(time))
			return time;

		const date     = new Date(time),
					diff     = (((new Date()).getTime() - date.getTime()) / 1000),
					day_diff = Math.floor(diff / 86400);
		const year     = date.getFullYear(),
					month    = date.getMonth() + 1,
					day      = date.getDate();

		if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
			return (
				year.toString() + '-'
				+ ((month < 10) ? '0' + month.toString() : month.toString()) + '-'
				+ ((day < 10) ? '0' + day.toString() : day.toString())
			);

		const r =
						(
							(
								day_diff === 0 &&
								(
									(diff < 60 && this.i8n.instant('JUST_NOW'))
									|| (diff < 120 && this.i8n.instant('ONE_MINUTE_AGO'))
									|| (diff < 3600 && `${Math.floor(diff / 60)} ${this.i8n.instant('MINUTES_AGO')}`)
									|| (diff < 7200 && this.i8n.instant('ONE_HOUR_AGO'))
									|| (diff < 86400 && `${Math.floor(diff / 3600)} ${this.i8n.instant('HOURS_AGO')}`)
								)
							)
							|| (day_diff === 1 && this.i8n.instant('YESTERDAY'))
							|| (day_diff < 7 && `${day_diff} ${this.i8n.instant('HOURS_AGO')}`)
							|| (day_diff < 31 && `${Math.ceil(day_diff / 7)} ${this.i8n.instant('WEEKS_AGO')}`)
						);
		return r;
	}

	ngOnDestroy(): void {
	}

	navigateToPage(page: CsNavItem) {
		const isActive = this.homeData.pages.find(value => value.active);

		if (isActive)
			isActive.active = false;

		this.router.navigate([page.name], {relativeTo: this.activatedRoute}).then(value => {
			page.active = true;
		});
	}

	private setupNavbarChangeListener() {
		this.appStateQuery.select(store => store.currentAppParamsScope)
				.pipe(
					untilDestroyed(this),
					filter(value => value != null)
				)
				.subscribe(value => {
					this.currentNavbarSelection = (value || this.getUrlParamAsSelection()) as PmNavbarExtendedSelection;
					// TODO: filter the queryParams for the compare bar
					this.updateFilterAndCompareBar(this.activatedRoute.snapshot.queryParams);
				});
	}

	filterBarSelectionChanged($event: FilterSelectionChangedEventArgs) {
		this.filterAndCompareBar.toggleDropdowns($event.dropdown.sortIndex, 'hide', $event.isCompareRow);
		SafeMethods.detectChanges(this.cdRef);
		const dataSource = this.mainbarDataSource;
		const apiParams  = Object.assign({}, dataSource.apiParams);
		const selection  = Object.assign({}, apiParams, $event.newApiParams);
		this.updateFilterAndCompareBar(selection, $event.dropdown.identifier, $event.isCompareRow);
	}

	private updateFilterAndCompareBar(apiParams: { [key: string]: any }  = {},
																		trigger: string                    = '',
																		isComparisonBar: boolean           = false,
																		paramsMain: { [key: string]: any } = {}) {

		// merge the module params with the params provided by the filterbar selection
		let patchedParams = Object.assign({}, apiParams, this.currentNavbarSelection);

		// Other feature modules could register a cleanup method for the navigation params,
		// mostly used for nested objects as base64 queryparam
		patchedParams = this.appNavigationService.cleanUpQueryParams(patchedParams) as PmNavbarExtendedSelection;

		this.config.getFilterAndCompareBarData(patchedParams,
			this.currentNavbarSelection.navFilterBar,
			isComparisonBar,
			paramsMain,
			trigger
		)
				.subscribe(result => {
					if (isNullOrUndefined(result.value)) {
						LoggerUtil.error('PLEASE CHECK FILTERBAR request... it\'s returning NULL');
						return;
					}

					if (trigger === '') {
						const currentBar = isComparisonBar ? this.filterAndCompareBar.mainBar : this.filterAndCompareBar.compareBar;
						if (!isNullOrUndefined(currentBar)) {
							currentBar.state = 'firstLoaded';
							this.filterAndCompareBar.changeRef.markForCheck();
							this.filterAndCompareBar.changeRef.detectChanges();
						}
					}

					this.mainbarDataSource = result.value;

					// this.setData(result.value.resultParams);

					SafeMethods.detectChanges(this.cdRef);

					this.setHomeData(this.mainbarDataSource.resultParams);

				});
	}

	filterAndCompareMovement() {
		let dataValues = this.elRef.nativeElement as HTMLElement;
		dataValues     = dataValues.querySelector('.home-header-data-values-container') as HTMLElement;

		const elements         = this.elRef.nativeElement as HTMLElement;
		const filter           = elements.querySelector('.home-header-data-list-container') as HTMLElement;
		const indicators       = elements.querySelector('.home-header-data-values-container') as HTMLElement;
		indicators.style.width = 'auto';

		const offsetRight = dataValues.offsetLeft + dataValues.offsetWidth;
		const offsetLeft  = filter.offsetLeft;

		if (offsetRight > offsetLeft) {
			filter.style.marginBottom = '13%';
			indicators.style.width    = '100%';
		} else {
			filter.style.marginBottom = '0';
		}
	}

	setBackgroundImage(image: string | undefined) {

		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (response: HttpErrorResponse) => {

			// Restore default image
			this.backgroundStyling = null;
			return true;
		};

		// Download the backdrop, This is done async and replaced when the image is loaded
		this.config.getBackdropImage(image, {}, options).subscribe(value => {

			if (value == null) {
				this.backgroundStyling = null;
				return;
			}

			FileUtil.createFileUrl(value).subscribe(dataUrl => {
				this.backgroundStyling = image == null ? null : {
					backgroundImage:    `url(${dataUrl})`,
					backgroundPosition: 'center',
					backgroundSize:     'cover'
				};
			});
		});


	}

	setHeaderDataValuesSlider() {
		const slider = this.elRef.nativeElement.querySelector('.home-header-data-values-container');
		let isDown   = false;
		let startX;
		let scrollLeft;

		slider.addEventListener('mousedown', (e) => {
			isDown = true;
			slider.classList.add('active');
			startX     = e.pageX - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
		});
		slider.addEventListener('mouseleave', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mouseup', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mousemove', (e) => {
			if (!isDown) return;
			e.preventDefault();
			const x           = e.pageX - slider.offsetLeft;
			const walk        = (x - startX);
			slider.scrollLeft = scrollLeft - walk;
			// console.log(walk);
		});
	}

	private getUrlParamAsSelection() {
		const activeModules = getAllUrlParamsFromActiveComponents(this.activatedRoute);
		return activeModules as PmNavbarExtendedSelection;
	}

	private setupBackdropChangeListener() {
		this.brandingQuery.select(store => store.backDropImage)
				.pipe(
					untilDestroyed(this),
					filter(value => value != null)
				)
				.subscribe(value => {
					this.setBackgroundImage(value);
				});
	}
}
