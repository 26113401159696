import { NgModule }                    from '@angular/core';
import { CommonModule }                from '@angular/common';
import { PmDynamicButtonBarComponent } from './dynamic-button-bar.component';
import { MatDialogModule }             from '@angular/material/dialog';
import { MatTooltipModule }            from '@angular/material/tooltip';
import { DialogBasicModule }           from '../dialog-basic/dialog-basic.module';
import { CsSharedModule }              from '@cs/components';

@NgModule({
	declarations: [PmDynamicButtonBarComponent],
	exports:      [PmDynamicButtonBarComponent],
	imports:      [
		CommonModule,
		MatTooltipModule,
		MatDialogModule,
		DialogBasicModule,
		CsSharedModule
	]
})
export class PmDynamicButtonBarModule {
}
