import { Component, OnInit, OnDestroy, Inject, NgZone } from '@angular/core';
import { AnimationEvent }                               from '@angular/animations';

import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from './toast-manager.config';
import { ToastRef }                                   from './toast-ref.model';
import { toastAnimations, ToastAnimationState }       from './toast-animation';

@Component({
	selector:    'cs-toast',
	templateUrl: './toast.component.html',
	animations:  [toastAnimations.fadeToast]
})
export class ToastComponent implements OnInit, OnDestroy {
	animationState: ToastAnimationState = 'default';
	iconType: string;

	private intervalId: number;
	// Progress bar variables
	progressWidth    = 0;
	private stopTime = false;
	private timer: any;
	private steps: number;
	private speed: number;
	private count    = 0;
	private start: any;
	private diff: any;
	showProgressBar: boolean;

	constructor(
		readonly data: ToastData,
		readonly ref: ToastRef,
		private zone: NgZone,
		@Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
	) {
		this.iconType        = data.type === 'success' ? 'done' : data.type;
		this.showProgressBar = data.showProgressBar || true;
	}

	ngOnInit() {
		if (!this.data.clickToClose)
			this.startTimeOut();
	}

	ngOnDestroy() {
		clearTimeout(this.intervalId);
	}

	close() {
		//Trigger the animation for closing
		this.animationState = 'closing';
	}

	onFadeFinished(event: AnimationEvent) {
		const {toState}  = event;
		const isFadeOut  = (toState as ToastAnimationState) === 'closing';
		const itFinished = this.animationState === 'closing';

		if (isFadeOut && itFinished) {
			this.ref.close();
		}
	}

	startTimeOut(): void {
		const timeout = (this.data.timeOut || this.toastConfig.timeOut) / 10;
		this.steps    = timeout;
		this.speed    = timeout / this.steps;
		this.start    = new Date().getTime();
		this.zone.runOutsideAngular(() => this.timer = setTimeout(this.instance, this.speed));
	}

	private instance = () => {
		this.zone.runOutsideAngular(() => {
			this.zone.run(() => this.diff = (new Date().getTime() - this.start) - (this.count * this.speed));

			if (this.count++ === this.steps) {
				this.zone.run(() => this.animationState = 'closing');
			} else if (!this.stopTime) {
				if (this.showProgressBar) {
					this.zone.run(() => this.progressWidth += 100 / this.steps);
				}

				this.timer = setTimeout(this.instance, (this.speed - this.diff));
			}
		});
	};

	onEnter(): void {
		if (this.toastConfig.pauseOnHover) {
			this.stopTime = true;
		}
	}

	onLeave(): void {
		if (this.toastConfig.pauseOnHover) {
			this.stopTime = false;
			setTimeout(this.instance, (this.speed - this.diff));
		}
	}
}
