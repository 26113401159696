import { IConfigService }               from '../app/config-service.interface';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { IsAuthenticated }              from './models/is-authenticated.model';
import { Observable }                   from 'rxjs';
import { LoginUserProfile }             from './models/login-user-profile';
import { AuthLogin }                    from './models/auth-login.model';

const SECOND = (60 * 1000);
const MINUTE = (60 * SECOND);
const HOUR   = (60 * MINUTE);

export abstract class AuthenticationConfigService implements IConfigService {

	moduleName         = 'authentication';
	/**
	 * The url path when an unrecoverable error has occurred
	 */
	errorUrl           = '/error';
	/**
	 * The url path where the user can login
	 */
	loginUrl           = '/login';
	/**
	 * The url path where the user reset password
	 */
	resetPasswordUrl   = '/login/recover';
	/**
	 * The url path where the user reset password
	 */
	expiredPasswordUrl = '/login/recover/change-password';
	/**
	 *  The url path when the user needs to set a new password
	 */
	setPasswordUrl     = '/login/recover/set-password';
	/**
	 *  The url path used for external logins
	 */
	externalLoginUrl   = '/login/external';
	/**
	 * The amount of time in seconds between each keep-alive call: defaults to 2 hours
	 */
	keepAliveInterval  = HOUR * 2;

	/**
	 * Checks if the user is logged in by requesting a validation
	 * @param errorHandler The handling of errors intercepting the global error handler
	 */
	abstract isLoggedIn(errorHandler?: CsHttpRequestOptions): Observable<Result<IsAuthenticated>>;

	/**
	 * The user is requesting a login by token
	 * @param autoLoginToken The token to login
	 * @param errorHandler The handling of errors intercepting the global error handler
	 */
	abstract autoLogin(autoLoginToken: string, csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<LoginUserProfile>>;

	/**
	 * The user is requesting a login by external auth provider
	 * @param autoLoginToken The token to login
	 * @param errorHandler The handling of errors intercepting the global error handler
	 */
	abstract authLogin(method: string, param: AuthLogin, csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<LoginUserProfile>>;

	/**
	 * Will logout the user
	 */
	abstract logOut(): Observable<Result<boolean>>;

	/**
	 * Sends a keep alive request to extend the session
	 * @param errorHandler Custom error handler
	 */
	abstract keepAlivePing(errorHandler?: CsHttpRequestOptions): Observable<Result<boolean>>;

	/**
	 * Get the userprofile of the logged in user, also the IsImpersonated info
	 */
	abstract getUserProfile(): Observable<Result<LoginUserProfile>>;
}
