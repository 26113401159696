/* tslint:disable */

import {
	HttpHeaders,
	HttpParams,
	HttpResponse,
	HttpEvent
} from '@angular/common/http';


import { Observable } from 'rxjs';

import { HttpUrlEncodingCodec }               from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions } from './cs-http-client.service';

/**
 * CustomHttpUrlEncodingCodec
 * Fix plus sign (+) not encoding, so sent as blank space
 * See: https://github.com/angular/angular/issues/11058#issuecomment-247367318
 */
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
	encodeKey(k: string): string {
		k = super.encodeKey(k);
		return k.replace(/\+/gi, '%2B');
	}

	encodeValue(v: string): string {
		v = super.encodeValue(v);
		return v.replace(/\+/gi, '%2B');
	}
}


export enum HTTP_METHOD {
	GET    = 'GET',
	POST   = 'POST',
	PUT    = 'PUT',
	DELETE = 'DELETE',
}

export enum GenericHttpRequestOptionAction {
	REFRESH = 'Refresh',
	CONFIRM = 'Confirm'
}

export interface GenericHttpRequestOption {
	action: GenericHttpRequestOptionAction;
	message: string;
}

export interface GenericHttpRequest {
	method: HTTP_METHOD;
	queryParams: { [key: string]: any };
	body: any;
	preAction: GenericHttpRequestOption;
	postAction: GenericHttpRequestOption;
}

export interface ConfigurationParameters {
	apiKeys?: { [key: string]: string };
	username?: string;
	password?: string;
	accessToken?: string | (() => string);
	basePath?: string;
	withCredentials?: boolean;
}

export class Configuration {
	apiKeys?: { [key: string]: string };
	username?: string;
	password?: string;
	accessToken?: string | (() => string);
	basePath?: string;
	withCredentials?: boolean;

	constructor(configurationParameters: ConfigurationParameters = {}) {
		this.apiKeys         = configurationParameters.apiKeys;
		this.username        = configurationParameters.username;
		this.password        = configurationParameters.password;
		this.accessToken     = configurationParameters.accessToken;
		this.basePath        = configurationParameters.basePath;
		this.withCredentials = configurationParameters.withCredentials;
	}

	/**
	 * Select the correct content-type to use for a request.
	 * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
	 * If no content type is found return the first found type if the contentTypes is not empty
	 * @param {string[]} contentTypes - the array of content types that are available for selection
	 * @returns {string} the selected content-type or <code>undefined</code> if no selection could be made.
	 */
	public selectHeaderContentType(contentTypes: string[]): string | undefined {
		if (contentTypes.length == 0) {
			return undefined;
		}

		let type = contentTypes.find(x => this.isJsonMime(x));
		if (type === undefined) {
			return contentTypes[0];
		}
		return type;
	}

	/**
	 * Select the correct accept content-type to use for a request.
	 * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
	 * If no content type is found return the first found type if the contentTypes is not empty
	 * @param {string[]} accepts - the array of content types that are available for selection.
	 * @returns {string} the selected content-type or <code>undefined</code> if no selection could be made.
	 */
	public selectHeaderAccept(accepts: string[]): string | undefined {
		if (accepts.length == 0) {
			return undefined;
		}

		let type = accepts.find(x => this.isJsonMime(x));
		if (type === undefined) {
			return accepts[0];
		}
		return type;
	}

	/**
	 * Check if the given MIME is a JSON MIME.
	 * JSON MIME examples:
	 *   application/json
	 *   application/json; charset=UTF8
	 *   APPLICATION/JSON
	 *   application/vnd.company+json
	 * @param {string} mime - MIME (Multipurpose Internet Mail Extensions)
	 * @return {boolean} True if the given MIME is JSON, false otherwise.
	 */
	public isJsonMime(mime: string): boolean {
		const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
		return mime != null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
	}
}


export class GenericHttpClientService {

	protected basePath    = ''; // No default basepath
	public defaultHeaders = new HttpHeaders();
	public configuration  = new Configuration();

	constructor(protected httpClient: CsHttpClient, basePath: string, configuration: Configuration) {
		if (basePath) {
			this.basePath = basePath;
		}
		if (configuration) {
			this.configuration = configuration;
			this.basePath      = basePath || configuration.basePath || this.basePath;
		}
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}


	/**
	 * A generic GET request

	 * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public get<T>(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<T>;
	public get<T>(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<T>>;
	public get<T>(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<T>>;
	public get<T = any>(endpoint: string, queryParams: { [key: string]: any }, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (endpoint === null || endpoint === undefined) {
			throw new Error(`Required parameter endpoint was null or undefined when calling ${endpoint}.`);
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (queryParams != undefined) {
			Object.keys(queryParams).forEach(key => {
				queryParameters = queryParameters.set(key, <any>queryParams[key]);
			});
		}

		let headers = this.defaultHeaders;

		// to determine the Accept header
		const httpHeaderAccepts: string[]                  = [
			'text/plain',
			'application/json',
			'text/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		let consumes: string[] = [];

		if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
			for (const headerKey of csHttpRequestOptions.headers.keys()) {
				headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
			}
		}
		return this.httpClient.get<T>(`${this.basePath}${endpoint}`,
			{
				params:               queryParameters,
				withCredentials:      this.configuration.withCredentials,
				headers:              headers,
				observe:              observe,
				reportProgress:       reportProgress,
				csHttpRequestOptions: csHttpRequestOptions
			}
		);
	}

	/**
	 * A generic GET request for blobs

	 * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getBlob(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>> {
		if (endpoint === null || endpoint === undefined) {
			throw new Error(`Required parameter endpoint was null or undefined when calling ${endpoint}.`);
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (queryParams != undefined) {
			Object.keys(queryParams).forEach(key => {
				queryParameters = queryParameters.set(key, <any>queryParams[key]);
			});
		}

		let headers = this.defaultHeaders;

		// to determine the Accept header
		const httpHeaderAccepts: string[]                  = [
			'text/plain',
			'application/json',
			'text/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		let consumes: string[] = [];

		if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
			for (const headerKey of csHttpRequestOptions.headers.keys()) {
				headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
			}
		}
		return this.httpClient.get(`${this.basePath}/${endpoint}`,
			{
				responseType:         'blob',
				params:               queryParameters,
				withCredentials:      this.configuration.withCredentials,
				headers:              headers,
				observe:              observe,
				reportProgress:       reportProgress,
				csHttpRequestOptions: csHttpRequestOptions
			}
		);
	}

	/**
	 * A generic POST request

	 * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public post<I, T>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<T>;
	public post<I, T>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<T>>;
	public post<I, T>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<T>>;
	public post<I = any, T = any>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (endpoint === null || endpoint === undefined) {
			throw new Error(`Required parameter endpoint was null or undefined when calling ${endpoint}.`);
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (queryParams != undefined) {
			Object.keys(queryParams).forEach(key => {
				queryParameters = queryParameters.set(key, <any>queryParams[key]);
			});
		}

		let headers = this.defaultHeaders;

		// to determine the Accept header
		const httpHeaderAccepts: string[]                  = [
			'text/plain',
			'application/json',
			'text/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		let consumes: string[]                            = [
			'application/json-patch+json',
			'application/json',
			'text/json',
			'application/_*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
			for (const headerKey of csHttpRequestOptions.headers.keys()) {
				headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
			}
		}
		return this.httpClient.post<T>(`${this.basePath}${endpoint}`,
			body,
			{
				params:               queryParameters,
				withCredentials:      this.configuration.withCredentials,
				headers:              headers,
				observe:              observe,
				reportProgress:       reportProgress,
				csHttpRequestOptions: csHttpRequestOptions
			}
		);
	}

	/**
	 * A generic DELETE request

	 * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public delete<T>(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<T>;
	public delete<T>(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<T>>;
	public delete<T>(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<T>>;
	public delete<T = any>(endpoint: string, queryParams?: { [key: string]: any }, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (endpoint === null || endpoint === undefined) {
			throw new Error(`Required parameter endpoint was null or undefined when calling ${endpoint}.`);
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (queryParams != undefined) {
			Object.keys(queryParams).forEach(key => {
				queryParameters = queryParameters.set(key, <any>queryParams[key]);
			});
		}

		let headers = this.defaultHeaders;

		// to determine the Accept header
		const httpHeaderAccepts: string[]                  = [
			'text/plain',
			'application/json',
			'text/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
			for (const headerKey of csHttpRequestOptions.headers.keys()) {
				headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
			}
		}
		return this.httpClient.delete<T>(`${this.basePath}${endpoint}`,
			{
				params:               queryParameters,
				withCredentials:      this.configuration.withCredentials,
				headers:              headers,
				observe:              observe,
				reportProgress:       reportProgress,
				csHttpRequestOptions: csHttpRequestOptions
			}
		);
	}

	/**
	 * A generic POST request
	 * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public put<I, T>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<T>;
	public put<I, T>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<T>>;
	public put<I, T>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<T>>;
	public put<I = any, T = any>(endpoint: string, body?: I, queryParams?: { [key: string]: any }, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

		if (endpoint === null || endpoint === undefined) {
			throw new Error(`Required parameter endpoint was null or undefined when calling ${endpoint}.`);
		}

		let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
		if (queryParams != undefined) {
			Object.keys(queryParams).forEach(key => {
				queryParameters = queryParameters.set(key, <any>queryParams[key]);
			});
		}

		let headers = this.defaultHeaders;

		// to determine the Accept header
		const httpHeaderAccepts: string[]                  = [
			'text/plain',
			'application/json',
			'text/json'
		];
		const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		let consumes: string[]                            = [
			'application/json-patch+json',
			'application/json',
			'text/json',
			'application/_*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
			for (const headerKey of csHttpRequestOptions.headers.keys()) {
				headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
			}
		}
		return this.httpClient.put<T>(`${this.basePath}${endpoint}`,
			body,
			{
				params:               queryParameters,
				withCredentials:      this.configuration.withCredentials,
				headers:              headers,
				observe:              observe,
				reportProgress:       reportProgress,
				csHttpRequestOptions: csHttpRequestOptions
			}
		);
	}

}
