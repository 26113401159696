import { FormatRegistry } from './format-registry';

export class DefaultFormatRegistry extends FormatRegistry {

  constructor() {
    super();
    this.register('DateTime', '{0:d}', DefaultConversions.DateTime);
    this.register('Date', '{0:d}', DefaultConversions.DateTime);
    this.register('Decimal', '{0:n2}');
    this.register('Int32', '{0:n1}');
    this.register('Int', '{0:n1}');
  }
}

export class DefaultConversions {
  static DateTime(value) {
    try {
      return new Date(value);
    } catch (e) {
      return value;
    }
  }

}
