import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { CsAdvancedDropdownModule } from '@cs/components/advanced-dropdown';

import { CsFilterAndCompareBarComponent } from './filter-and-compare-bar.component';
import { CsDatepickerModule }             from '@cs/components/datepicker';


@NgModule({
	imports:      [
		CommonModule,
		CsAdvancedDropdownModule,
		CsDatepickerModule
	],
	declarations: [
		CsFilterAndCompareBarComponent
	],
	exports:      [
		CsFilterAndCompareBarComponent
	]
})
export class CsFilterAndCompareBarModule {
}



