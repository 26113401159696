import { IProperty }                       from '@cs/core';
import { DataGridCellType, GridItemType }  from '../enums/data-grid.enum';
import { isString }                        from '@cs/core';
import { createToObjectWithLowerCaseKeys } from '@cs/components/util';
import { IMatchLevelKeys }                 from '../interfaces/IMatchLevelKeys';
import { DataGridHelpers }                 from '../utils/data-grid-helpers';
import { GridRule }                        from './grid-rule.model';
import { DataGridType }                    from '../classes/data-grid-type';
import { DataGridUIState }                 from '../classes/data-grid-u-i-state';
import { DataGridSortItem }                from '../classes/data-grid-sort-item';
import { CellBehavior }                    from '../classes/cell-behavior';
import { DataGridLookupAction }            from '../classes/data-grid-action';


/**
 * Created by alex on 22-6-2017.
 */

export class GridHeaderCell implements DataGridType, IMatchLevelKeys {


	get id() {
		return 'th_' + DataGridHelpers.createKeysString(this.keys);
	}

	get key(): any {
		return this._key;
	}

	set key(value: any) {
		this._key = isString(value) ? value.toLowerCase() : value;
	}

	get keys(): any {
		return this._keys;
	}

	set keys(value: any) {
		this._keys = createToObjectWithLowerCaseKeys(value);
	}

	dataGridType: GridItemType       = GridItemType.Column;
	headerSortItem: DataGridSortItem = new DataGridSortItem(null);
	isTruncated                      = false;
	colSpan: number;
	width: string;
	isGroup: boolean;
	displayKey: string;
	cssClass                         = '';
	index: number; // wordt niet gezet
	properties: IProperty;
	private _keys: any;
	private _key: any;
	value: any;
	isPrimaryKey                     = false;
	depth                            = 0;
	isInjected                       = false;
	isLabel                          = false;
	cellType: DataGridCellType       = DataGridCellType.Data;
	lookup: DataGridLookupAction[]   = [];
	rules: GridRule[]                = [];
	cellUIState: DataGridUIState     = new DataGridUIState();
	behavior: CellBehavior;

	constructor(args: Partial<GridHeaderCell>) {
		Object.assign(this, args);
	}

}
