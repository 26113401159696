<div class="cs-toast-notification"
     (mouseenter)="onEnter()" (mouseleave)="onLeave()"
     (click)="close()"
     [ngClass]="{
                'cs-toast-alert': data.type === 'alert',
                'cs-toast-error': data.type === 'error',
                'cs-toast-success': data.type === 'success',
                'cs-toast-info': data.type === 'info',
                'cs-toast-bare': data.type === 'bare',
                'cs-toast-warning': data.type === 'warning'
            }"
     [@fadeAnimation]="{value: animationState, params:
        { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }}"
     (@fadeAnimation.done)="onFadeFinished($event)">
  <div class="cs-toast-content-container">
    <div class="cs-title" *ngIf="data.showTitle != false">{{data.title | capitalize}}</div>
    <div class="cs-content">{{data.content | capitalize }}</div>
  </div>
  <div>
    <i class="mdi mdi-18px mdi-close-circle" (click)="close()"></i>
  </div>
  <div class="cs-progress-loader" *ngIf="showProgressBar">
    <span [ngStyle]="{'width': progressWidth + '%'}"></span>
  </div>
</div>
