import {
	Component,
	Input,
	Output,
	EventEmitter,
	TemplateRef,
	OnInit
} from '@angular/core';

import { ItemClickedEventArgs }  from './event-args/item-clicked-event-args';
import { AdvancedDropdownGroup } from './models/advanced-dropdown-group';
import { AdvancedDropdownItem }  from './models/advanced-dropdown-item';


@Component({
	selector:    'cs-advanced-dropdown-item',
	templateUrl: './advanced-dropdown-item.component.html'
})
export class CsAdvancedDropdownItemComponent implements OnInit {
	@Input() group: AdvancedDropdownGroup;
	@Input() groups: Array<AdvancedDropdownGroup>;
	@Input() addDivider                                       = false;
	@Input() itemTemplate: TemplateRef<any>;
	@Input() headerTemplate: TemplateRef<any>;
	@Output() itemClicked: EventEmitter<ItemClickedEventArgs> = new EventEmitter<ItemClickedEventArgs>();
	headerTemplateContext;

	ngOnInit() {
		this.headerTemplateContext = {$implicit: this.group};
	}

	getItemTemplateContext(item: any) {
		item.onItemClicked = () => this.onItemClicked(item);
		return {$implicit: item};
	}

	onItemClicked(item: AdvancedDropdownItem) {
		if (item.disabled)
			return;

		item.selected   = true;
		const eventArgs = new ItemClickedEventArgs(this.group, item);
		this.itemClicked.emit(eventArgs);
	}

	/**
	 * Detects if the header is truncated and if so add an tooltip
	 */
	detectTruncatedField($event: MouseEvent, group: AdvancedDropdownGroup) {
		const element = $event.currentTarget as HTMLElement;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}


		group.isTruncated = isEllipsisActive(element);

	}
}
