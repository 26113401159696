import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule }                        from '@angular/common';

import { MdmPropertiesViewerRoutingModule }                   from './mdm-properties-viewer-routing.module';
import { MdmPropertiesViewerComponent }                       from './mdm-properties-viewer.component';
import { CsApplicationSettings }                              from '@cs/performance-manager/shared';
import { MdmPropertiesViewerConfigService }                   from './mdm-properties-viewer-config.service';
import { HttpClient }                                         from '@angular/common/http';
import { IConfigService }                                     from '@cs/performance-manager/shared';
import { CsTranslationLoader }                                from '@cs/performance-manager/shared';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoggerUtil }                                         from '@cs/core';
import { FormsModule, ReactiveFormsModule }                   from '@angular/forms';
import {
  CsPropertiesFormComponent
}                                                             from './components/properties-form/properties-form.component';
import { CsPropertyNodeViewComponent }                        from './components/property-node-view/property-node-view.component';
import { CsPropertiesViewComponent }                          from './components/properties-view/properties-view.component';

import { MatRippleModule }                   from '@angular/material/core';
import { CsSharedModule, NguiMapModule }     from '@cs/components';
import { ComponentTranslationLoaderService } from '@cs/common';
import { MatTooltipModule }                  from '@angular/material/tooltip';
import { MdmPropertiesViewerEventService }   from './mdm-properties-viewer-event.service';
import { MatDialog, MatDialogModule }        from '@angular/material/dialog';
import { DialogBasicModule }                 from '@cs/performance-manager/shared';


export function stationProfileTranslationLoader(client: HttpClient,
                                                pmAppSettings: CsApplicationSettings,
                                                service: IConfigService,
                                                componentLoader: ComponentTranslationLoaderService) {
  return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
    client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

const COMPONENTS = [MdmPropertiesViewerComponent,
                    CsPropertiesFormComponent,
                    CsPropertiesViewComponent,
                    CsPropertyNodeViewComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports:      [
    CommonModule,
    CsSharedModule,
    TranslateModule.forChild({
      loader:  {
        provide:    TranslateLoader,
        useFactory: stationProfileTranslationLoader,
        deps:       [HttpClient, CsApplicationSettings, MdmPropertiesViewerConfigService, ComponentTranslationLoaderService]
      },
      isolate: true
    }),
    FormsModule,
    MatRippleModule,
    ReactiveFormsModule,
    NguiMapModule,
    MdmPropertiesViewerRoutingModule,
    DialogBasicModule,
    MatTooltipModule
  ],
  providers:    [MdmPropertiesViewerEventService]
})
export class PmMdmPropertiesViewerModule {
  static forRoot(moduleConfig: Type<MdmPropertiesViewerConfigService>,
                 appSetting: CsApplicationSettings): ModuleWithProviders<PmMdmPropertiesViewerModule> {
    return {
      ngModule:  PmMdmPropertiesViewerModule,
      providers: [
        {
          provide:  MdmPropertiesViewerConfigService,
          useClass: moduleConfig
        },
        {
          provide:  CsApplicationSettings,
          useValue: appSetting
        }
      ]
    };
  }


  constructor(translate: TranslateService) {

    if (!translate) {
      LoggerUtil.warn('Translation service is not set-up');
      return;
    }

    // this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
    translate.use('en');
  }
}
